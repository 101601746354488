import { AutoCompleteOption } from "models/fields.model";
import { Roles } from "models/roles.model";

export const EmailRegexPattern =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i;

export const PhoneNumberRegexPattern = /\+?\d+/;

// Role ids are sent when editing user  roles
// Hardcoded because no API exists currently
export const coOwnerRoles: AutoCompleteOption[] = [
  { id: "owner", label: "Company Owner", num_id: 2 },
  { id: "operator", label: "Company Operator", num_id: 3 },
  { id: "technician", label: "Company Technician", num_id: 4 },
];

export const adminRoles = [{ id: "admin", label: "Administrator", num_id: 1 }];

export const userRoles = [{ id: "end_user", label: "End User", num_id: 49 }];

export const roles = [...coOwnerRoles, ...adminRoles, ...userRoles];

export const companyImageTypes = [
  "image/png",
  "image/jpeg",
  "image/webp",
  "image/svg+xml",
];

export const ingredientUnits = [
  { id: "gram", label: "Gram" },
  { id: "millilitre", label: "Millilitre" },
  { id: "count", label: "Count" },
];

export const productTypes = [
  { id: "packaged", label: "Packaged" },
  { id: "recipe", label: "Recipe" },
];

export const interfaceModes = [
  { id: "list", label: "List" },
  { id: "keyboard", label: "Keyboard" },
];

export const flowTypes = [
  { id: "standard", label: "Standard" },
  { id: "carwash", label: "Carwash" },
];

export const rolesPermission: Roles = {
  admin: [],
  owner: ["Modules", "Entity Categories"],
  operator: [
    "Transactions",
    "Orders",
    "Invoices",
    "Users",
    "Companies",
    "Modules",
    "payment-credentials",
  ],
  technician: [
    "Dashboard",
    "Locations",
    "Virtual POS",
    "Transactions",
    "Orders",
    "Invoices",
    "Users",
    "Companies",
    "Promotions",
    "payment-credentials",
  ],
};
