import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Add } from "@mui/icons-material";
import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { styled } from "@mui/material/styles";

import Blueprint from "models/resources/blueprint.model";
import constants from "styles/scss/constants.module.scss";

import useBlueprintDetails from "./useBlueprintDetails";

interface MappingSchemeProps {
  data: Blueprint | null;
}

const MappingBlueprintScheme = () => {
  const [buttonSignal, setButtonSignal] = useState("");
  const [info, setInfo] = useState<any>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingRow, setLoadingRow] = useState<number | null>(null);
  const { t } = useTranslation();
  const {
    deleteSignalMapping,
    createSignalMapping,
    updateSignalMapping,
    blueprintData,
  } = useBlueprintDetails();

  const maxRows = blueprintData?.number_of_rows ?? 0;

  let matrix = [];
  for (let i = 0; i < maxRows; i++) {
    matrix[i] = blueprintData?.machine_blueprint_signal_mappings.filter(
      (item) => item.row === i + 1,
    );
  }

  const handleOpen = (signal: any) => {
    setInfo(signal);
    setButtonSignal(signal.button_signal);
    setIsOpen(true);
  };
  const handleSave = (signal: any, buttonSignal: string) => {
    updateSignalMapping({
      row: signal.row,
      column: signal.column,
      value: buttonSignal,
    });
    setIsOpen(false);
  };

  const isMaxColumnsReached = (indexRow: number) => {
    const rowCells = blueprintData?.machine_blueprint_signal_mappings.filter(
      (el) => el.row === indexRow + 1,
    );
    return (rowCells?.length || 0) >= (blueprintData?.number_of_columns || 0);
  };

  const createCell = async (indexRow: number, row: any) => {
    if (isMaxColumnsReached(indexRow)) {
      return;
    }

    setIsLoading(true);
    setLoadingRow(indexRow);

    let maxCol = blueprintData?.machine_blueprint_signal_mappings.filter(
      (el) => el.row === indexRow + 1,
    );
    for (let i = 0; i < blueprintData?.number_of_columns!; i++) {
      if (!maxCol?.find((el) => el.column === i + 1)) {
        try {
          await createSignalMapping({
            row: indexRow + 1,
            column: i + 1,
            value: "0",
          });
        } finally {
          setIsLoading(false);
          setLoadingRow(null);
        }
        break;
      }
    }
  };
  return (
    <Box>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "3em",
              backgroundColor: constants.bgTextShadow,
              color: "transparent",
              textTransform: "uppercase",
              textShadow: `0px 3px 3px ${constants.textShadow}`,
              fontFamily: constants.bold,
              backgroundClip: "text",
              textAlign: "center",
              paddingBottom: "10px!important",
            }}
          >
            {t("Mapping")}
          </Typography>
          <Box
            component="form"
            sx={{ "& > :not(style)": { m: 1, width: "25ch" } }}
            noValidate
            autoComplete="off"
          >
            <TextField
              id="outlined-controlled"
              label="Button signal"
              value={buttonSignal}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setButtonSignal(event.target.value);
              }}
            />
          </Box>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
              gap: "10px",
            }}
          >
            <Button
              onClick={() => handleSave(info, buttonSignal)}
              style={{ background: "#6a6afe", color: "#fff" }}
            >
              {t("Save")}
            </Button>
            <Button
              style={{ background: "#ad3e3e", color: "#f5f5f5" }}
              onClick={() => {
                deleteSignalMapping(blueprintData?.id!, info);
                setIsOpen(false);
              }}
            >
              {t("Delete")}
            </Button>
          </div>
        </div>
      </Dialog>
      {matrix.map((row, indexRow) => {
        const maxReached = isMaxColumnsReached(indexRow);

        return (
          <Stack
            key={indexRow}
            direction="row"
            justifyContent="center"
            alignItems="center"
            display="flex"
            gap={2}
            marginBottom={2}
          >
            <h3>{indexRow + 1}</h3>
            {row
              ?.sort((a, b) => a.column - b.column)
              .map((signal, indexCol) => (
                <>
                  <div
                    key={indexCol * (indexRow + 1)}
                    style={{
                      width: "100%",
                      border: "1px solid blue",
                      borderRadius: "5px",
                    }}
                  >
                    <Button
                      style={{ width: "100%" }}
                      onClick={() => handleOpen(signal)}
                    >
                      {t("button")}: {signal.button_signal}
                    </Button>
                  </div>
                </>
              ))}
            <Tooltip
              title={maxReached ? t("Maximum number of columns reached") : ""}
            >
              <span>
                <Button
                  onClick={() => createCell(indexRow, row)}
                  disabled={
                    (isLoading && loadingRow === indexRow) || maxReached
                  }
                >
                  {isLoading && loadingRow === indexRow ? (
                    <CircularProgress size={24} />
                  ) : (
                    <Add />
                  )}
                </Button>
              </span>
            </Tooltip>
          </Stack>
        );
      })}
    </Box>
  );
};

export default MappingBlueprintScheme;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: "unset!important",
  },
}));

const BlueprintButtons = ({ data }: MappingSchemeProps) => {};
