export const translation = {
  greeting: "Hello World!",
  labels: {
    totalAmount: "Total Amount",
    requiredFields: "Please fill in all required fields",
  },
  List: "List",
  Name: "Name",
  Save: "Save",
  Email: "Email",
  Address: "Address",
  "Machine name / serial": "Machine name / serial",
  "First Name": "First Name",
  Company: "Company",
  Currency: "Currency",
  "Last Name": "Last Name",
  "Location map": "Location map",
  Role: "Role",
  "Add company": "Add company",
  Companies: "Companies",
  "Optional comments": "Optional comments",
  Location: "Location",
  "Add location": "Add location",
  Module: "Module",
  "Are you sure?": "Are you sure?",
  "Loading...": "Loading...",
  Delete: "Delete",
  All: "All",
  Close: "Close",
  "Are you sure you want to delete this entity?":
    "Are you sure you want to delete this entity?",
  "Search location": "Search location...",
  Paid: "Paid",
  "QR Code Size": "QR Code Size",
  Latitude: "Latitude",
  Failed: "Failed",
  Longitude: "Longitude",
  "Paid transactions for period selected":
    "Paid transactions for period selected",
  "Remove Logo": "Remove Logo",
  "Failed / timed-out transactions": "Failed / timed-out transactions",
  Up: "Up",
  "Select download format": "Select download format",
  Down: "Down",
  down: "down",
  Machines: "Machines",
  SVG: "SVG",
  "Total Sales": "Total Sales",
  BGN: "BGN",
  PNG: "PNG",
  "BGN/day": "BGN/day",
  JPEG: "JPEG",
  "Download QR Code": "Download QR Code",
  "QR code for module": "QR code for module",
  "AVERATO Administration Platform": "AVERATO Administration Platform",
  "Daily revenue for period selected": "Daily revenue for period selected",
  "Machines module states": "Machines module states",
  "Password changed!": "Password changed!",
  "MDB settings": "MDB settings",
  "Set up your SumUp credentials": "Set up your payment credentials",
  "Unknown response!": "Unknown response!",
  "Edit existing company": "Edit existing company",
  "Edit existing location": "Edit existing location",
  "attached to": "attached to",
  "Important notice": "Important notice",
  "Edit your profile": "Edit your profile",
  "Client ID": "Client ID",
  "Project ID": "Project ID",
  "Project Key": "Project Key",
  "Edit existing user": "Edit existing user",
  "Invalid email or password!": "Invalid email or password!",
  "Invalid old password!": "Invalid old password!",
  Admin: "Administrator",
  "Company Owner": "Company Owner / Representative",
  "Company Operator": "Company Owner",
  "Company Technician": "Company Technician",
  Owner: "Owner / CEO",
  Operator: "Operator",
  Technician: "Technician",
  "Company name": "Company name",
  "Location name": "Location name",
  "MDB settings are low level technical ones.":
    "MDB settings are low level technical ones.",
  "First name": "First name",
  "Client secret": "Client secret",
  "Password must be at least 6 characters long!":
    "Password must be at least 6 characters long!",
  "Company info": "Company info",
  "AVAILABLE, still not attached to any machine":
    "AVAILABLE, still not attached to any machine",
  "Peripheral Address": "Peripheral Address",
  "Last name": "Last name",
  "Merchant code": "Merchant code",
  "Password field can't be blank!": "Password field can't be blank!",
  "SumUp credentials": "Payment credentials",
  "Feature level": "Feature level",
  "Error occured!": "Error occured!",
  "Set credentials": "Set credentials",
  "Edit existing machine": "Edit existing machine",
  "Scale factor": "Scale factor",
  "Please, provide your old / current password!":
    "Please, provide your old / current password!",
  "Machine name": "Machine name",
  "App max response time": "App max response time",
  "Old password": "Old password",
  "Entity Serial": "Entity Serial",
  "You must select a company first": "You must select a company first",
  "There are no modules available in the specified company":
    "There are no modules available in the specified company",
  Comments: "Comments",
  "Country / currency code": "Country / currency code",
  "New password": "New password",
  "Decimal places": "Decimal places",
  "Max price": "Max price",
  "Min price": "Min price",
  "Miscelaneous options": "Miscelaneous options",
  module: "module",
  "attached to machine": "attached to machine",
  "NO module attached": "NO module attached",
  "Edit existing module": "Edit existing module",
  "Wrong URL": "Wrong URL",
  Averato: "Averato",
  "Please, select the desired amount to pay.":
    "Please, select the desired amount to pay.",
  "Sorry! The URL you typed is wrong!": "Sorry! The URL you typed is wrong!",
  "Need to get something, but got no cash?":
    "Need to get something, but got no cash?",
  "That's OK ;) All you need to do is:": "That's OK ;) All you need to do is:",
  "Please, specify amount to pay": "Please, specify amount to pay",
  "1. Download and install the free Averato app.":
    "1. Download and install the free Averato app.",
  "1. Download and install the free MyPay.bg app.":
    "1. Download and install the free MyPay.bg app.",
  "Get Averato for Android": "Get Averato for Android",
  Pay: "Pay",
  "Get Averato for iOS": "Get Averato for iOS",
  "Get MyPay.bg for Android": "Get MyPay.bg for Android",
  "2. Open the app and scan the QR code again.":
    "2. Open the app and scan the QR code again.",
  now: "now",
  "3. Setup your payment method and pay the desired amount.":
    "3. Setup your payment method and pay the desired amount.",
  "Get MyPay.bg for iOS": "Get MyPay.bg for iOS",
  "Enjoy!": "Enjoy!",
  "Pay without installing the app": "Pay without installing the app",
  "See where else you can pay with AVERATO":
    "See where else you can pay with AVERATO",
  "My Profile": "My Profile",
  "No access": "No access",
  "Completing transaction... Plase, wait!":
    "Completing transaction... Plase, wait!",
  "Please, check your email for password reset link!":
    "Please, check your email for password reset link!",
  "Password changed! Please, log in": "Password changed! Please, log in.",
  "My Companies": "My Companies",
  "You are restricted to access this resource.":
    "You are restricted to access this resource.",
  "Email not found!": "Email not found!",
  "System error!": "System error!",
  "Change Password": "Change Password",
  "Sign in": "Sign in",
  "Type a new password": "Type a new password",
  "Reset password": "Reset password",
  "Forgot password": "Forgot password",
  Search: "Search",
  "Filter by company": "Filter by company",
  "From date": "From date",
  Dashboard: "Dashboard",
  None: "None",
  "Company Name": "Company Name",
  Show: "Show",
  Wallets: "Wallets",
  "Prepaid cards": "Prepaid cards",
  "Prepaid card": "Prepaid card",
  "prepaid card": "prepaid card",
  "prepaid cards": "prepaid cards",
  "Gift Card": "Gift Card",
  AVAX: "AVAX",
  up: "up",
  "VPOS State": "VPOS State",
  "Filter by payment type": "Filter by payment type",
  averato: "averato",
  cash: "cash",
  cashless1: "cashless1",
  cashless2: "cashless2",
  wallet: "wallet",
  "Payment type": "Payment type",
  Administrator: "Administrator",
  "Module Serial": "Module Serial",
  ICCID: "ICCID",
  "To date": "To date",
  "Machine Serial": "Machine Serial",
  Edit: "Edit",
  Hide: "Hide",
  "Module Signal Strength": "Module Signal Strength",
  "Signal strength": "Signal strength",
  "Filter by machine": "Filter by machine",
  "Show revenue": "Show revenue",
  Map: "Map",
  "Module State": "Module State",
  "Attached to machine": "Attached to machine",
  "Order Date": "Order Date",
  "Transaction Date": "Transaction Date",
  QR: "QR",
  "Module task successfully reset to idle":
    "Module task successfully reset to idle",
  "Order Time": "Order Time",
  Amount: "Amount",
  "Transaction Time": "Transaction Time",
  "Could not reset module task to idle": "Could not reset module task to idle",
  "Product number": "Product number",
  Status: "Status",
  Number: "Number",
  "Download as PDF": "Download as PDF",
  "Is invoice cancelled?": "Is invoice cancelled?",
  "Module successfully disabled": "Module successfully disabled",
  "Unit price": "Unit price",
  "Checkout ID": "Transaction ID",
  "Could not disable module": "Could not disable module",
  Quantity: "Quantity",
  Capacity: "Capacity",
  "Must be equal or lower than capacity":
    "Must be equal or lower than capacity.",
  "Module successfully enabled": "Module successfully enabled",
  "Order total": "Order total",
  "Could not enable module": "Could not enable module",
  "Firmware update was started": "Firmware update was started",
  "Could not update firmware": "Could not update firmware",
  "Module serial": "Module serial",
  "Module MCU": "Module MCU",
  "Current firmware": "Current firmware",
  "Last firmware update": "Last firmware update",
  update: "update",
  active: "active",
  inactive: "inactive",
  ID: "ID",
  "Added to cloud": "Added to cloud",
  "MCU version & Firmware": "MCU version & Firmware",
  Machine: "Machine",
  state: "state",
  uptime: "uptime",
  task: "task",
  "Module state": "Module state",
  "Are you sure you want to reset the module task to idle?":
    "Are you sure you want to reset the module task to idle?",
  Reset: "Reset",
  "Update firmware": "Update firmware",
  Version: "Version",
  Firmware: "Firmware",
  Disable: "Disable",
  Enable: "Enable",
  "Reset Task": "Reset Task",
  disable: "disable",
  "---": "---",
  enable: "enable",
  "Signal Strength": "Signal Strength",
  "View and download QR": "View and download QR",
  "QR code": "QR code",
  "Please, attach Averato module to display QR payment code!":
    "Please, attach Averato module to display QR payment code!",
  "MCU Version": "MCU Version",
  "Peripheral address": "Peripheral address",
  "Current Firmware": "Current Firmware",
  Uptime: "Uptime",
  Task: "Task",
  Locations: "Locations",
  "Virtual POS / QR": "Virtual POS / QR",
  Transactions: "Transactions",
  Telemetry: "Telemetry",
  Users: "Users",
  "SumUp Credentials": "Payment Credentials",
  Modules: "Modules",
  "Phone number": "Phone number",
  "Phone is invalid": "Номерът е невалиден.",
  "Location created!": "Location created!",
  "Machine name fields is required!": "Machine name fields is required!",
  "User created!": "User created!",
  "Type of payment receiving point": "Type of payment receiving point",
  "Company created!": "Company created!",
  "Currency fields is required!": "Currency fields is required!",
  "POS name": "POS name",
  "POS Name": "POS Name",
  "Please, select a company!": "Please, select a company!",
  "Auto generate new serial number": "Auto generate new serial number",
  "Please, select machine location!": "Please, select machine location!",
  "Auto generate unique serial number": "Auto generate unique serial number",
  "Unique POS serial": "Unique POS serial",
  "Associated users": "Associated users",
  "Add user": "Add user",
  Cancel: "Cancel",
  "Rows per page": "Rows per page",
  "Are you sure you want to delete this record?":
    "Are you sure you want to delete this record?",
  "Delete confirmation": "Delete confirmation",
  "Profile updated": "Profile updated",
  "SumUp credentials updated.": "Payment credentials updated.",
  "Edit existing virtual POS": "Edit existing virtual POS",
  "Additional settings": "Additional settings",
  Language: "Language",
  English: "English",
  Български: "Български",
  "That's OK ;) All you need to do is": "That's OK ;) All you need to do is",
  "Payment made!": "Payment made!",
  "Payment failed!": "Payment failed!",
  "Loading machine data...": "Loading machine data...",
  "Transaction not found in system!": "Transaction not found in system!",
  "Vending machine busy! Please, try again in a minute or two!":
    "Vending machine busy! Please, try again in a minute or two!",
  "Expired URL or QR code.": "Expired URL or QR code.",
  "Payment FAILED! Please, try again!": "Payment FAILED! Please, try again!",
  "Invalid parameters sent in request.": "Invalid parameters sent in request.",
  "See where you can pay with AVERATO": "See where you can pay with AVERATO",
  "Loading transaction data....": "Loading transaction data....",
  "Vending machine out of order. Sorry!":
    "Vending machine out of order. Sorry!",
  "Pay for": "Pay for",
  "Set up credentials for accepting payments through SumUp":
    "Set up credentials for accepting payments",
  "Set up credentials for accepting payments through SumUp for company":
    "Set up credentials for accepting payments in the respective currency for company",
  "Are you sure you want to delete this location?":
    "Are you sure you want to delete this location?",
  "Are you sure you want to delete this machine?":
    "Are you sure you want to delete this machine?",
  "Communication errro": "Communication errro",
  "Are you sure you want to delete this user?":
    "Are you sure you want to delete this user?",
  "POS type": "POS type",
  "Are you sure you want to delete this company?":
    "Are you sure you want to delete this company?",
  "Are you sure you want to delete this module?":
    "Are you sure you want to delete this module?",
  "POS serial": "POS serial",
  "POS Serial": "POS Serial",
  "Set POS serial now": "Set POS serial now",
  "Are you sure you want to delete this VPOS?":
    "Are you sure you want to delete this VPOS?",
  "Filter by role": "Filter by role",
  "POS/QR serial": "POS/QR serial",
  "no_signal signal": "no signal",
  PAID: "PAID",
  FAILED: "FAILED",
  TIMED_OUT: "TIMED OUT",
  PENDING: "PENDING",
  AVERATO: "AVERATO",
  "Forgot password?": "Forgot password?",
  "QR Code name": "QR Code name",
  "Unique QR serial": "Unique QR serial",
  "URL encoded in QR code": "URL encoded in QR code",
  "${props.signal} signal": "${props.signal} signal",
  "Fixed amount QR code": "Fixed amount QR code",
  "Enter amount to be paid": "Enter amount to be paid",
  "Logo in the middle of QR code (optional)":
    "Logo in the middle of QR code (optional)",
  "Do you wish to receive sms?": "Do you wish to receive sms?",
  "Change password": "Change password",
  "MDB settings for module": "MDB settings for module",
  "MDB Settings updated.": "MDB Settings updated.",
  "Date Format Types": "Date Format Types",
  "Edit existing QR Code": "Edit existing QR Code",
  CHF: "CHF",
  "Sorry, the module is busy": "Sorry, the module is busy",
  Serial: "Serial",
  Yes: "Yes",
  No: "No",
  "The module is out of order": "The module is out of order",
  "Add currency": "Add currency",
  "Avalanche payment": "Avalanche payment",
  "Please fill in all the fields": "Please fill in all the fields",
  "Avalanche wallet address updated.": "Avalanche wallet address updated.",
  "Avalanche Wallet Address": "Avalanche Wallet Address (X-Chain)",
  "AVERATO module is currently offline. Please, try again later.":
    "AVERATO module is currently offline. Please, try again later.",
  "Pay again": "Pay again",
  "Loading payment form": "Loading payment form...",
  "Remembered passowrd?": "Remembered passowrd?",
  "No module attached": "No module attached",
  "Communication error": "Communication error",
  "Payment point": "Payment point",
  Update: "Update",
  "QR code serial": "QR code serial",
  "Filter by location": "Filter by location",
  "Filter by status": "Filter by status",
  "QR code name": "QR code name",
  "No SumUp credentials have been defined for the company yet. Please, set up the first ones using the form below!":
    "No паъмент credentials have been defined for the company yet. Please, set up the first ones using the form below!",
  "Payment credentials deleted.": "Payment credentials deleted.",
  "Selected company does not have any payment credentials yet":
    "Selected company does not have any payment credentials yet.",
  "Avalanche credentials deleted.": "Avalanche credentials deleted.",
  "Delete credentials for": "Delete credentials for",
  "QR payment codes": "QR payment codes",
  "Virtual POS": "Virtual POS",
  "Product Number": "Product Number",
  "Currency at transaction": "Currency at transaction",
  Orders: "Orders",
  "Please, select currency to view or edit existing credentials.":
    "Please, select currency to view or edit existing credentials.",
  "Please, use X button instead of browser's Back button to close the dialog without reloading!":
    "Please, use X button instead of browser's Back button to close the dialog without reloading!",
  "Login required": "Login required",
  "Revenue by currency": "Revenue by currency",
  "You are not registered as company user.":
    "You are not registered as company user.",
  success: "success",
  failed: "failed",
  open: "open",
  "VAT %": "VAT %",
  "Total amount": "Total amount",
  "Issuing company": "Issuing company",
  "Invoice date": "Invoice date",
  "Invoice number": "Invoice number",
  Incoming: "Incoming",
  Waiting: "Waiting",
  Issued: "Issued",
  Requests: "Requests",
  "End Users": "End Users",
  "Set up credentials for accepting payments in the respective currency":
    "Set up credentials for accepting payments in the respective currency",
  approved: "approved",
  declined: "declined",
  "Change Status": "Change Status",
  "VAT% (load prepaid cards)": "VAT% (load prepaid cards)",
  "Accountable person": "Accountable person",
  "Next invoice number": "Next invoice number",
  "Tax number": "Tax number",
  City: "City",
  Country: "Country",
  "Do you wish to receive SMS?": "Do you wish to receive SMS?",
  "Load prepaid cards": "Load prepaid cards",
  Send: "Send",
  "The current page data will be overwritten are you sure?":
    "The current page data will be overwritten. Are you sure?",
  OK: "OK",
  "Custom amount for every user": "Custom amount for every user",
  "Request invoice": "Request invoice",
  Promotions: "Promotions",
  "Date From": "Date From",
  "Date To": "Date To",
  From: "From",
  To: "To",
  "Pay Now": "Pay Now",
  "Get Later": "Get Later",
  "Get later": "Get later",
  Type: "Type",
  "Edit existing promotion": "Edit existing promotion",
  "high signal": "high signal",
  "Is entity funded?": "Is entity funded?",
  Logs: "Logs",
  Date: "Date",
  Time: "Time",
  "Order Details": "Order Details",
  Order: "Order",
  Transaction: "Transaction",
  "Not requested": "Not requested",
  "Invoice status": "Invoice status",
  "Order Id": "Order Id",
  "Order id": "Order Id",
  "Order date": "Order date",
  "Cancellation reason": "Cancellation reason",
  requested: "requested",
  Reason: "Reason",
  issued: "issued",
  "Default company": "Default company",
  "There aren't any available API tokens for the current company":
    "There aren't any available API tokens for the current company",
  "Receiving company": "Receiving company",
  "Invoice company": "Invoice company",
  "Companies awaiting approval": "Companies awaiting approval",
  "Accountable Person": "Accountable Person",
  waiting: "waiting",
  "This company is awaiting approval.": "This company is awaiting approval.",
  Approve: "Approve",
  Decline: "Decline",
  "Last Seen": "Last Seen",
  idle: "idle",
  "Please, only change values if you are 100% sure about what you are doing!":
    "Please, only change values if you are 100% sure about what you are doing!",
  "Sales today": "Sales today",
  Macines: "Macines",
  Invoices: "Invoices",
  "QR Payment Codes": "QR Payment Codes",
  "Payment made! Amount paid will be credited to vending machine in a few seconds":
    "Payment made! Amount paid will be credited to vending machine in a few seconds.",
  Welcome: "Welcome",
  Today: "Today",
  "since last month": "since last month",
  "Location Name": "Location Name",
  "Machine Name": "Machine Name",
  "": "",
  "Order details product quantity": "Quantity",
  high: "high",
  "QR Code Name": "QR Code Name",
  "Transactions Date": "Transactions Date",
  "Transactions Time": "Transactions Time",
  "Orders Date": "Orders Date",
  "Orders Time": "Orders Time",
  Cancelled: "Cancelled",
  "Payment credentials": "Payment credentials",
  "API Credentials": "API Credentials",
  "API Tokens": "API Tokens",
  "Valid until": "Valid until",
  "Last used": "Last used",
  Description: "Description",
  "Callback url": "Callback url",
  "Valid until field is required!": "Valid until field is required!",
  "Log out": "Log out",
  "Signed as": "Signed as",
  "Reason to decline": "Reason to decline",
  "Invoiced company": "Invoiced company",
  null: "null",
  "Sign up": "Sign up",
  Login: "Login",
  "Please enter the Email associated with your account":
    "Please enter the Email associated with your account",
  "Don’t have an account?": "Don’t have an account?",
  "You have an account?": "You have an account?",
  "Orders Invoice": "Orders Invoice",
  "Load your voucher and send to anyone you want":
    "Load your voucher and send to anyone you want",
  "New User": "New User",
  "For company": "For company",
  "Show Map": "Show Map",
  "Hide Map": "Hide Map",
  admin: "Administrator",
  owner: "Company Owner",
  operator: "Operator",
  technician: "Technician",
  end_user: "End User",
  "Click here to edit": "Click here to edit.",
  "Click here to delete": "Click here to delete.",
  "Resource created successfully": "Resource created successfully",
  "Resource edited successfully": "Resource edited successfully",
  "Resource deleted successfully": "Resource deleted successfully",
  "Must be a valid email": "Must be a valid email.",
  "User email was not found": "User email was not found",
  "Duplicated user email": "Duplicated user email",
  "New Ingredient": "New Ingredient",
  "Add ingredient": "Add Ingredient",

  "Measurement Unit": "Measurement Unit",
  Gram: "Gram",
  Millilitre: "Millilitre",
  Count: "Count",
  Allergens: "Allergens",
  allergen: "allergen",
  "Add allergen": "Add allergen",
  Ingredients: "Ingredients",
  Ingredient: "Ingredient",
  ingredient: "ingredient",
  Measurement: "Measurement",
  "Measurement unit": "Measurement unit",
  "Unit name": "Unit name",
  Products: "Products",
  product: "product",
  packaged: "packaged",
  recipe: "recipe",
  "Add packaged product": "Add packaged product",
  "Add product with recipe": "Add product with recipe",
  "Product Name": "Product Name",
  "Product type": "Product type",
  Price: "Price",
  "Attach products": "Attach products",
  Detach: "Detach",
  "Ingredient capacities": "Ingredient capacities",
  "Add product capacity": "Add product capacity",
  "Specify Product capacity": "Specify Product capacity",
  "Edit Product capacity": "Edit Product capacity",
  "Add ingredient capacity": "Add ingredient capacity",
  "Specify ingredient capacity": "Specify ingredient capacity",
  "Edit ingredient capacity": "Edit ingredient capacity",
  Vending: "Vending",
  Blueprints: "Blueprints",
  Scheme: "Scheme",
  Mapping: "Mapping",
  "Button signal": "Button signal",
  "Copy from machine": "Copy from machine",
  "Minimal sum for purchase": "Minimal sum for purchase",
  period: "Period",
  "active-gift": "Active",
  "ACTIVE-GIFT": "Active",
  "gift-card": "GIFT CARD",
  "Module Flow": "Module flow",
  Flow: "Flow",
  standard: "standard",
  carwash: "carwash",
  "Upload logo": "Upload logo",
  "module flow version": "module flow version",
  "Machine Type": "Machine Type",
  vending: "vending",
  discount_value: "Value",
  gift_card_price: "Purchase price",
  Rows: "Rows",
  Columns: "Columns",
  "Detach from company": "Detach from company",
  token: "token",
  deactivated: "Deactivated",
  ADD: "add",
  Token: "Token",
  Active: "Active",
  ProductType: "Product Type",
  name: "name",
  quantity: "quantity",
  measurement: "measurement",
  allergens: "allergens",
  action: "action",
  "Entity Categories": "Entity Categories",
  "Add Entity Category": "Add Entity Category",
  "Entity Category": "Entity Category",
  "entity-categories": "Entity Categories",
  successfully_generate_code: "successfully generated code",
  "must be at least 4 characters long code":
    "must be at least 4 characters long code",
  code: "Code",
  valid: "Valid",
  generate_access_code: "Generate access code",
  access_code: "Access Code",
  generate_code_for: "Generate code for",
  enabled: "Enabled",
  "Select a product": "Select a product",
  "Cannot delete a signal while it is connected to a button with a product":
    "Cannot delete a signal while it is connected to a button with a product",
  alert_types: "Alert Types",
  coin_acceptor_state_change: "Coin acceptor state change",
  mdb_state_change: "MDB state changed",
  module_up: "Module up",
  module_down: "Module down",
  "has already been taken code": "Code has already been taken.",
  "Access code must contain only numbers":
    "Access code must contain only numbers",
  "Maximum number of columns reached": "Maximum number of columns reached",
  mdb_state_inactive: "MDB state inactive",
  mdb_state_disabled: "MDB state disabled",
  mdb_state_enabled: "MDB state enabled",
  mdb_state_session_idle: "MDB state session idle",
  mdb_state_vend: "MDB state vend",
  "Interface Mode": "Interface Mode",
  "The new interface will be active after restarting the machine":
    "The new interface will be active after restarting the machine",
  list: "List",
  keyboard: "Keyboard",
  Keyboard: "Keyboard",
  button: "Button",
  "No button attached": "No button attached",
  "Button signal is already used in this machine blueprint.":
    "Button signal is already used in this machine blueprint.",
};
