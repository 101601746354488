import React, {
  Dispatch,
  FormEvent,
  Fragment,
  ReactNode,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import { table } from "console";
import * as _ from "lodash";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditSharp from "@mui/icons-material/EditSharp";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";

import CSVExport from "components/CsvExport";
import CustomSearch from "components/CustomSearch";
import CustomTabs from "components/CustomTabs";
import DynamicForm from "components/Forms/generic/DynamicForm";
import MapContainer from "components/MapContainer";
import PDFExport from "components/PdfExport/PdfExport";
import DynamicDialog from "components/layouts/Dialog";
import ConfirmDeleteDialog from "components/layouts/Dialog/ConfirmDeleteDialog";
import useAuthContext from "context/AuthContext";
import useDataContext from "context/DataContext";
import useFilterContext from "context/FilterContext";
import useDialogShowState from "hooks/useDialogShowState";
import { EditDialogState } from "models/components.model";
import { FilterType } from "models/filterData.model";
import QueryParams from "models/queryParams.model";
import Invoice from "models/resources/invoice.model";
import Location from "models/resources/location.model";
import Module from "models/resources/module.model";
import Order from "models/resources/order.model";
import User from "models/resources/user.model";
import {
  DatabaseEntity,
  ResponseDataSuccess,
  ResponseDataTable,
} from "models/responseData.model";
import apiClient from "services/api";
import { invoiceRequestFilter } from "utils/invoiceRequestFilter";
import { parseLocations } from "utils/parseMapLocations";
import { moduleRequestFilter } from "utils/requestFilter";

import styles from "./Table.module.scss";

export type CellValue = string | number | Date | undefined | JSX.Element;
export interface TableSettings {
  pagination: boolean;
  page: number;
  rowsPerPage: number;
  field?: string;
  order?: string;
  sort?: { [x: string]: "asc" | "desc" } | undefined;
  expandProps?: (data: User) => string | JSX.Element;
}

export interface Column<T> {
  label: string;
  name: string;
  getData: (el: T) => CellValue;
  show: (user?: User) => boolean;
  sort?: {
    isSortable: boolean;
    field: string;
  };
  align?: string;
  width?: string;
  minWidth?: string;
  padding?: string;
}

export interface DynamicDialogProps {
  title: string;
  resource: string;
  call: string;
  styles: (...args: string[]) => string;
}

export function CustomTable({
  title,
  columns,
  tableData,
  setTableData,
  fetchPaginatedData,
  tableOptions,
  getCurrentTab,
  isTabs,
  isExpandable,
  resource,
  call,
  isSearchable = true,
  clickableRow = true,
  currentTabIndex,
  url,
  userCompanies,
  dynamicDialogProps,
  exportProp,
  isDynamic,
  customCreate,
  customEdit,
  onDelete,
  notCSV,
  locations,
  hadPDF,
  invoiceType,
  isNestedTable,
  callForInvoice,
  type,
  isDropDown = false,
  openState,
}: {
  title: string | React.ReactElement;
  columns: Column<any>[];
  tableData: ResponseDataTable<any> | undefined;
  fetchPaginatedData: (
    queryParams: QueryParams,
    setDataLoading: any,
    signal?: any,
  ) => void;
  setTableData?: Dispatch<SetStateAction<any>>;
  tableOptions: TableSettings;
  getCurrentTab?: (tabIndex: number) => void;
  tabs?: string[];
  isTabs?: boolean;
  isExpandable?: boolean;
  resource?: string;
  call?: string;
  isSearchable?: boolean;
  clickableRow?: boolean;
  currentTabIndex?: string;
  url?: string;
  userCompanies?: number[];
  dynamicDialogProps?: DynamicDialogProps;
  exportProp?: string;
  isDynamic?: boolean;
  customCreate?: React.ReactElement; // Used for adding custom functionallity to create button.
  customEdit?: React.ReactElement; // Used for adding custom functionallity to edit button.
  onDelete?(id: number | string): void; // Custom delete function from parent component.
  notCSV?: boolean;
  hadPDF?: boolean;
  locations?: boolean;
  currentTabIndexExport?: string;
  invoiceType?: string;
  isNestedTable?: boolean;
  callForInvoice?: string;
  type?: string;
  isDropDown?: boolean;
  openState?: [boolean, Dispatch<SetStateAction<boolean>>];
}) {
  const { getPagePropsData, setProductIngredients } = useDataContext();
  const { handleFilterChange } = useFilterContext();
  const [direction, setDirection] = useState("desc");
  const [valueOrder, setValueOrder] = useState("id");
  const { user } = useAuthContext();
  const { filter, options } = useFilterContext();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const pageQuery = searchParams.get("page");
  const pageRowQuery = searchParams.get("pageRows");
  const searchQuery = searchParams.get("search");
  const searchFilterRef = useRef<boolean>(false);

  const perPage: number = parseInt(pageQuery ?? "0");
  const pageRows: number = parseInt(
    pageRowQuery ?? tableOptions.rowsPerPage.toString(),
  );

  const [isDataLoading, fetchPaginatedDataLoading] = useState(false);

  const [showEditDialog, setShowEditDialog] = useState<EditDialogState>({
    display: false,
    editId: -1,
  });
  const [productType, setProductType] = useState<string | null>(null);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    tableOptions.page > 0
      ? Math.max(
          0,
          (1 + tableOptions.page) * tableOptions.rowsPerPage -
            tableData?.data?.length,
        )
      : 0;

  const addProductDialog = useDialogShowState();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    addProductDialog.closeDialog();
    setAnchorEl(event.currentTarget);
  };

  const EditCell = ({
    row,
    toggleEdit,
    hasDelete = false,
  }: {
    row: any;
    toggleEdit: Dispatch<SetStateAction<EditDialogState>>;
    hasDelete?: boolean;
  }) => {
    const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

    const deleteHandler = async () => {
      typeof onDelete !== "undefined" && onDelete(row.id ?? row.name);
    };

    return (
      <TableCell key={`${row.id}-99`}>
        {row?.role?.type === "end_user" ? (
          <></>
        ) : (
          <Stack direction="row">
            <IconButton
              size="small"
              color="secondary"
              aria-label="edit"
              onClick={() => {
                toggleEdit({ display: true, editId: row.id ?? row.name });

                if (resource === "product") {
                  setProductType(
                    row.product_type === "packaged"
                      ? "packet-product"
                      : "recipe-product",
                  );
                }
              }}
            >
              <Tooltip title={t("Click here to edit")}>
                <EditSharp />
              </Tooltip>
            </IconButton>
            {hasDelete && (
              <IconButton
                size="small"
                color="secondary"
                aria-label="delete"
                onClick={() => setConfirmDelete(true)}
              >
                <Tooltip title={t("Click here to delete")}>
                  <DeleteIcon />
                </Tooltip>
              </IconButton>
            )}
          </Stack>
        )}

        {confirmDelete && (
          <ConfirmDeleteDialog
            setConfirmDelete={setConfirmDelete}
            deleteHandler={deleteHandler}
          />
        )}
      </TableCell>
    );
  };

  const handleChangePage = (_: unknown, newPage: number) => {
    setSearchParams({
      search: searchQuery ?? "",
      pageRows: pageRows.toString(),
      page: newPage.toString(),
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchParams({
      search: searchQuery ?? "",
      page: "0",
      pageRows: event.target.value,
    });
  };

  const columnsFiltered = () => {
    return columns.filter((c) => c.show(user) === true);
  };

  const queryFilters = Object.fromEntries(
    // Remove empty entries, but leave boolean values e.g. deleted: false and empty string for serach query.
    Object.entries(filter).filter(
      ([key, value]) => (!value && value !== "") || !_.isEmpty(value),
    ),
  );
  const sortTable = (field: string, order: string) => {
    const { companyIds, ...companyFilter } = queryFilters; // TODO: Clean up code around this.
    const sortingField: string | string[] = field.includes(".")
      ? field.split(".")
      : field;
    apiClient({
      url: url ? url : call!,
      method: "get",
      queryParams: {
        pagination: {
          page: perPage + 1,
          perPage: pageRows,
        },
        sort: { field: sortingField, order },
        filter: resource === "company" ? companyFilter : queryFilters,
      },
    }).then((response: ResponseDataSuccess<any>) => {
      if (setTableData) {
        const type =
          user?.role?.type === "admin" &&
          (call === "requests" || call === "waiting")
            ? "pending"
            : "invoices";
        if (url === "orders" || url === "invoices") {
          setTableData({
            ...tableData,
            data: invoiceRequestFilter(
              response.data,
              type as string,
              userCompanies,
            ) as Order[],
          });
        } else if (call === "modules") {
          setTableData({
            ...tableData,
            data: moduleRequestFilter(
              response.data,
              currentTabIndex,
            ) as Module[],
          });
        } else {
          setTableData({ ...tableData, data: response.data });
        }
      }
    });
  };

  const activeState = (tabIndex: number) => {
    apiClient({
      url: url ? url : call!,
      method: "get",
      queryParams: {
        pagination: {
          page: perPage + 1,
          perPage: pageRows,
        },
      },
    }).then((response: ResponseDataSuccess<any>) => {
      if (setTableData) {
        tabIndex == 0
          ? (currentTabIndex = "active")
          : tabIndex == 1
            ? (currentTabIndex = "deactivated")
            : (currentTabIndex = "deleted");
        setTableData({
          ...tableData,
          data: moduleRequestFilter(response.data, currentTabIndex) as Module[],
        });
      }
    });
  };

  const sortByColumn = (property: string) => (event: FormEvent) => {
    const isAscending = valueOrder === property && direction === "asc";
    setDirection(isAscending ? "desc" : "asc");
    setValueOrder(property);
    sortTable(property, isAscending ? "desc" : "asc");
  };

  const ExpandableTableRow = ({ children, ...otherProps }: any) => {
    const [isExpanded, setIsExpanded] = useState(false);
    return (
      <>
        <TableRow
          {...otherProps}
          hover
          sx={{ cursor: clickableRow && "pointer" }}
        >
          <TableCell padding="checkbox">
            <IconButton onClick={() => setIsExpanded(!isExpanded)}>
              {isExpanded ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
            </IconButton>
          </TableCell>
          {children}
        </TableRow>
        {isExpanded && (
          <TableRow>
            <TableCell colSpan={10}>
              {tableOptions.expandProps &&
                tableOptions.expandProps(otherProps.row)}
            </TableCell>
          </TableRow>
        )}
      </>
    );
  };

  useEffect(() => {
    if (!_.isEmpty(filter)) {
      if (!searchFilterRef.current) return;

      setSearchParams({
        search: searchQuery ?? "",
        pageRows: pageRows.toString(),
        page: "0",
      });
    }
  }, [filter]);

  useEffect(() => {
    // Used for keeping the page queries on search input change. This is because of the search component uncontrolled rerenders from nonoptimal arhitecture.
    const getFilterData = setTimeout(() => {
      searchFilterRef.current = true;
    }, 200);

    return () => clearTimeout(getFilterData);
  }, [filter.searchQuery]);

  useEffect(() => {
    fetchPaginatedData(
      {
        filter: filter,
        pagination: {
          page: perPage + 1,
          perPage: pageRows,
        },
      },
      fetchPaginatedDataLoading,
    );
  }, [options, searchParams, filter]);

  const tableRows = (row: any) => {
    return columnsFiltered().map((column, index) => {
      const colProp = column.getData(row) as any;
      return column.name === "edit" || column.name === "actions" ? (
        <EditCell
          hasDelete={column.name === "actions"}
          row={row}
          toggleEdit={setShowEditDialog}
          key={`${index}-${column.label}`}
        />
      ) : (
        <TableCell
          onClick={
            !clickableRow ||
            (colProp?.props?.component && colProp?.key !== "Email")
              ? () => {}
              : (event) => viewDetails(row, event)
          }
          key={`${index}-${column.label}`}
          sx={{
            textAlign: column.align || "left",
            width: column.width || "fit-content",
          }}
        >
          {column.getData(row) as ReactNode}
        </TableCell>
      );
    });
  };

  const viewDetails = (row: any, event: any) => {
    if (event.target.nodeName === "A") return;
    if (row.product) {
      navigate({ pathname: `/account/${call}/${row.product.id}` });
    } else if (isNestedTable) {
      navigate({ pathname: `/account/${call}/${row.id}` });
    } else if (callForInvoice) {
      navigate({ pathname: `/account/${callForInvoice}/${row.id}` });
    } else {
      navigate({
        pathname: `${row.id}`,
      });
    }
  };

  const getTotalEntries = () => {
    if (tableOptions.pagination)
      return tableData?.pagination?.total_entries || 0;
    return tableData?.data?.length || 0;
  };

  return (
    <Paper className={styles.container}>
      {isTabs && typeof getPagePropsData.tabs !== "undefined" && (
        <CustomTabs
          tabs={getPagePropsData.tabs.map((tab) => ({
            label: tab,
          }))}
          onCustomChange={(tabIndex) => {
            activeState(tabIndex);
            setSearchParams({ ...searchParams, page: "0" });

            if (typeof getCurrentTab !== "undefined") getCurrentTab(tabIndex);
          }}
        />
      )}
      <Toolbar className={styles.toolbar}>
        <Typography variant="h6" className={styles.title} component="div">
          {title}
        </Typography>
        <Box display="flex" justifyContent="end" alignItems="center">
          {(isSearchable ?? true) && (
            <CustomSearch
              value={searchParams.get("search") ?? ""}
              onCustomChange={(value) => {
                handleFilterChange({
                  type: FilterType.SearchQuery,
                  value,
                });
              }}
            />
          )}
          {isDynamic && (
            <Tooltip title={t(dynamicDialogProps?.title!)}>
              <div style={{ marginTop: "5px" }}>
                <DynamicDialog
                  hideActions={true}
                  title={t(dynamicDialogProps?.title!)}
                  iconButton={<AddIcon fontSize="medium" htmlColor="#9e9e9e" />}
                  openMessage={dynamicDialogProps?.title!}
                  openState={openState}
                  component={
                    customCreate ?? (
                      <DynamicForm
                        key={dynamicDialogProps?.resource!}
                        resource={dynamicDialogProps?.resource!}
                        isResourceListedInTable={true}
                        mode="create"
                        call={dynamicDialogProps?.call!}
                        dynamicClass={dynamicDialogProps?.styles}
                        updateData={setTableData}
                        tableData={tableData}
                      />
                    )
                  }
                />
              </div>
            </Tooltip>
          )}
          {isDropDown && (
            <Fragment>
              <DynamicDialog
                isOpen={addProductDialog.show}
                hideButton
                hideActions
                title={
                  productType === "recipe-product"
                    ? t("Add product with recipe")
                    : t("Add packaged product")
                }
                onCancel={() => {
                  addProductDialog.closeDialog();

                  if (productType === "recipe-product") {
                    setProductIngredients([]);
                  }
                }}
                component={
                  <DynamicForm
                    key={dynamicDialogProps?.resource!}
                    resource={productType!}
                    isResourceListedInTable={true}
                    mode="create"
                    call={dynamicDialogProps?.call!}
                    dynamicClass={dynamicDialogProps?.styles!}
                    updateData={setTableData}
                    tableData={tableData}
                    onCustomSubmit={addProductDialog.closeDialog}
                  />
                }
              />
              <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                className="iconButton"
                color="primary"
              >
                <AddIcon fontSize="medium" htmlColor="#9e9e9e" />
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={() => {
                    setProductType("packet-product");
                    addProductDialog.openDialog();
                  }}
                >
                  {t("Add packaged product")}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setProductType("recipe-product");
                    addProductDialog.openDialog();
                  }}
                >
                  {t("Add product with recipe")}
                </MenuItem>
              </Menu>
            </Fragment>
          )}
          {locations && (
            <Tooltip title={t(`Check locations`)}>
              <div>
                <DynamicDialog
                  fullWidth={true}
                  hideActions={true}
                  title={t("Check locations")}
                  iconButton={
                    <LocationOnOutlinedIcon
                      fontSize="medium"
                      htmlColor="#9e9e9e"
                    />
                  }
                  component={
                    <MapContainer
                      locations={parseLocations(tableData?.data as Location[])}
                    />
                  }
                />
              </div>
            </Tooltip>
          )}
          {hadPDF && (
            <PDFExport
              rowData={tableData?.data || []}
              type={exportProp!}
              invoiceType={invoiceType}
              typeInvoice={type}
            />
          )}
          {!notCSV && (
            <CSVExport
              rowData={tableData?.data || []}
              type={exportProp!}
              typeInvoice={type}
            />
          )}
        </Box>
      </Toolbar>
      {showEditDialog?.display && (
        <DynamicDialog
          hideActions={true}
          title={t("Edit").concat(` ${t(resource || "")}`)}
          key={`edit-${showEditDialog?.editId}`}
          component={
            typeof customEdit !== "undefined" ? (
              <customEdit.type
                {...customEdit.props}
                resourceId={showEditDialog.editId}
              />
            ) : (
              <DynamicForm
                key={showEditDialog?.editId}
                isResourceListedInTable={true}
                mode="edit"
                resource={productType || resource!}
                call={call!}
                dynamicClass={() => `edit-form__${resource!}`}
                editId={showEditDialog?.editId}
                exitAction={setShowEditDialog}
                updateData={setTableData}
                tableData={tableData?.data}
              />
            )
          }
          openMessage={"Open selection"}
          isOpen={true}
          hideButton={true}
          exitAction={setShowEditDialog}
        />
      )}
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              {isExpandable ? <TableCell padding="checkbox" /> : null}
              {columnsFiltered().map(
                (column: Column<DatabaseEntity>, index: number) => (
                  <TableCell
                    key={`${index}-${column.label}-${column.name}`}
                    sx={{
                      textAlign: `${column.align}` || "left",
                      width: `${column.width}` || "fit-content",
                    }}
                  >
                    {column?.sort ? (
                      <TableSortLabel
                        active={valueOrder === column.sort.field}
                        direction={
                          valueOrder === column.sort.field
                            ? (direction as "desc" | "asc" | undefined)
                            : "asc"
                        }
                        onClick={sortByColumn(column.sort.field)}
                      >
                        {t(column.label) ?? t(column.name)}
                      </TableSortLabel>
                    ) : (
                      t(column.label) ?? t(column.name)
                    )}
                  </TableCell>
                ),
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {isDataLoading ? (
              <TableRow key={"loading-row"}>
                <TableCell
                  colSpan={columnsFiltered().length}
                  style={{
                    textAlign: "center",
                    fontSize: "20px",
                  }}
                  key={"loading-row"}
                >
                  Loading...
                </TableCell>
              </TableRow>
            ) : (
              <>
                {tableData?.data?.map((row: DatabaseEntity, index: number) =>
                  isExpandable ? (
                    <ExpandableTableRow key={`${index}-${row}`} row={row}>
                      {tableRows(row)}
                    </ExpandableTableRow>
                  ) : (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                      className={clickableRow ? styles.row : undefined}
                    >
                      {tableRows(row)}
                    </TableRow>
                  ),
                )}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[
                  25,
                  100,
                  {
                    label: "All",
                    value: getTotalEntries(),
                  },
                ]}
                count={getTotalEntries()}
                rowsPerPage={pageRows}
                page={perPage}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                labelRowsPerPage={t("Rows per page")}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{
                  position: "absolute",
                  paddingRight: "20px",
                  borderBottom: "none",
                  right: "20px",
                }}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Paper>
  );
}
