import { useTranslation } from "react-i18next";

import {
  CellValue,
  Column,
  CustomTable,
  DynamicDialogProps,
  TableSettings,
} from "components/Table/Table";
import { Item } from "models/resources/order.model";

interface Props {
  items: Item[];
}

const ItemsTable = ({ items }: Props) => {
  const { t } = useTranslation();

  const columns: Column<Item>[] = [
    {
      label: t("Name"),
      name: "name",
      getData: (data): CellValue => data.product?.name,
      show: () => {
        return true;
      },
      sort: { isSortable: true, field: "name" },
    },
    {
      label: t("Product name"),
      name: "productName",
      getData: (data): CellValue => {
        return data?.product?.display_name;
      },
      show: () => {
        return true;
      },
    },

    {
      label: t("Currency"),
      name: "currency",
      getData: (data): CellValue => data?.product?.currency,
      show: () => {
        return true;
      },
    },
    {
      label: t("Price"),
      name: "price",
      getData: (data): CellValue => Number(data.price).toFixed(2),
      show: () => {
        return true;
      },
    },
    {
      label: t("Taken"),
      name: "taken",
      getData: (data): CellValue => (data.taken ? t("yes") : t("no")),
      show: () => {
        return true;
      },
    },
  ];

  const tableOptions: TableSettings = {
    pagination: true,
    page: 0,
    rowsPerPage: 25,
    field: "id",
    order: "asc",
  };

  const dynamicDialogProps: DynamicDialogProps = {
    title: "Add product",
    resource: "product",
    call: "products",
    styles: () => "flexHalfForm",
  };
  return (
    <CustomTable
      title={t("Products")}
      tableData={{ data: items }}
      fetchPaginatedData={() => {}}
      setTableData={() => {}}
      columns={columns}
      resource="product"
      call="vending/products"
      tableOptions={tableOptions}
      isNestedTable={true}
      exportProp={"products"}
      dynamicDialogProps={dynamicDialogProps}
      isDropDown={true}
      notCSV={true}
    />
  );
};

export default ItemsTable;
