import { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  Button,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";

import Machine from "models/resources/machine.model";
import constants from "styles/scss/constants.module.scss";

import { ButtonProductMapper } from "./useMachineDetails";

interface ProductOptionType {
  display_name: string;
  id: number;
  name: string;
}
interface MachineMatrixI {
  createSignalMapping: ({
    signal,
    buttonSignal,
  }: {
    signal: number;
    buttonSignal: number;
  }) => void;
  machineData: Machine | undefined;
  buttonProductMappers: ButtonProductMapper[];
  updateSignalMapping: ({
    signal,
    buttonSignal,
    productMapperId,
  }: {
    signal: number;
    buttonSignal: number;
    productMapperId: number;
  }) => void;
  deleteSignalMapping: (id: number) => void;
}
const MachineMatrix = ({
  machineData,
  createSignalMapping,
  buttonProductMappers,
  updateSignalMapping,
  deleteSignalMapping,
}: MachineMatrixI) => {
  if (machineData === undefined) return null;
  const productOptions = machineData.products || [];
  const defaultProps = {
    options: productOptions,
    getOptionLabel: (option: ProductOptionType) => option.display_name,
  };

  const [buttonSignal, setButtonSignal] = useState<{ id: number }>({ id: 0 });
  const [info, setInfo] = useState<SignalType>();
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const [productMapper, setProductMapper] = useState<{
    id: number;
    product: ProductOptionType;
  }>();

  if (!machineData) return null;
  const maxRows = Math.max(
    ...(machineData?.machine_blueprint?.machine_blueprint_signal_mappings.map(
      (item) => item.row,
    ) ?? [0]),
  );
  let matrix = [];
  for (let i = 0; i < maxRows; i++) {
    matrix[i] =
      machineData?.machine_blueprint?.machine_blueprint_signal_mappings.filter(
        (item) => item.row === i + 1,
      );
  }
  const [selectedProduct, setSelectedProduct] =
    useState<ProductOptionType | null>(null);

  interface SignalType {
    id: number;
    column: number;
    row: number;
    button_signal: string;
  }

  const handleOpen = (signal: SignalType) => {
    const findProductMapper = buttonProductMappers.find(
      (bpm: ButtonProductMapper) =>
        bpm.column === signal.column && bpm.row === signal.row,
    );
    setProductMapper(findProductMapper);
    setInfo(signal);
    setButtonSignal({ id: parseInt(signal.button_signal) });

    // Set previously selected product
    setSelectedProduct(findProductMapper?.product || null);

    setIsOpen(true);
  };
  const handleSave = (signal: SignalType) => {
    if (productMapper && productMapper.id !== 0) {
      updateSignalMapping({
        signal: signal.id,
        buttonSignal: buttonSignal.id ?? productMapper.product.id,
        productMapperId: productMapper?.id,
      });
    } else {
      createSignalMapping({
        signal: signal.id,
        buttonSignal: buttonSignal.id,
      });
    }

    setIsOpen(false);
  };
  const handleDelete = (info: { id: number; column: number; row: number }) => {
    deleteSignalMapping(info.id);
    setIsOpen(false);
  };
  return (
    <Box>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "3em",
              backgroundColor: constants.bgTextShadow,
              color: "transparent",
              textTransform: "uppercase",
              textShadow: `0px 3px 3px ${constants.textShadow}`,
              fontFamily: constants.bold,
              backgroundClip: "text",
              textAlign: "center",
              paddingBottom: "10px!important",
            }}
          >
            {t("Mapping")}
          </Typography>

          <Box
            component="form"
            noValidate
            autoComplete="off"
            width={"100%"}
            mb={2}
          >
            <Autocomplete
              fullWidth
              {...defaultProps}
              id="controlled-demo"
              value={selectedProduct}
              onChange={(
                event: React.ChangeEvent<{}>,
                newValue: ProductOptionType | null,
              ) => {
                setSelectedProduct(newValue); // <-- Update selected product
                setButtonSignal(newValue ? { id: newValue.id } : { id: 0 });
              }}
              renderInput={(params: AutocompleteRenderInputParams) => (
                <TextField
                  required={true}
                  {...params}
                  label={t("Select a product")}
                  variant="standard"
                />
              )}
            />
          </Box>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
              gap: "10px",
            }}
          >
            <Button
              variant="contained"
              color={!selectedProduct ? "warning" : "primary"}
              onClick={() => {
                if (info) {
                  handleSave(info);
                }
              }}
              disabled={!selectedProduct}
            >
              {t("Save")}
            </Button>
            {productMapper?.product.display_name && (
              <Button
                style={{ background: "#ad3e3e", color: "#f5f5f5" }}
                onClick={() => {
                  if (info) {
                    handleDelete(info);
                    setIsOpen(false);
                  }
                }}
              >
                {t("Delete")}
              </Button>
            )}
          </div>
        </div>
      </Dialog>
      {matrix.map((row, indexRow) => {
        return (
          <Stack
            key={indexRow}
            direction="row"
            justifyContent="center"
            alignItems="center"
            display="flex"
            gap={2}
            marginBottom={2}
          >
            <h3>{indexRow + 1}</h3>
            {row
              ?.sort((a, b) => a.column - b.column)
              .map((signal, indexCol) => {
                if (signal.button_signal === "0") {
                  return (
                    <>
                      <div
                        key={indexCol * (indexRow + 1)}
                        style={{
                          width: "100%",
                          border: "1px solid rgba(0, 0, 0, 0.26)",
                          borderRadius: "5px",
                        }}
                      >
                        <Button style={{ width: "100%" }} disabled>
                          {t("No button attached")}
                        </Button>
                      </div>
                    </>
                  );
                }

                const hasProduct = buttonProductMappers.some(
                  (bpm: ButtonProductMapper) =>
                    bpm.button_signal === signal.button_signal &&
                    signal.button_signal !== "0",
                );

                return (
                  <>
                    <div
                      key={indexCol * (indexRow + 1)}
                      style={{
                        width: "100%",
                        border: hasProduct
                          ? "1px solid blue"
                          : "2.5px dashed rgba(0, 0, 0, 0.20)",
                        borderRadius: "5px",
                      }}
                    >
                      <Button
                        style={{ width: "100%" }}
                        onClick={() => handleOpen(signal)}
                      >
                        {buttonProductMappers.find(
                          (bpm: ButtonProductMapper) =>
                            bpm.button_signal === signal.button_signal &&
                            signal.button_signal !== "0",
                        )?.product?.display_name || t("No product")}
                      </Button>
                    </div>
                  </>
                );
              })}
          </Stack>
        );
      })}
    </Box>
  );
};

export default MachineMatrix;
