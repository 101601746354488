import { EntityApiLogic } from "models/fields.model";

export const responseInterpretation: EntityApiLogic[] = [
  {
    resource: "machine",
    rootValues: [
      "module",
      "comments",
      "currency",
      "name",
      "location_id",
      "company_id",
      "product_type",
      "minimum_payment_amount",
      "machine_blueprint_id",
      "entity_category_id",
      "interface_mode",
    ],

    nestedValues: [
      {
        objectKey: "module",
        objectIsArray: false,
        objectProperties: [
          { responsePropertyName: "id", formPropertyName: "module_id" },
        ],
      },
      {
        objectKey: "machine_blueprint",
        objectIsArray: false,
        objectProperties: [
          {
            responsePropertyName: "id",
            formPropertyName: "machine_blueprint_id",
          },
        ],
      },
    ],
  },
  {
    resource: "location",
    rootValues: ["address", "name", "company_id"],
    nestedValues: [
      {
        objectKey: "company",
        objectIsArray: false,
        objectProperties: [
          {
            responsePropertyName: "id",
            formPropertyName: "company_id",
          },
        ],
      },
    ],
  },
  {
    resource: "qr",
    rootValues: [
      "serial",
      "location_id",
      "name",
      "company_id",
      "currency",
      "comments",
    ],
  },
  {
    resource: "user",
    rootValues: [
      "first_name",
      "last_name",
      "email",
      "phone",
      "receive_sms",
      "role",
      "users_companies",
      "alert_types",
    ],
    nestedValues: [
      {
        objectKey: "users_companies",
        objectIsArray: true,
        objectProperties: [
          {
            responsePropertyName: "id",
            formPropertyName: "company_ids",
          },
        ],
      },
      {
        objectKey: "role",
        objectIsArray: false,
        objectProperties: [
          { responsePropertyName: "type", formPropertyName: "role" },
        ],
      },
    ],
  },
  {
    resource: "company",
    rootValues: [
      "name",
      "display_name",
      "phone",
      "email",
      "country",
      "state",
      "city",
      "tax_number",
      "state",
      "accountable_person",
      "next_invoice_number",
      "tax_number",
      "receive_sms",
      "info",
      "address",
      "vat_load_gift_cards",
    ],
  },
  {
    resource: "pos",
    rootValues: [
      "name",
      "company_id",
      "currency",
      "users_pos",
      "location_id",
      "comments",
    ],
    nestedValues: [
      {
        objectKey: "users_pos",
        objectIsArray: true,
        objectProperties: [
          { responsePropertyName: "id", formPropertyName: "user_ids" },
        ],
      },
    ],
  },
  {
    resource: "promotion",
    rootValues: [
      "name",
      "display_name",
      "description",
      "company_id",
      "active",
      "range",
      "gift_card_id",
      "type",
    ],
  },
  {
    resource: "price adjustment",
    rootValues: [
      "name",
      "display_name",
      "description",
      "company_id",
      "active",
      "range",
      "gift_card_id",
      "type",
      "products",
    ],
    nestedValues: [
      {
        objectKey: "gift_card",
        objectIsArray: false,
        objectProperties: [
          {
            responsePropertyName: "discount_value",
            formPropertyName: "price_adjustment",
          },
        ],
      },
      {
        objectKey: "products",
        objectIsArray: true,
        objectProperties: [
          { responsePropertyName: "id", formPropertyName: "product_ids" },
        ],
      },
      {
        objectKey: "win_conditions",
        objectIsArray: true,
        objectProperties: [
          { responsePropertyName: "from", formPropertyName: "from" },
        ],
      },
      {
        objectKey: "win_conditions",
        objectIsArray: true,
        objectProperties: [
          { responsePropertyName: "to", formPropertyName: "to" },
        ],
      },
    ],
  },
  {
    resource: "module",
    rootValues: ["serial", "comments", "company_id", "machine_id"],
    nestedValues: [
      {
        objectKey: "machine",
        objectIsArray: false,
        objectProperties: [
          { responsePropertyName: "id", formPropertyName: "machine_id" },
        ],
      },
    ],
  },
  {
    resource: "module-firmware",
    nestedValues: [
      {
        objectKey: "firmware",
        objectIsArray: false,
        objectProperties: [
          {
            responsePropertyName: "current",
            formPropertyName: "version",
          },
        ],
      },
    ],
  },
  {
    resource: "module-flow",
    nestedValues: [
      {
        objectKey: "module",
        objectIsArray: false,
        objectProperties: [
          {
            responsePropertyName: "flow",
            formPropertyName: "moduleflow",
          },
        ],
      },
    ],
  },
  {
    resource: "packet-product",
    rootValues: [
      "name",
      "display_name",
      "description",
      "company_id",
      "currency",
      "price",
    ],

    nestedValues: [
      {
        objectKey: "ingredients[0]",
        objectIsArray: true,
        objectProperties: [
          { responsePropertyName: "id", formPropertyName: "allergen_ids" },
        ],
      },
      {
        objectKey: "machines",
        objectIsArray: true,
        objectProperties: [
          { responsePropertyName: "id", formPropertyName: "machine_ids" },
        ],
      },
      {
        objectKey: "promotions",
        objectIsArray: true,
        objectProperties: [
          { responsePropertyName: "id", formPropertyName: "promotion_ids" },
        ],
      },
    ],
  },
  {
    resource: "recipe-product",
    rootValues: [
      "name",
      "display_name",
      "description",
      "currency",
      "price",
      "ingredients",
      "company_id",
    ],

    nestedValues: [
      {
        objectKey: "machines",
        objectIsArray: true,
        objectProperties: [
          { responsePropertyName: "id", formPropertyName: "machine_ids" },
        ],
      },
      {
        objectKey: "promotions",
        objectIsArray: true,
        objectProperties: [
          { responsePropertyName: "id", formPropertyName: "promotion_ids" },
        ],
      },
    ],
  },
  {
    resource: "ingredient",
    rootValues: ["name", "unit_name", "company_id"],
    nestedValues: [
      {
        objectKey: "allergens",
        objectIsArray: true,
        objectProperties: [
          { responsePropertyName: "id", formPropertyName: "allergen_ids" },
        ],
      },
    ],
  },
  {
    resource: "allergen",
    rootValues: ["name", "company_id"],
  },
  {
    resource: "blueprint",
    rootValues: ["name", "company_id", "number_of_rows", "number_of_columns"],
  },
  {
    resource: "gift-cards",
    rootValues: [
      "name",
      "description",
      "company_id",
      "id",
      "is_public",
      "active",
      "price",
      "type",
      "currency",
      "discount_value",
      "purchased_gift_cards_count",
    ],
    nestedValues: [
      {
        objectKey: "usage_conditions",
        objectIsArray: true,
        objectProperties: [
          {
            responsePropertyName: "validity_amount",
            formPropertyName: "validity_amount",
          },
          {
            responsePropertyName: "validity_type",
            formPropertyName: "validity_type",
          },
        ],
      },
      {
        objectKey: "purchase_conditions",
        objectIsArray: true,
        objectProperties: [
          {
            responsePropertyName: "total_purchase_amount",
            formPropertyName: "total_purchases",
          },
        ],
      },
    ],
  },
  {
    resource: "gift-card",
    rootValues: [
      "id",
      "price",
      "type",
      "discount_value",
      "name",
      "description",
      "active",
    ],
    nestedValues: [
      {
        objectKey: "company",
        objectIsArray: true,
        objectProperties: [
          {
            responsePropertyName: "name",
            formPropertyName: "name",
          },
        ],
      },
    ],
  },
  {
    resource: "entity-categories",
    rootValues: ["name"],
  },
];
