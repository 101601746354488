import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import UserModel from "models/resources/user.model";
import apiClient from "services/api";
import { getDataById } from "services/requests/listData";

const useUserDetails = () => {
  const { t } = useTranslation();

  const [userData, setUserData] = useState<UserModel>();
  const { id } = useParams();
  const [accesCode, setAccesCode] = useState<string>();
  const [filteredMachines, setFilteredMachines] = useState();
  const [fetch, refetch] = useState<boolean>(false);
  useEffect(() => {
    getDataById(setUserData, `users/${id}`);
  }, [fetch]);
  const createUserAccessCode = async (id: number, bodyData: any) => {
    await apiClient({
      url: `users/${id}/access_codes`,
      method: "post",
      bodyData: bodyData,
    })
      .then((response) => {
        toast.success(t("successfully_generate_code").toString());
        window.location.reload();
        setAccesCode(response.data);
      })
      .catch((error) => {
        toast.error(t(error.message));
      });
  };
  const handleDelete = async (accessCodeId: number) => {
    await apiClient({
      url: `users/${id}/access_codes/${accessCodeId}`,
      method: "delete",
    }).then(() => refetch((old) => !old));
  };

  const updateAccesCode = async (bodyData: any) => {
    await apiClient({
      url: `users/${bodyData.user_id}/access_codes/${bodyData.id}`,
      method: "patch",
      bodyData: {
        code: bodyData.code,
        is_valid: bodyData.is_valid,
      },
    })
      .then((response) => {
        setAccesCode(response.data);
        refetch((old) => !old);
      })
      .catch((error) => {
        toast.error(t(error.message));
      });
  };
  useEffect(() => {
    (async () => {
      const companyId = userData?.users_companies.map((company) => company.id);
      const queryParams = {
        filter: {
          companyIds: companyId,
        },
      };
      await apiClient({
        url: `machines`,
        queryParams: queryParams,
        method: "get",
      }).then((response) => setFilteredMachines(response.data));
    })();
  }, [userData, fetch]);

  return {
    accesCode,
    createUserAccessCode,
    userData,
    setUserData,
    filteredMachines,
    handleDelete,
    updateAccesCode,
    fetch,
    refetch,
  };
};
export default useUserDetails;
