import { UseFormGetValues } from "react-hook-form";

import { TFunction } from "i18next";
import _ from "lodash";

import { isTime, normalizeDate } from "components/DatePicker/utils";
import { responseInterpretation } from "components/Forms/generic/descriptors/editForms";
import {
  AutoCompleteOption,
  AutoCompleteOptions,
  EntityApiLogic,
  FieldAutoCompleteOptions,
  FieldDescriptor,
  FormData,
  FormDataRequest,
  ProfileSettings,
} from "models/fields.model";
import QueryParams, { Filter } from "models/queryParams.model";
import RequestParams from "models/requestParams.model";
import Module, { Firmware } from "models/resources/module.model";
import {
  PriceAdjustment,
  TypeAdjustment,
} from "models/resources/price_adjustment.model";
import Promotion, {
  winConditionTypes,
  winConditions,
} from "models/resources/promotion.model";
import { DatabaseEntity, ResponseDataSuccess } from "models/responseData.model";
import apiClient from "services/api";
import { roles } from "utils/constants";
import CountryList from "utils/countryList";

import Regions from "./generic/regionsUtility";

// Dynamically access keys from interfaces
export type ParamObjectKey = keyof QueryParams;
export type FormDataKey = keyof FormData;
export type ResponseDataKey = keyof DatabaseEntity;

const countryList = new CountryList();

export const fetchEditEntity = async (
  type: string,
  call: string,
  id?: number,
) => {
  const requestParams: RequestParams = {
    url: id !== undefined ? `/${call}/${id}` : call,
    method: "get",
  };

  const response: ResponseDataSuccess<DatabaseEntity> =
    await apiClient<DatabaseEntity>(requestParams).then((response) => response);

  // TO DO: Handle error case and notification when failed to fetch data

  return response.data;
};

export const emptyOptions = (values: string[], messages: string[]) => {
  const warnings: string[] = values.map((values, idx) => {
    return values == undefined || values.length == 0 ? messages[idx] : "";
  });

  if (warnings.length == 0) return "";
  return warnings[0];
};

// Fetch database entity objects
// Refer to FieldDescriptor interface declaration for attribute use cases
export const fetchEntities = async (
  conditionalId: number,
  field: FieldDescriptor,
  editId?: number,
): Promise<DatabaseEntity[] | string | void> => {
  const {
    id,
    sendParamAsFilter,
    filterParamVariable,
    filterHardcodeKey,
    filterHardcodeValue,
    fieldAwaitsQueryParam,
    isFieldParamVariableArray,
  } = field;

  const key = filterParamVariable as ParamObjectKey;
  // prevent empty API calls with undefined query params
  if (
    fieldAwaitsQueryParam &&
    (conditionalId === undefined || conditionalId === null) &&
    editId === undefined
  )
    return;

  let filter: Filter = {
    [key]:
      sendParamAsFilter && fieldAwaitsQueryParam
        ? isFieldParamVariableArray
          ? [+conditionalId]
          : +conditionalId
        : undefined,
  };

  if (filterHardcodeKey !== undefined && filterHardcodeValue !== undefined) {
    filter = { ...filter, [filterHardcodeKey]: filterHardcodeValue };
  }

  const params: QueryParams = {
    filter: id === "currency" || id === "type" ? undefined : filter,
    pagination:
      id === "type" ? undefined : { page: 1, perPage: Number.MAX_SAFE_INTEGER },
    sort: ["type", "currency", "allergen_ids", "gift_card_id"].includes(id)
      ? undefined
      : { field: "id", order: "ASC" },
  };

  const requestParams: RequestParams = {
    url: generateFetchUrl(field, conditionalId, editId),
    queryParams: params,
    method: "get",
  };

  if (requestParams.url === "/undefined") return;

  const response: ResponseDataSuccess<DatabaseEntity[]> =
    await apiClient<DatabaseEntity[]>(requestParams);

  // TO DO: Handle error case and notification when failed to fetch data

  if (id === "currency") {
    const data = response.data as any;
    return data?.currency
      ? data?.currency
      : // Return currency array from all fetched providers from payment-credentials request.
        data?.sumup.concat(data?.paysera, data?.avalanche);
  }
  if (id === "current") {
    return ((response.data! as unknown as Module).firmware as Firmware)
      ?.all as [];
  }

  return response.data;
};

// Parse database entities to a autocomplete object with id and value
export const createAutoCompleteOptions = async (
  fieldAttributes: FieldDescriptor[],
  conditionalId: number,
  editId?: number,
  conditionalCountryState?: number,
): Promise<FieldAutoCompleteOptions[]> => {
  let fetchedOptions: (FieldAutoCompleteOptions | undefined)[];

  fetchedOptions = await Promise.all(
    fieldAttributes!.map(async (field: FieldDescriptor) => {
      if (
        field.type === "Select" ||
        field.type === "Autocomplete" ||
        field.type === "MultiAutocomplete"
      ) {
        const isCountryList =
          field.hardcodedOptionsType === "countries" ||
          field.hardcodedOptionsType === "cities" ||
          field.hardcodedOptionsType === "states";

        let options;

        if (field.useHardcodedOptions && isCountryList) {
          //@ts-ignore
          options = countryList[field.hardcodedOptionsType](
            conditionalId,
            conditionalCountryState,
          );
        } else {
          options = await fetchEntities(conditionalId, field, editId);

          if (typeof options === "string") {
            // TO DO: add notification that an error occured
          }

          if (
            field.id === "gift_card_id" &&
            Array.isArray(options) &&
            options.length > 0
          ) {
            options = options.filter(
              // @ts-ignore
              ({ active, type }: { active: boolean; type: string }) =>
                type === "promotional" && active == true,
            );
          }

          // Leave only non-attached modules for selection
          if (
            field.id === "module_id" &&
            Array.isArray(options) &&
            options.length > 0
          )
            options = _.filter(options as Module[], (option: Module) => {
              return option.machine === null || option.machine?.id === editId;
            }) as DatabaseEntity[];
        }

        return { id: field.id, options: options } as FieldAutoCompleteOptions;
      }
    })!,
  );

  return fetchedOptions?.filter(
    (entity: FieldAutoCompleteOptions | undefined) => entity !== undefined,
  ) as FieldAutoCompleteOptions[];
};

interface labelForOptionProps {
  entity: DatabaseEntity | string;
  omitParsing?: boolean;
  translate?: TFunction<"translation", undefined> | ((str: string) => string);
}

// Companies should use `display_name`. If it is not present then use the `name`.
// Locations have a format of: `name - address`.
// Modules have a format of: `serial - attached to - machine.name`
// Users have a format of: `first_name last_name`
export const getLabelForOption = ({
  entity,
  omitParsing = false,
  translate = (str: string) => str,
}: labelForOptionProps) => {
  //@ts-ignore
  if (omitParsing) return entity.label;
  if (typeof entity === "string") return entity;
  if (typeof entity === "undefined") return "Unknown label";

  // if (keyEntity && keyEntity == (field.renderedBy || field.id) && entity[keyEntity] !== null && typeof entity[keyEntity] !== 'object') {
  //   return entity[keyEntity]
  // } else if ("machine" in entity) {
  //   return `${entity?.serial} - ${entity.machine?.id
  //     ? "attached to" + " " + entity?.machine?.name
  //     : "AVAILABLE, still not attached to any machine"
  //     }`;
  // } else if ("first_name" in entity && "last_name" in entity) {
  //   return `${entity.first_name} ${entity.last_name}`;
  // } else {
  //   return 'Unknown label';
  // }
  if ("display_name" in entity && entity.display_name !== null)
    return entity.display_name!;
  else if ("name" in entity && entity.name !== null) {
    if ("address" in entity && entity.address !== null)
      return entity.name + " - " + entity.address;
    else return entity.name!;
  } else if ("machine" in entity) {
    return `${entity?.serial} - ${
      entity.machine?.id
        ? translate("attached to") + " " + entity?.machine?.name
        : translate("AVAILABLE, still not attached to any machine")
    }`;
  } else if ("currency" in entity) {
    return entity.currency!;
  } else if ("first_name" in entity && "last_name" in entity)
    return `${entity.first_name} ${entity.last_name}`;
  else return "Unknown label";
};

// Use case:
// Several auto complete fields have been populated based on the previously selected condition
// The selected condition_id changes and the selected options are no longer applicable to this condition
// This resets the fields and prevents sending API calls with invalid data.

// TO DO: Currently, only the form state is reset. Implement a visual reset for the MUI Autocomplete.
export const resetOptionsOnConditionChange = (
  formFields: FieldDescriptor[],
  resetField: Function,
): void => {
  formFields.map((field: FieldDescriptor) => {
    if (
      field.type === "Autocomplete" ||
      field.type === "MultiAutocomplete" ||
      field.type === "Select"
    ) {
      if (!field.isFieldValueConditional)
        resetField(field.id, { defaultValue: undefined });
    }
  });
};

export const parsePayload = (
  mode: string,
  resource: string,
  data: FormData,
): FormDataRequest => {
  let payload: FormDataRequest = {
    serial: data.serial,
    name: data.name,
    minimum_payment_amount: data.minimum_payment_amount,
    currency: data.currency,
    company_id: data.company_id,
    company_ids: data.company_ids,
    machine_id: data.machine_id,
    location_id: data.location_id,
    module_id: data.module_id,
    user_ids: data.user_ids,
    comments: data.comments,
    email: data.email,
    display_name: data.display_name,
    info: data.info,
    tax_number: data.tax_number,
    country: data.country,
    state: data.state,
    city: data.city,
    next_invoice_number: data.next_invoice_number,
    accountable_person: data.accountable_person,
    first_name: data.first_name,
    last_name: data.last_name,
    phone: data.phone,
    address: data.address,
    latitude: data.latitude,
    longitude: data.longitude,
    vat_load_gift_cards: data.vat_load_gift_cards,
    receive_sms: data.receive_sms,
    version: data.current ? data.current : data.version,
    description: data.description,
    price: data.price,
    machine_ids: data.machine_ids,
    allergen_ids: data.allergen_ids,
    promotion_ids: data.promotion_ids,
    unit_name: data.unit_name,
    product_type: data.product_type,
    interface_mode: data.interface_mode,
    product_ids: data.product_ids,
    type: data.type,
    discount_value: data.discount_value,
    is_public: data.is_public,
    active: data.active,
    number_of_rows: data.number_of_rows,
    number_of_columns: data.number_of_columns,
    machine_blueprint_id: data.machine_blueprint_id,
    flow: data.flow,
    entity_category_id: data.entity_category_id,
    alert_types: data.alert_types,
  };
  if (resource === "promotion") {
    data?.range?.[1].setHours(23, 59, 59);
    const getNonRequiredWinCond = () => {
      let winConds: winConditions[] = [];
      if (data?.total_rewards)
        winConds.push({
          type: winConditionTypes.TotalRewards,
          total_rewards: data.total_rewards,
        });
      if (data?.rewards_per_user)
        winConds.push({
          type: winConditionTypes.RewardsPerUser,
          rewards_per_user: data.rewards_per_user,
        });
      if (data?.rewards_per_payment)
        winConds.push({
          type: winConditionTypes.RewardsPerPayment,
          rewards_per_payment: data.rewards_per_payment,
        });
      return winConds;
    };
    payload = {
      ...payload,
      valid_from: data?.range?.[0].toISOString(),
      valid_to: data?.range?.[1].toISOString(),
      active: data.active!,
      type: data.type,
      gift_card_id: data.gift_card_id,
      win_conditions: [
        {
          currency: "BGN",
          required_sum: data.required_sum,
          type: "total_sum",
        },
        ...getNonRequiredWinCond(),
      ],
    };
  } else if (resource === "machine") {
    payload = {
      ...payload,
      type: "vending",
    };
  } else if (resource === "user")
    payload = {
      ...payload,
      role: typeof data.role?.type === "undefined" ? data.role : data.role.type,
    };
  else if (resource === "profile-edit") {
    if (typeof data.currency !== "undefined") {
      payload = {
        ...payload,
        settings: JSON.stringify({
          currency: data.currency,
        }) as ProfileSettings,
      };
    }
  } else if (resource === "change-password")
    payload = {
      ...payload,
      old_password: data.old_password,
      new_password: data.new_password,
    };
  else if (resource === "packet-product") {
    payload = {
      ...payload,
      product_type: "packaged",
    };
  } else if (resource === "recipe-product") {
    payload = {
      ...payload,

      product_type: "recipe",
    };
  } else if (resource === "price adjustment") {
    let price_adjust = data.price_adjustment ?? 0;
    let type_adjustment: TypeAdjustment = "amount";

    if (data.type_adjustment === "percentage") type_adjustment = "percent";
    if (
      (data.adjusted_sign === "price increase" && price_adjust < 0) ||
      (data.adjusted_sign == "price decrease" && price_adjust > 0)
    )
      price_adjust *= -1;
    payload = {
      ...payload,
      price_adjustment_type: type_adjustment,
      valid_from: data.range![0],
      valid_to: new Date(data.range![1].setHours(23, 59, 59, 59)),
      price_adjustment: price_adjust,
      win_conditions: [
        {
          from: data.from![0]!.toLocaleTimeString("en-GB", {
            hour12: false,
            timeZone: "UTC",
          }),
          to: data.to![0]!.toLocaleTimeString("en-GB", {
            hour12: false,
            timeZone: "UTC",
          }),
          type: winConditionTypes.TimeOfDay,
        },
      ],
    };
  } else if (resource === "gift-cards") {
    payload = {
      ...payload,
      ...(data.total_purchases !== undefined &&
      data.total_purchases.toString() !== ""
        ? {
            purchase_conditions: [
              {
                total_purchase_amount: data.total_purchases,
                type: "total_purchases",
              },
            ],
          }
        : {}),
    };
  }

  return payload;
};

export const setConditionalFieldOptions = async (
  formFields: FieldDescriptor[],
  conditionalId: number,
  setValue: Function,
): Promise<boolean> => {
  let formHasConditionalField: boolean = false;

  formFields!.map(async (field: FieldDescriptor) => {
    if (field.isFieldValueConditional) {
      const fieldOptions = await fetchEntities(conditionalId, field);

      formHasConditionalField = true;

      // If it is an array it contains payload, if it is a string it is an error message
      if (Array.isArray(fieldOptions)) {
        setValue(
          `options.0.${field.id}`,
          fieldOptions.map((entity: DatabaseEntity) => ({
            //@ts-ignore
            id: field.useIdInPayload ? entity.id! : entity.label!,
            label: getLabelForOption({ entity: entity })!,
          })),
        );
      }
    }
  });

  return formHasConditionalField;
};

interface FieldsOptionsProps {
  formFields: FieldDescriptor[];
  conditionalId: number;
  getValues: (forField: string) => UseFormGetValues<FormData>;
  update: (index: number, values: AutoCompleteOptions) => void;
  resetField?: Function;
  editId?: number;
  conditionalCountryState?: number;
  translate: TFunction<"translation", undefined>;
}

export const setFieldsOptions = async ({
  formFields,
  conditionalId,
  getValues,
  update,
  resetField,
  editId,
  conditionalCountryState,
  translate, // Used for showing only cities from the selected country-state in company create/edit page.
}: FieldsOptionsProps): Promise<void> => {
  let fetchedOptions = await createAutoCompleteOptions(
    formFields,
    conditionalId,
    editId,
    conditionalCountryState,
  );

  /* API call returns an array of the selected resource if no errors occured and a string
            / if there was an error. 
            / Update the auto complete and select values only if there was no error, otherwise
            return an empty array (if we had a previously selected value and an error occured with the new id, then
            the old options should disappear)
            */
  let modifiedValues: AutoCompleteOptions = { ...getValues("options.0") };

  // autocompletes/selects that depend on the selected condition_id (that now changes)
  if (resetField) resetOptionsOnConditionChange(formFields, resetField);

  fetchedOptions.map((fieldOption: FieldAutoCompleteOptions) => {
    // find the index
    const index = _.findIndex(formFields, (el) => el.id === fieldOption.id);
    const isCountryField =
      fieldOption.id === "country" ||
      fieldOption.id === "city" ||
      fieldOption.id === "state";

    modifiedValues = {
      ...modifiedValues,
      [fieldOption.id]: Array.isArray(fieldOption?.options)
        ? fieldOption.options.map((option: DatabaseEntity) => ({
            //@ts-ignore
            id: option.id!,
            label: getLabelForOption({
              entity: option,
              omitParsing: isCountryField,
              translate,
            }),
          }))
        : [],
    };
  });
  // Accessing the options array at index 0 is react-hook-form convention
  update(0, modifiedValues);
};

// Make the previously selected value disappear if the condition changed
// Otherwise return the same value.
// Also used to populate edit forms to prepopulate the form.
export const getAutoCompleteValue = (
  inputType: string,
  getValue: Function,
  setValue: Function,
  {
    id,
    useIdInPayload,
    useHardcodedOptions,
    hardcodedOptionsType,
    defaultHardocdedOption,
  }: FieldDescriptor,
  tableData: any,
): AutoCompleteOption[] | AutoCompleteOption | string[] | string | number => {
  const selectedValue = getValue(id);

  if (id === "flow" && selectedValue === undefined) return tableData[id];

  if (
    id === "current" &&
    selectedValue === undefined &&
    tableData.firmware !== null
  )
    return tableData.firmware?.[id] ?? [];

  if (selectedValue === undefined)
    return inputType === "MultiAutocomplete"
      ? []
      : defaultHardocdedOption ?? "";

  if (inputType === "Select") {
    if (id === "role") return _.filter(roles, { id: selectedValue })[0].id;

    return selectedValue;
  }

  // refactor: it is assumed that it is related to country/state/city
  let countryList = new CountryList();
  const options = useHardcodedOptions
    ? //@ts-ignore
      countryList[hardcodedOptionsType](
        id === "state" || id === "city" ? getValue("country") : undefined,
        id === "city" ? getValue("state") : undefined,
      )
    : getValue(`options.0.${id}`);

  if (!_.isEmpty(options)) {
    let values = [];

    if (
      !options.some((obj: any) =>
        !Array.isArray(selectedValue)
          ? selectedValue === useIdInPayload
            ? obj.id
            : obj.label
          : selectedValue.includes(useIdInPayload ? obj.id : obj.label),
      )
    ) {
      setValue(id, undefined);
    }

    values = options.filter((option: AutoCompleteOption) => {
      if (inputType === "MultiAutocomplete") {
        return useIdInPayload
          ? _.includes(selectedValue, option.id)
          : _.includes(selectedValue, option.label);
      } else {
        return useIdInPayload
          ? option.id === selectedValue
          : option.label === selectedValue;
      }
    });

    return inputType === "MultiAutocomplete"
      ? values
      : values.map((val: AutoCompleteOption) =>
          val?.label ? val.label : [""],
        )[0];
  }

  return inputType === "MultiAutocomplete" ? [""] : "";
};

export const generateFetchUrl = (
  {
    autoCompleteRequestType,
    sendParamAsFilter,
    fieldAwaitsQueryParam,
    autoCompleteRequestEndpoint,
  }: FieldDescriptor,
  conditionalId: number,
  editId?: number,
) => {
  let url = "";

  const id =
    typeof conditionalId === "undefined" ||
    (conditionalId === -1 && typeof editId !== "undefined")
      ? editId
      : conditionalId;
  // API allows following formats:
  // [resource/:id/function, resource/:id, resource] and we take into account both cases. Refer to interface declaration for further clarification.
  if (!sendParamAsFilter && fieldAwaitsQueryParam) {
    if (autoCompleteRequestType && autoCompleteRequestEndpoint) {
      url = `/${autoCompleteRequestType!}/${id}/${autoCompleteRequestEndpoint}`;
    } else if (autoCompleteRequestType)
      url = `/${autoCompleteRequestType!}/${id}`;
  } else {
    url = `/${autoCompleteRequestType!}`;
  }

  return url;
};

export const generateSubmitUrl = (
  resource: string,
  mode: string,
  call: string,
  callEndPoint: string,
  editId: number,
  selectedCompanyEditId: number,
) => {
  let url = "";
  if (resource === "profile-edit") {
    url = call;
  } else if (
    mode === "create" &&
    call &&
    callEndPoint !== "" &&
    editId !== -1
  ) {
    url = `${call}/${editId}/${callEndPoint}`;
  } else if (mode === "create" && call && callEndPoint !== "") {
    url = `${call}/${selectedCompanyEditId}/${callEndPoint}`;
  } else if (mode === "create") {
    url = call;
  } else if (call && callEndPoint !== "" && editId !== -1) {
    url = `${call}/${editId}/${callEndPoint}`;
  } else if (call && callEndPoint !== "") {
    url = `${call}/${selectedCompanyEditId}/${callEndPoint}`;
  } else {
    url = `${call}/${editId}`;
  }

  return url;
};

export const populateEditForm = async (
  resource: string,
  call: string,
  editId: number,
  setMarkerPosition: Function,
  setValue: Function,
  formFields: FieldDescriptor[],
) => {
  {
    const entity = (await fetchEditEntity(
      resource,
      call,
      editId,
    )) as DatabaseEntity;
    if (typeof entity === "undefined") return;

    // Pin location in map component
    if ("latitude" in entity! && "longitude" in entity!) {
      const entityLat = parseFloat(entity["latitude"] as string);
      const entityLng = parseFloat(entity["longitude"] as string);

      setMarkerPosition({
        lat: entityLat,
        lng: entityLng,
      });
    }
    // Fetch the logic how to interpret the response (refer to interface declaration for more info)
    let responseLogicArray: EntityApiLogic[] = _.map(
      responseInterpretation,
      (responseInfo: EntityApiLogic) => {
        if (responseInfo.resource === resource) {
          return responseInfo!;
        }
      },
    ) as EntityApiLogic[];
    let responseLogic = _.head(
      Object.values(_.omitBy(responseLogicArray, _.isNil)),
    ) as EntityApiLogic;
    _.forEach(entity, (responseValue, responseKey) => {
      if (_.includes(responseLogic.rootValues, responseKey)) {
        const currField = formFields.find((field) => field.id === responseKey);
        // If the current key is one we need at root level, set the value to the form
        if (currField?.isPrice)
          setValue(responseKey, Number(responseValue).toFixed(2));
        else setValue(responseKey, responseValue);
      }
    });
    if ((entity as Promotion)?.valid_from) {
      setValue("range", [
        new Date((entity as Promotion)?.valid_from),
        new Date((entity as Promotion)?.valid_to),
      ]);
    }

    let optionsArray: any[] = [];

    if (responseLogic?.nestedValues) {
      _.forEach(responseLogic.nestedValues!, (nestedValue) => {
        //@ts-ignore
        let entityKey = entity[nestedValue.objectKey];

        if (nestedValue.objectKey === "ingredients[0]") {
          // Used for parsing allergen values to product edit form.
          //@ts-ignore
          entityKey = entity["ingredients"][0].allergens;
        }
        _.forEach(
          //@ts-ignore
          entityKey,
          (responseNestedValue, responseNestedKey) => {
            if (nestedValue.objectIsArray) {
              let formValue = "";

              _.forEach(
                responseNestedValue,
                (nestedArrayValue, nestedArrayKey) => {
                  _.forEach(
                    nestedValue.objectProperties,
                    (objectPropertyValue) => {
                      if (
                        objectPropertyValue.responsePropertyName ===
                        nestedArrayKey
                      ) {
                        if (isTime(nestedArrayValue))
                          nestedArrayValue = normalizeDate(nestedArrayValue);
                        optionsArray.push(nestedArrayValue);
                        formValue = objectPropertyValue.formPropertyName;
                      }
                    },
                  );
                  setValue(formValue, optionsArray);
                },
              );
            } else {
              _.forEach(nestedValue.objectProperties, (objectPropertyValue) => {
                if (
                  objectPropertyValue.responsePropertyName === responseNestedKey
                ) {
                  const currField = formFields.find(
                    (field) =>
                      field.id === objectPropertyValue.formPropertyName,
                  );
                  if (currField?.textFieldType === "number")
                    setValue(
                      objectPropertyValue.formPropertyName,
                      Number(responseNestedValue).toFixed(2),
                    );
                  else
                    setValue(
                      objectPropertyValue.formPropertyName,
                      responseNestedValue,
                    );
                }
              });
            }
          },
        );
        optionsArray = [];
      });
    }

    if (resource === "price adjustment") {
      setValue(
        "adjusted_sign",
        (entity as Promotion).gift_card.discount_value[0] === "-"
          ? "price decrease"
          : "price increase",
      );

      setValue(
        "type_adjustment",
        (entity as PriceAdjustment).gift_card.discount_type === "percent"
          ? "percentage"
          : "fixed amount",
      );
    }
    return { entity, responseLogic };
  }
};

export const getCountryStateCity = (
  type: string,
  countryCode?: string,
  stateName?: string,
) => {
  const getCountryChoices = () => {
    const countries = Regions.getAllCountries().map((country) => ({
      label: country.name,
    }));

    return countries;
  };

  const getStateChoices = (country: string) => {
    if (typeof country !== "undefined") {
      const states = Regions.getStatesOfCountry(country);
      return states.map((state) => {
        return {
          label: state,
        };
      });
    }
  };

  const getCityChoices = (countryCode: string, stateName: string) => {
    if (
      typeof countryCode !== "undefined" &&
      typeof stateName !== "undefined"
    ) {
      const cities = Regions.getCitiesOfState(countryCode, stateName)!;

      return (cities ?? []).map((cities: any) => {
        return {
          label: cities.name,
        };
      });
    }
  };

  const returnType = {
    countries: getCountryChoices(),
    states: getStateChoices(countryCode!),
    cities: getCityChoices(countryCode!, stateName!),
  };

  //@ts-ignore
  return returnType[type];
};

export const prepaidCardMethod = {
  defaultValues: {
    userAmounts: [{ email: "", amount: null }] as
      | {
          userAmounts?:
            | (
                | {
                    email?: string | undefined;
                    amount?: number | undefined;
                    rate?: number | undefined;
                  }
                | undefined
              )[]
            | undefined;
        }
      | undefined,
  },
};
