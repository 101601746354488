export const translation = {
  greeting: "Привет!",
  ra: {
    page: {
      dashboard: "Начало",
      list: "Списък",
      create: "Нов запис",
      edit: "Редакция",
      show: "Преглед",
      empty: "Празна страница...",
      error: "Грешка в страницата :(",
    },
    auth: {
      logout: "Изход",
      user_menu: "Моето меню",
      username: "Потребител",
      password: "Парола",
      sign_in: "Вход",
      auth_check_error: "Моля, логнете се!",
    },
    action: {
      refresh: "Презареждане",
      create: "Добавяне",
      export: "Експорт",
      add_filter: "Още филтри",
      sort: "Сортиране",
      cancel: "Отказ",
      confirm: "Потвърждаване",
      save: "Запис",
      undo: "Откажете промените",
      back: "Назад",
      add: "Добавяне",
      remove: "Премахване",
    },
    message: {
      error: "Грешка",
    },
    navigation: {
      next: "напред",
      prev: "назад",
      page_range_info: "Страници",
      no_results: "Няма намерени резултати",
    },
    notification: {
      updated: "Записът е обновен.",
      data_provider_error: "Грешка при извличане на данните!",
      item_doesnt_exist: "Записът не е намерен в базата данни!",
    },
    validation: {
      required: "Задължително поле",
    },
  },
  labels: {
    totalAmount: "Обща сума",
    requiredFields: "Моля попълнете всички задължителни полета",
  },
  List: "Списък",
  Save: "Запис",
  Name: "Системно име",
  "Machine name / serial": "Име на машина / сериен номер",
  "First Name": "Малко име",
  Email: "Имейл",
  "Email is already in use or invalid":
    "Имейлът вече се използва или е невалиден",
  Address: "Адрес",
  "Entity Serial": "Сериен номер на модул",
  Currency: "Валута",
  "Last Name": "Фамилия",
  Company: "Фирма",
  Role: "Роля",
  "Location map": "Карта",
  "Optional comments": "Коментари",
  Orders: "Поръчки",
  "Orders Date": "Дата на поръчката",
  "Orders Time": "Час на поръчката",
  "since last month": "от последния месец",
  Companies: "Фирми",
  companies: "Фирми",
  Location: "Локация",
  Module: "Модул",
  "Are you sure?": "Сигурни ли сте?",
  "Loading...": "Зареждане...",
  "Loading, please wait": "Зарежда, моля изчакайте",
  All: "Всички",
  "Download QR Code": "Изтеглете QR кода",
  "Are you sure you want to delete this entity?":
    "Сигурни ли сте че искате да изтриете този запис?",
  "Do you wish to receive SMS?": "Желаете ли да получавате SMS-и?",
  Yes: "Да",
  No: "Не",
  Latitude: "Географска ширина",
  Paid: "Платена",
  Longitude: "Географска дължина",
  Failed: "Неуспех",
  "Timed Out": "Незавършени в срок",
  "Paid transactions for period selected":
    "Платените трансакции за избраният период",
  "Failed / timed-out transactions": "Неуспешни / неприключени трансакции",
  Up: "Включени",
  Down: "Изключени",
  up: "работи",
  down: "изключен",
  Machines: "Машини",
  "Total Sales": "Всичко продажби",
  BGN: "BGN",
  "BGN/day": "лв./ден",
  "AVERATO Administration Platform": "Платформа за администриране",
  "Daily revenue for period selected": "Приходи ден по ден за избрания период",
  "Machines module states": "Статуси на AVERATO модулите",
  "Password changed!": "Паролата е променена!",
  "Company name": "Име на фирма",
  "Location Name": "Име на локация",
  "Location name": "Име на локация",
  "Machine name": "Име на машина",
  "Machine Name": "Име на машина",
  "MDB settings": "MDB настройки",
  "MDB Settings": "MDB настройки",
  "You must select a company first": "Трябва да изберете компания",
  "There are no modules available in the specified company":
    "Няма налични модули към текущата компания",
  "MDB settings for module": "MDB настройки за модул",
  "First name": "Име",
  "Set up your SumUp credentials":
    "Настройки за приемане на плащания чрез SumUp и Avalanche",
  "Unknown response!": "Непознат системен отговор!",
  "Company info": "Фирмена информация",
  Comments: "Коментари",
  "Important notice": "Важно съобщение",
  "Important notice: MDB settings are low level technical ones. Please, only change values if you are 100% sure about what you are doing!":
    "Важно: MDB настройките са технически и на ниско ниво. Моля променяйте само ако сте на 100% сигурни какво правите!",
  "Last name": "Фамилия",
  "Client ID": "Клиентско ID",
  "Invalid old password!": "Невалидна парола!",
  "SumUp credentials": "Настройки за приемане на плащания",
  "MDB settings are low level technical ones.":
    "MDB настройките са строго технически!",
  "Client secret": "Тайна дума",
  "Project ID": "ID на проект",
  "Project Key": "Ключ на проект",
  "Password must be at least 6 characters long!":
    "Паролата трябва да има минимална дължина от 6 символа!",
  "Set credentials": "Запис на идентификационните данни",
  "Peripheral Address": "Периферен адрес",
  "Merchant code": "Код на търговеца",
  "Password field can't be blank!": "Полето за парола е празно!",
  "Password missmatch!": "Паролите не са еднакви!",
  "Feature level": "Ниво на функциите",
  "Error occured!": "Възникна грешка!",
  "Scale factor": "Фактор за умножение",
  "Please, provide your old / current password!":
    "Моля, въведете вашата стара / текуща парола!",
  "App max response time": "Максимално време за отговор на приложението",
  "Old password": "Текуща парола",
  "Country / currency code": "Код на държава / валута",
  "New password": "Нова парола",
  "Decimal places": "Бр. десетични знаци",
  "Max price": "Максимална цена",
  "Min price": "Минимална цена",
  "Price change": "Посока на промяна на цената",
  "price increase": "увеличение",
  "price decrease": "намаление",
  "Miscelaneous options": "Опции",
  "Wrong URL": "Грешен URL",
  Averato: "АВЕРАТО",
  "Sorry! The URL you typed is wrong!":
    "Съжаляваме! Попълненият адрес е грешен!",
  "Need to get something, but got no cash?":
    "Искате да си вземете нещо, но нямате дребни?",
  "That's OK ;) All you need to do is": "Няма проблем ;) Единствено трябва",
  "1. Download and install the free Averato app.":
    "1. Да изтеглите безплатното приложение АВЕРАТО.",
  "Get Averato for Android": "Свалете АВЕРАТО за Android",
  "Get Averato for iOS": "Свалете АВЕРАТО за IOS",
  "2. Open the app and scan the QR code again.":
    "2. Да отворите приложението и сканирате QR кода отново.",
  "See where else you can pay with Averato":
    "Вижте къде другаде можете да платите с АВЕРАТО",
  "My Profile": "Моят профил",
  "No access": "Няма достъп",
  "Completing transaction... Plase, wait!":
    "Завършване на трансакцията... Моля, изчакайте!",
  "Please, check your email for password reset link!":
    "Моля, проверете имейла си за код с който да нулирате паролата си!",
  "Password changed! Please, log in": "Паролата е сменена! Моля влезте.",
  "My Companies": "Моите компании",
  "You are restricted to access this resource.":
    "Нямате право на достъп до тази функция.",
  "Email not found!": "Имейлът не е намерен!",
  "Token does not exist! Please, check the link in your email!":
    "Токенът не съществува! Моля, проверете линка във Вашия емайл!",
  "System error!": "Системна грешка!",
  "Change Password": "Промяна на паролата",
  "Change password": "Промяна на парола",
  "Sign in": "Влезте",
  "Type a new password": "Въведете нова парола",
  "Retype the password": "Въведете я отново",
  "Please, enter a new password": "Моля, въведете нова парола",
  "Reset password": "Смяна на паролата",
  "Forgot password": "Забравена парола",
  Edit: "Редактирай",
  Create: "Създаване",
  Show: "Покажи",
  "Module Serial": "Сериен номер на модула",
  "Module successfully disabled": "Модулът е деактивиран успешно",
  "Order Date": "Дата на поръчка",
  "Checkout ID": "ID на трансакция",
  Hide: "Скрий",
  "Please, attach Averato module to display QR payment code!":
    "Моля, свържете модул АВЕРАТО, за да генерирате QR код за плащане към тази машина!",
  "Could not disable module": "Модулът не може да бъде деактивиран",
  Origin: "Произход",
  "Order Time": "Време на поръчката",
  "Order items": "Закупени продукти",
  "Machine Serial": "Сериен номер",
  preorder: "поръчана",
  onsite: "на място",
  system_menu: "системно меню",
  Map: "картата",
  "You can pay with": "Може да платите с",
  "* Scan the QR code for more information":
    "* Сканирайте QR кода за повече информация",
  "* Scan the QR code for more": "* Сканирайте QR кода за повече",
  information: "информация",
  "Module Signal Strength": "Сила на сигнала на модула",
  "Module successfully enabled": "Модулът е активиран",
  "Product number": "Номер на продукт",
  Status: "Статус",
  "Module State": "Състояние на модула",
  "Could not enable module": "Модулът не може да бъде активиран",
  "Unit price": "Единична цена",
  "Transaction Date": "Дата на трансакцията",
  "Transactions Date": "Дата на трансакцията",
  Date: "Дата",
  "Peripheral address": "Периферен адрес",
  "Firmware update was started": "Актуализацията на фърмуера бе стартирана",
  Quantity: "Наличност",
  "Recharge Quantity": "Кол. за презареждане",
  Capacity: "Вместимост",
  "Recharge Quantities": "Презареди количества",
  "Cancel Pre-orders": "Анулираи предварителните поръчки",
  "Are you sure you want to recharge the machine quantities?":
    "Сигурни ли сте, че искате да презаредите количествата на машината?",
  "Are you sure you want to cancel all preorders?":
    "Сигурни ли сте, че искате да анулирате всички предварителни поръчки?",
  Preview: "Разгледай",
  "Must be equal or lower than capacity":
    "Трябва да е равна или по-малка от вместимостта.",
  "Transaction Time": "Час на трансакцията",
  "Transactions Time": "Час на трансакцията",
  "Sales today": "Продажби днес",
  Today: "Днес",
  Time: "Час",
  "Could not update firmware": "Не може да се актуализира фърмуера",
  "Order total": "Обща стойност на поръчката",
  Amount: "Сума",
  "fixed amount": "фиксирана стойност",
  percentage: "процент",
  "Module task successfully resset to idle":
    "Модулът е поставен в състояние на изчакване",
  "Could not reset module task to idle":
    "Неуспех при опита за поставяне на модула в изчакване",
  idle: "в изчакване",
  payment: "извършва се плащане",
  surcharge: "извършва се доплащане",
  order: "изпълнява се поръчка",
  "Module serial": "Сериен номер на модула",
  "Module MCU": "MCU на модула",
  "Current firmware": "Версия на фърмуера",
  "Last firmware update": "Последна актуализация на фърмуера",
  update: "обновяване",
  Update: "Обнови",
  View: "Разгледай",
  disable: "деактивирай",
  enable: "активирай",
  "Update firmware": "Актуализирайте фърмуера",
  "Reset task": "Прекратяване на задачата",
  "Signal Strength": "Сила на сигнала",
  "QR code": "QR код",
  "MCU Version": "MCU Версия",
  ICCID: "ICCID",
  "Current Firmware": "Текущ фърмуер",
  Machine: "Машина",
  Pin: "Пин",
  "Product type": "Тип продукти",
  "Module state": "Състояние на модула",
  Uptime: "Време от последния рестарт / включване",
  Task: "Задача",
  "Are you sure you want to reset the module task to idle?":
    "Сигурни ли сте, че искате да включите модула в режим на изчакване?",
  Reset: "Нулиране",
  Version: "Версия",
  Search: "Търсене",
  "Filter by company": "Филтрирайте по фирма",
  "From date": "От дата",
  Dashboard: "Начало",
  None: "Нито един",
  "Company Name": "Име на фирмата",
  Delete: "Изтрий",
  QR: "QR",
  "Signal strength": "Сила на сигнала",
  "To date": "До дата",
  "Attached to machine": "Свързан към машина",
  "Filter by machine": "Филтрирай по машина",
  "Show revenue": "Покажи приходите",
  active: "Активен",
  inactive: "Неактивни",
  ID: "ID",
  "Added to cloud": "Дата на регистрация",
  Firmware: "Фърмуер",
  state: "състояние",
  State: "Състояние",
  "Country state": "Област",
  "Country State": "Oбласт",
  uptime: "време на работа",
  task: "задача",
  Disable: "Деактивиране",
  Enable: "Активиране",
  "3. Setup your payment method and pay the desired amount.":
    "3. Настройте начина си на плащане и платеже цената на продукта през приложението.",
  "Enjoy!": "Насладете се на избора си!",
  "Enter your credentials to accept payments to your SumUp account":
    "Редакция на данните за иницииране на плащания",
  "Please, only change values if you are 100% sure about what you are doing!":
    "Редактирайте настройките само, ако сте напълно сигурни в това, което правите!",
  "Add company": "Добавяне на фирма",
  "Add location": "Добавяне на локация",
  "Add machine": "Добавяне на машина",
  "Add QR code": "Добавяне на QR код",
  "Add virtual POS": "Добавяне на виртуален POS",
  "Add promotion": "Добавяне на промоция",
  "Add blueprint": "Добавяне на чертеж",
  "This field is required": "Това поле е задължително",
  "Remove user": "Премахване на потребител",
  Submit: "Изпращане",
  "For Company": "За Компания",
  "Add resource": "Добавяне на ресурс",
  Close: "Затвори",
  "Search location": "Търсене на локации...",
  "QR Code Size": "Размер на QR кода",
  "Remove Logo": "Премахване на логото",
  "Select download format": "Изберете формат за изтегляне",
  SVG: "SVG",
  PNG: "PNG",
  JPEG: "JPEG",
  "QR code for module": "QR за модул",
  "Edit existing company": "Редакция на компания",
  "Edit existing location": "Редакция на локация",
  "attached to": "свързан към",
  "Edit your profile": "Редактирайте профила си",
  "Edit existing user": "Редакция на потребител",
  "AVAILABLE, still not attached to any machine":
    "СВОБОДЕН, не е свързан към никоя машина",
  "Edit existing machine": "Редакция на машина",
  module: "модул",
  "attached to machine": "свързан към машина",
  "NO module attached": "НЯМА свързан модул",
  "Edit existing module": "Редакция на модул",
  "Please, select the desired amount to pay.":
    "Моля, посочете сумата, която желаете да платите.",
  "Please, specify amount to pay": "Моля, посочете сума за плащане",
  "1. Download and install the free MyPay.bg app.":
    "1. Изтеглете и инсталирайте безплатното MyPay.bg приложение.",
  Pay: "Платете",
  "Get MyPay.bg for Android": "Вземете MyPay.bg за Андроид",
  now: "сега",
  "Get MyPay.bg for iOS": "Вземете MyPay.bg за iOS",
  "Pay without installing the app": "Плащане без инсталиране на приложение",
  "See where else you can pay with AVERATO":
    "Вижте още къде можете да плащате с АВЕРАТО",
  "MCU version & Firmware": "MCU версия и фърмуер",
  "---": "---",
  "View and download QR": "Преглед / изтегляне на QR код",
  Locations: "Локации",
  "Virtual POS": "Виртуални POS",
  "QR payment codes": "QR кодове за плащане",
  "QR Payment Codes": "QR кодове за плащане",
  Transaction: "Трансакция",
  Transactions: "Трансакции",
  external: "външно за системата",
  Telemetry: "Телеметрия",
  Users: "Потребители",
  users: "Потребители",
  "SumUp Credentials": "Платежни настройки",
  Modules: "Модули",
  "Phone number": "Телефон",
  "Phone is invalid": "Номерът е невалиден.",
  "Location created!": "Локацията е създадена!",
  "Machine name fields is required!": "Името на машината е задължително поле!",
  "User created!": "Потребителят е създаден!",
  "Type of payment receiving point": "Тип на точката за получаване на плащания",
  "Company created!": "Търговецът е създаден!",
  "Currency fields is required!": "Полето за валута е задължително!",
  "POS name": "Име на POS",
  "Please, select a company!": "Моля, посочете фирма!",
  "Auto generate new serial number": "Генериране на нов сериен номер",
  "Please, select machine location!": "Моля, посочете локацията на машината!",
  "Auto generate unique serial number": "Генериране на уникален сериен номер",
  "Unique POS serial": "Уникален номер на POS",
  "Associated users": "Потребители с достъп",
  "Add user": "Добавяне на потребител",
  Cancel: "Отказ",
  "Cancel Invoice": "Отказ от фактура",
  "Request Invoice": "Заявка за фактура",
  "Rows per page": "Редове на страница",
  "Are you sure you want to delete this record?":
    "Сигурни ли сте, че желаете да изтриете този запис?",
  "EUR/day": "ЕВРО/ден",
  "Delete confirmation": "Потвърждаване на изтриването",
  "Profile updated": "Профилът е обновен",
  "SumUp credentials updated.":
    "Идентификационните данни за плащане са обновени!",
  "Edit existing virtual POS": "Редактиране на виртуален POS",
  "Additional settings": "Допълнителни настройки",
  Language: "Език",
  English: "English",
  Български: "Български",
  "Payment made!": "Плащането е успешно!",
  "Payment is being processed...": "Плащането се обработва...",
  "Payment failed!": "Плащането е неуспешно!",
  "That's OK ;) All you need to do is:": "Няма проблем ;) Единствено трябва:",
  "Loading machine data...": "Зареждане на данните за машината...",
  "Transaction not found in system!": "Трансакцията не е намерена в системата!",
  "Vending machine busy! Please, try again in a minute or two!":
    "Вендинг автоматът е зает! Моля, опитайте отново след 1-2 минути!",
  "Expired URL or QR code.": "Невалиден или остарял QR код.",
  "Payment FAILED! Please, try again!":
    "Плащането е НЕУСПЕШНО! Моля, опитайте отново!",
  "Invalid parameters sent in request.":
    "Заявката съдържаше невалидни параметри.",
  "See where you can pay with AVERATO": "Виж къде може да плащаш с АВЕРАТО",
  "Loading transaction data....": "Зареждане на данните за трансакцията....",
  "Vending machine out of order. Sorry!":
    "Вендинг машината няма връзка с АВЕРАТО. Съжаляваме!",
  "Pay for": "Плащане за",
  "Set up credentials for accepting payments through SumUp":
    "Настройте идентификацията си за приемане на плащания",
  "Set up credentials for accepting payments in the respective currency":
    "Настройки за приемане на плащания",
  "Are you sure you want to delete this location?":
    "Сигурни ли сте, че желаете да изтриете локацията?",
  "Are you sure you want to delete this machine?":
    "Сигурни ли сте, че желаете да изтриете машината?",
  "Communication error": "Комуникационна грешка",
  "Are you sure you want to delete this user?":
    "Сигурни ли сте, че желаете да изтриете потребителя?",
  "POS type:": "Тип POS",
  "Are you sure you want to delete this company?":
    "Сигурни ли сте, че желаете да изтриете тази фирма?",
  "Are you sure you want to delete this module?":
    "Сигурни ли сте, че желаете да изтриете този модул?",
  "POS serial": "POS сериен номер",
  "Set POS serial now": "Настройте POS сериен номер",
  "Are you sure you want to delete this VPOS?":
    "Сигурни ли сте, че желаете да изтриете този виртуален POS?",
  "Are you sure about deleting this ingredient?":
    "Сигурни ли сте, че искате да откачите съставката от машината?",
  "Are you sure about deleting this product?":
    "Сигурни ли сте, че искате да откачите този продукт от машината?",
  "Filter by role": "Филтриране по роля",
  "POS/QR serial": "POS/QR сериен номер",
  "Forgot password?": "Забравена парола",
  "Register as company owner": "Регистрирай се като собственик на компания",
  AVERATO: "АВЕРАТО",
  "Remembered passowrd?": "Спомнихте си паролата?",
  "Please enter the Email associated with your account":
    "Моля, въведете имейла с който сте регистрирани!",
  "AVERATO module is currently offline. Please, try again later.":
    "АВЕРАТО не можа да се свърже с машината. Моля, опитайте по-късно",
  "low signal": "слаб сигнал",
  "medium signal": "добър сигнал",
  "high signal": "отличен сигнал",
  high: "отличен сигнал",
  "no_signal signal": "няма покритие",
  "URL encoded in QR code": "Адрес кодиран в QR кода",
  PAID: "ПЛАТЕНА",
  FAILED: "НЕУСПЕШНА",
  TIMED_OUT: "неприключена в нужното време",
  PENDING: "Изпълнява се в момента",
  EXPIRED: "",
  Taken: "Взет",
  yes: "да",
  no: "не",
  "Order details product quantity": "Количество",
  "All transactions": "Всички трансакции",
  success: "успешна",
  Admin: "Администратор",
  admin: "Администратор",
  "Company Owner": "Управител на фирма",
  "Company Operator": "Оператор на фирма",
  "Company Technician": "Техник на фирма",
  owner: "Управител на фирма",
  Operator: "Оператор",
  operator: "Оператор",
  Technician: "Техник",
  technician: "Техник",
  "Paid only": "Платени",
  "Failed only": "Неуспешни",
  "Timed out only": "Незавършени в срок",
  "Pending only": "Извършващи се в момента",
  "Filter by status": "Филтриране по статус",
  "Logo in the middle of QR code (optional)":
    "Лого в центъра на кода (по желание)",
  "Pay again": "Плащане отново",
  "The payment request expired before being completed! Please, try again!":
    "Заявката за плащане не бе приключена в нужното време. Моля, опитайте отново!",
  "Payment made! Amount paid will be credited to vending machine in a few seconds":
    "Успешно плащане! Сумата ще бъде отчетена от вендинг автомата до няколко секунди.",
  "Filter by location": "Филтриране по локация",
  "QR Code name": "Име на QR кода",
  "QR Code Name": "Име на QR кода",
  "Unique QR serial": "Уникален сериен номер на QR кода",
  "Fixed amount QR code": "QR код със сума за плащане",
  "Enter amount to be paid": "Въведете сума за плащане",
  "MDB Settings updated.": "MDB настройките са обновени.",
  "Date Format Types": "Формат на датата",
  "Edit existing QR Code": "Редакция на QR код",
  CHF: "CHF",
  "Sorry, the module is busy": "За съжаление модулът е зает",
  Serial: "Сериен номер",
  "The module is out of order": "Платката е извън строя",
  "Add currency": "Добавяне на валута",
  "Avalanche payment": "Плащане през Avalanche",
  "Please fill in all the fields": "Моля, попълнете всички полета",
  "Avalanche wallet address updated.":
    "Адресът на Avalanche портфейла е обновен.",
  "Avalanche Wallet Address": "Адрес на Avalanche портфейл (X-Chain)",
  "Loading payment form":
    "Зареждане на полетата за въвеждане на данни за картата...",
  "No module attached": "Няма свързан модул",
  "Communication errro": "Грешка в комуникацията",
  "Payment point": "Точка на плащане",
  "QR code serial": "Уникален сериен номер на QR кода",
  "QR code name": "Име на QR кода",
  "No SumUp credentials have been defined for the company yet. Please, set up the first ones using the form below!":
    "Няма записани настройки за извършване на плащания към тази фирма. Изберете валута от списъка, за да създадете такива!",
  "Payment credentials deleted.": "Настройките за плащане са изтрити!",
  "Payment credentials": "Настройки за плащане",
  "Payment Credentials": "Настройки за плащане",
  "API Credentials": "Настройки за API",
  "API Tokens": "Токени за API",
  "Valid until": "Валиден до",
  "Create API Token": "Създаване на АПИ токен",
  Sandbox: "Тестов режим",
  "Last used": "Последно използван",
  Description: "Описание",
  "Callback url": "Урл за обратна връзка",
  "Valid until field is required!": "Полето 'Валиден до' е задължително!",
  "Selected company does not have any payment credentials yet":
    "Избраната компания няма налични настройки за плащане.",
  "Avalanche credentials deleted.":
    "Настройките за плащане през Avalanche са изтрити.",
  "Delete credentials for": "Изтриване на настройките за",
  "You will not be able to accept payment in this currency any more!":
    "Няма да можете да получавате плащания в тази валута.",
  "You will not be able to accept Avalanche payments any more!":
    "Няма да можете да получавате плащания през Avalanche.",
  "Product Number": "Продуктов номер",
  "POS Amount": "Количество POS",
  "POS Currency": "Базова валута POS",
  "Currency at transaction": "Базова валута на трансакцията",
  "Please, select currency to view or edit existing credentials.":
    "Моля, изберете валута, за да видите/редактирате настройките за приемане на плащания в нея.",
  "Please, use X button instead of browser's Back button to close the dialog without reloading!":
    "Моля, използвайте бутон X на вътрешния прозорец, вместо бутон Назад на браузъра, за да го затворите без презареждане!",
  "Revenue by currency": "Постъпления по валути",
  Promotions: "Промоции",
  "Promotion Type": "Тип промоция",
  "Promotion type:": "Тип промоция",
  "Price Adjustment": "Променлива цена",
  "Price adjustment": "Променлива цена",
  "price adjustment": "Променлива цена",
  "Adjustment type": "Тип на промяна на цената",
  "Price difference": "Разлика в цена",
  "Total rewards": "Общ брой награди",
  "Rewards per user": "Позволени печалби за потребител",
  "Rewards per payment": "Позволени печалби за едно плащане",
  "Pay Now": "Платете сега",
  "Get Later": "Получете по-късно",
  "Get later": "Получете по-късно",
  "Promotion from": "От дата",
  "Promotion to": "До дата",
  total_sum: "Обща стойност продажби",
  time_of_day: "Часови диапазон от денонощието",
  "Starts at": "Започва от",
  "Ends at": "Завършва в",
  "Required sum": "Необходима сума",
  "Win Conditions": "Условия за печалба",
  "Win conditions": "Улсовия за печалба",
  "Win conditions - total sum": "Условия за печалба - обща сума",
  "Win conditions - total rewards": "Условия за  печалба - общ брой награди",
  "Win conditions - rewards per user":
    "Условия за печалба - позволени печалби за потребител",
  "Win conditions - rewards per payment":
    "Условия за печалба - позволени печалби за едно плащане",
  "Valid from": "Валидна от",
  "Valid to": "Валидна до",
  "Validity period": "Период на валидност",
  Unlocked: "Отключено",
  "Promotion period": "Промоционален период",
  "Accumulative amount": "Обща стойност продажби",
  From: "От",
  To: "До",
  "Date From": "От дата",
  "Date To": "До дата",
  Type: "Тип",
  "Company ID": "Идентификатор на компания",
  Id: "Идентификатор",
  "Promotion ID": "Идентификатор на промоция",
  "Promotion Pay Now": "Платете сега",
  "Promotion Get Later": "Получете по-късно",
  "Edit existing promotion": "Редактиране на съществуваща промоция",
  "Prepaid cards": "Предплатени карти",
  "Gift card": "Карта за подарък",
  "Custom amount for every user": "Ръчно въвеждане на суми за всеки потребител",
  "Request invoice": "Заяви фактура",
  "for company": "за компания",
  "For company": "за компания",
  "Amount per user": "Сума за всеки потребител",
  SEND: "Изпращане",
  Employee: "Служител",
  "Payment finished successfully.": "Плащането завърши успешно.",
  "Payment failed.": "Плащането се провали.",
  "Save successful": "Записът е успешен",
  "The current page data will be overwritten are you sure?":
    "Данните на страницата ще бъдат презаписани. Искате ли да продължите?",
  "Please select a company.": "Моля изберете компания.",
  "Please select a currency.": "Моля изберете валута.",
  "Provided parameters are insufficient to process a payment.":
    "Въведените данни са недостатъчни за задействане на плащане",
  "Payments linked to this QR code are not accepted at the moment.":
    "Не се приемат плащания към този QR код в момента.",
  "Payment done!": "Плащането е извършено!",
  failed: "неуспешна",
  open: "отворена",
  "Pay now": "Платете сега",
  "Payment type": "Начин на плащане",
  "End User": "Краен потребител",
  "End Users": "Крайни Потребители",
  "end Users": "Крайни Потребители",
  Administrator: "Администратор",
  "Payment cannot proceed. Please double check the information provided.":
    "Плащането не може да се осъществи. Моля повторно проверете предоставената информация.",
  "Company field is required": "Полето за фирма е задължително",
  '"Date from" field is required': 'Полето "От дата" е задължително',
  '"Date to" field is required': 'Полето "До дата" е задължително',
  "Promotion type: field is required": "Полето за тип промоция е задължително",
  '"Pay now" field is required': 'Полето "Платете сега" е задължително',
  '"Get later" field is required': 'Полето "Получете по-късно" е задъижително',
  "Currency field is required": "Полето за валута е задължително",
  "Start date cannot be after end date":
    "Началната дата не може да е след крайната дата",
  '"Pay now" value must be a number':
    'Стойността на "Платете сега" трябва да е число',
  '"Get later" value must be a number':
    'Стойността на "Получете по-късно" трябва да е число',
  '"Pay now" value must be less than "Get Later" value':
    'Стойността на "Платете сега" трябва да е по-малка от "Получете по-късно"',
  "You are not registered as company user.":
    "Не сте регистриран като служител на компания.",
  "Email field is required!": "Полето имейл е задължително",
  "Password field is required!": "Полето парола е задължително",
  "Invalid email or password!": "Невалиден имейл или парола",
  "What is your first name?": "Какво е вашето първо име?",
  "What is your last name?": "Какво е вашето фамилно име?",
  Hello: "Здравейте",
  "Your account is almost ready": "Вашият профил е почти готов",
  "What is your E-mail?": "Какъв е вашият имейл адрес?",
  "I have read, understood and agree to the Averato policy.":
    "Прочетох и съм съгласен с политиката на Аверато.",
  "Registration successful. A password has been sent to your E-mail address.":
    "Вие се регистрирахте успешно. Паролата е изпратена на имейл адреса Ви.",
  "Your company has been registered successfully.":
    "Вашата компания е успешно регистрирана.",
  Back: "Назад",
  Next: "Напред",
  Country: "Държава",
  City: "Град",
  "Tax number": "Данъчен номер",
  Number: "Номер",
  "Next invoice number": "Начален номер на фактура",
  "Accountable person": "Материално отговорно лице (МОЛ)",
  "VAT% (load prepaid cards)": "ДДС% (зареждане на предплатени карти)",
  "Phone Number": "Телефонен номер",
  "What is the legal name of your company?":
    "Какво е официалното име на компанията Ви?",
  "What is the display name of your company?":
    "Какво име да използваме при показване на компанията Ви?",
  "What is your company phone number?":
    "Какъв е телефонния номер на компанията Ви?",
  "What is your company E-mail?": "Какъв е имейл адреса на компанията Ви?",
  "What country is your company located in?":
    "В коя държава се намира компанията Ви?",
  "What city is your company located in?":
    "В кой град се намира компанията Ви?",
  "What is your company's address?": "Какъв е адреса на компанията Ви?",
  "What is your company's tax number?":
    "Какъв е данъчния номер на компанията Ви?",
  "What is your next invoice number?":
    "Какъв е началния номер на фактурата Ви?",
  "Who is your accountable person?":
    "Кой е Материално отговорно лице (МОЛ) на компанията Ви?",
  "Create company": "Създаване на компания",
  "We need your company details": "Нуждаем се от информация за вашата компания",
  "Company creation failed. A company with this name/E-mail already exists!":
    "Създаването на компания се провали. Вече съществува компания с това име/имейл адрес.",
  "Please provide a valid phone number!":
    "Моля въведете валиден телефонен номер!",
  "This machine name is already taken. Please choose another one!":
    "Името на машината вече е заето. Моля, изберете друго!",
  "Your machine has been added successfully.":
    "Вашата машина е добавена успешно.",
  Welcome: "Добре дошли",
  "Accountable Person": "Материално отговорно лице (МОЛ)",
  Actions: "Действия",
  Approve: "Одобряване",
  Decline: "Откажи",
  approved: "одобрена",
  declined: "отхвърлена",
  waiting: "изчакващи",
  "waiting companies": "фирми изчакващи разрешение",
  "This company is awaiting approval.": "Тази фирма изчаква одобрение.",
  "An user with the provided E-mail address already exists.":
    "Потребител с този имейл адрес вече съществува.",
  "Companies awaiting approval": "Компании изчакващи одобрение",
  "Company successfully declined.": "Компанията е отхвърлена.",
  "Company successfully approved.": "Компанията е одобрена.",
  "Change status to": "Променете статуса на",
  "You are about to change the status of the company to":
    "Предстои да промените статуса на компанията на",
  "You are about to make the following action":
    "Предстои да направите следното действие",
  Confirm: "Потвърди",
  Deny: "Откажи",
  "Change status": "Променете Статуса",
  Load: "Зареждане",
  "Load prepaid cards": "Зареждане на предплатени карти",
  "There is no company selected.": "Няма избрана компания.",
  "There is no currency selected.": "Няма избрана валута.",
  "Not all users have a corresponding amount or there are no users selected.":
    "Не всички потребители имат въведена сума или няма въведени потребители.",
  "The selected company is not supporting payments for currency":
    "Избраната компания не поддържа плащания чрез валута",
  "One or more E-mail addresses was not found":
    "Един или повече Имейл адреси не бяха намерени",
  Order: "Поръчка",
  "Is entity funded?": "Захранено ли е устройството?",
  Invoices: "Фактури",
  invoices: "Фактури",
  Requests: "Заявки",
  requests: "Заявки",
  Issued: "Издадени",
  Waiting: "Чакащи",
  Incoming: "Входящи",
  Outgoing: "Изходящи",
  "Order Id": "Поръчка №",
  "Order id": "Поръчка №",
  "Incoming invoices": "Входящи фактури",
  "Outgoing invoices": "Изходящи фактури",
  "Invoice date": "Дата на фактурата",
  "Invoice Date": "Дата на фактурата",
  "Invoice Number": "Фактура номер",
  "Filter by payment type:": "Филтриране по начин на плащане",
  "Issuing company": "Компания издател",
  "Total amount": "Обща стойност",
  "VAT %": "ДДС %",
  "Invoice status": "Статус на фактурата",
  "Cancellation reason": "Причина за сторниране",
  "Not requested": "Не е заявена",
  Cancelled: "Сторнирана",
  cancelled: "сторнирана",
  "Download as PDF": "Изтегли PDF",
  "Invoice Id": "Фактура №",
  "Receiving company": "Компания получател",
  "This invoice request is still pending.":
    "Тази заявка за фактура още не е обработена.",
  "Pending invoice": "Обработващи се фактури",
  Issue: "Издаване",
  "You are about to decline this invoice.":
    "Предстои да отхвърлите тази фактура.",
  Reason: "Причина",
  "Order date": "Дата на поръчката",
  "Invoice number": "Номер на фактура",
  "You are about to reverse this invoice.":
    "Предстои да сторнирате тази фактура.",
  issued: "издадени",
  "Invoice successfully reversed.": "Фактурата е сторнирана успешно.",
  requested: "заявена",
  "Invoice company": "Фактурирана компания",
  Requested: "Заявени",
  "The invoice was issued successfully.": "Фактурата е издадена успешно.",
  "The invoice was declined successfully.": "Фактурата е отхвърлена успешно.",
  "Reason to decline invoice": "Причина за отказ от фактуриране",
  "Reason to decline": "Причина за отказ",
  "Is invoice cancelled?": "Сторнирана ли е фактурата?",
  "Invoice for company": "Фактура за фирма",
  "Reason field cannot be empty.": "Полето за причина не може да е празно.",
  "The invoices were issued successfully.": "Фактурите са издадени успешно.",
  "The invoices were declined successfully.":
    "Заявките за фактури бяха отхвърлени успешно.",
  "Issue invoices": "Издаване на фактури",
  "Decline invoices": "Откажи на фактури",
  "What state is your company located in?":
    "В коя област се намира вашата компания?",
  "An error has occured while issuing the invoices.":
    "Възникна грешка при издаването на фактурите",
  Approved: "Одобрени",
  Declined: "Отхвърлени",
  "Company receiver": "Компания-получател",
  "Company issuer": "Компания-издател",
  "Total price": "Обща сума",
  "%VAT": "%ДДС",
  "Invoiced company": "Фактурирана компания",
  Invoice: "Фактура",
  "This QR code is not ready to accept payments.":
    "Плащания към този QR код все още не се приемат.",
  "Show wallet payments": "Показване на плащания към портфейли",
  "Payment point type:": "Tочка за плащане",
  "Filter by payment point": "Филтрирайте по точка за плащане",
  machine: "машина",
  qr: "QR код",
  vpos: "Виртуални POS",
  "QR Code": "QR код",
  VPOS: "Виртуални POS",
  "Display name": "Име за показване",
  Send: "Изпращане",
  "ra.navigation.page_rows_per_page": "Редове на страница:",
  "ra.notification.created": "Елементът бе създаден успешно.",
  "ra.message.invalid_form": "Формата е невалидна.",
  "No data to load was found.": "Не бяха намерени данни за зареждане.",
  "Filter by issuing company": "Филтриране по компания-издател",
  "Filter by receiving company": "Филтриране по компания-получател",
  Pending: "Текущи заявки",
  pending: "Текущи заявки",
  Resolved: "Обработени заявки",
  "This change will affect the numbering of all subsequent invoices":
    "Тази промяна ще се отрази на номерацията на всички следващи фактури",
  "Don’t have an account?": "Нямате профил?",
  "Sign up": "Регистрирайте се",
  Login: "Вход",
  Password: "Парола",
  Register: "Регистрирайте се",
  "Remember me": "Запомни ме",
  "You have an account?": "Имате профил?",
  "Log out": "Изход",
  "Signed as": "Влезли сте като",
  "": "",
  AVAX: "AVAX",
  "VPOS State": "Състояние на POS",
  "POS Name": "Име на POS",
  "POS Serial": "Сериен номер на POS",
  null: "null",
  "Last Seen": "Последно видян",
  "Download now": "Изтеглете",
  "Upload File": "Прикачете файл",
  "Remove File": "Премахнете файла",
  "Close sidebar": "Затворете страничната лента",
  "Open sidebar": "Отверете страничната лента",
  "Change language": "Сменете езика",
  User_settings: "Настройки на потребителя",
  "Table filters": "Филтри за таблица",
  "Hide Map": "Скрий картата",
  "Show Map": "Покажи картата",
  "Minimal sum for purchase": "Минимална сума за покупка",
  "Minimal transaction sum": "Минимална сума за транзакция",
  "New User": "Нов Потребител",
  deleted: "изтрити",
  "Orders Invoice": "Фактура за поръчки",
  Add: "добавяне",
  "Serial number": "Сериен номер",
  end_user: "Краен потребител",
  "Edit profile": "Редактиране на профил",
  "Check locations": "Проверете локациите",
  "Export as PDF": "Изтеглете като PDF",
  "Export as CSV": "Изтеглете като CSV",
  Refresh: "Презареждане",
  "Unable to decline invoice request.":
    "Молбата за издаване на фактура не може да бъде отхвърлена.",
  "Invoice request declined successfully.":
    "Молбата за издаване на фактура е отхвърлена.",
  "Invoice issued successfully.": "Фактурата е издадена успешно.",
  "Invoice issuing failed.": "Възникна грешка при издаването на фактура.",
  "Why would you like to decline this request?":
    "Защо бихте искали да отхвърлите тази молба?",
  "Invoice cancelled successfully.": "Фактурата е успешно сторнирана.",
  "Invoice cancellation failed.": "Сторнирането на фактурата е неуспешно.",
  "User settings": "Потребителски настройки",
  "Unable to set company status.":
    "Неуспешна промяна на статуса на компанията.",
  "Edit location": "Редактиране на локация",
  "Edit machine": "Редактиране на машина",
  "Edit pos": "Редактиране на POS",
  "Edit qr": "Редактиране на QR код",
  "Edit user": "Редактиране на потребител",
  location: "локация",
  "Filter by:": "Филтрирайте по:",
  "Detach module from machine": "Разкачете модула от машината",
  "Payment Point type:": "Тип на точката за плащане",
  "Payment Point": "Точка за плащане",
  Period: "Период",
  "Transaction ID": "Уникален номер на транзакция",
  Logs: "Хронология",
  "Issuing Company": "Компания издател",
  "Receiving Company": "Kомпания получател",
  User: "Потребител",
  user: "Потребител",
  "This company is waiting to be approved": "Тази фирма изчаква одобрение",
  Reject: "Откажи",
  company: "Компания",
  "Edit company": "Редактиране на компания",
  "Avalanche Wallet Address (X-Chain)": "Avalanche адрес на портфейл (X-Chain)",
  "Currency name": "Име на валутата",
  "Default (0x10)": "По подразбиране (0х10)",
  "Default (0x60)": "По подразбиране (0х60)",
  "Default company": "Компания по подразбиране",
  "There aren't any available API tokens for the current company":
    "Няма налични токени за текущата компания",
  promotion: "промоция",
  "Edit promotion": "редактиране на промоция",
  "Period From": "Период от",
  "Period To": "Период до",
  "404: Page not found": "404: Страницата не е намерена",
  "Go back to dashboard": "Върнете се обратно на Началната страница",
  "Required fields are missing": "Липсват задължителни полета.",
  "Must be a valid email": "Имейла трябвад да е валиден.",
  "Click here to edit": "Кликнете тук за редакция",
  "Click here to delete": "Кликнете тук за изтриване",
  "Order Invoice": "ФАКТУРА ЗА ПОРЪЧКА",
  // "Cancel Invoice": "Прекратяване на фактура",
  "The value of this field is not valid":
    "Стойността на това поле не е валидна",
  "Are you sure you want to delete this resource?":
    "Сигурни ли сте, че искате да изтриете този ресурс?",
  "Resource created successfully": "Ресурса е създаден успешно.",
  "Resource edited successfully": "Ресурса е променен успешно.",
  "Resource deleted successfully": "Ресурса е изтрит успешно.",
  "User email was not found": "Потребител с въведения имейл не беше открит",
  "Duplicated user email": "Повтарящ се имейл на потребител",
  "New Ingredient": "Нова съставка",
  "Add ingredient": "Добави съставка",
  "There aren't any products": "Няма налични продукти",
  "There aren't any ingredients": "Няма налични съставки",
  Measurement: "Измерване",
  "Measurement unit": "Мерна единица",
  gram: "грама",
  millilitre: "милилитра",
  count: "броя",
  Counts: "броя",
  "Unit name": "Мерна единица",
  Allergens: "Алергени",
  allergen: "алерген",
  "Add allergen": "Добавяне на алерген",
  Ingredient: "Съставка",
  ingredient: "съставка",
  Ingredients: "Съставки",
  Products: "Продукти",
  product: "продукт",
  "Product type:": "Тип на продуктите",
  packaged: "пакетирани",
  recipe: "рецепти",
  "Add packaged product": "Добавяне на пакетиран продукт",
  "Add product with recipe": "Добавяне на продукт с рецепта",
  "Product name": "Име на продукт",
  Price: "Цена",
  "Current price": "Текуща цена",
  "Attach products": "Свържи продукт",
  Detach: "Откачане",
  "Ingredient capacities": "Вместимост на съставки",
  "Add product capacity": "Добави вместимост за продукт",
  "Specify product capacity": "Попълване на вместимост на продукт",
  "Edit product capacity": "Редакция на вместимост на продукт",
  "Add ingredient capacity": "Добави вместимост за съставка",
  "Specify ingredient capacity": "Попълване на вместимост на съставката",
  "Edit ingredient capacity": "Редакция на вместимост на съставката",
  Vending: "Вендинг",
  Blueprint: "Чертеж",
  blueprint: "чертеж",
  Blueprints: "Чертежи",
  "The maximum value for this field is 20.":
    "Максималното количество на това поле е 20.",
  "The maximum value for this field is 30.":
    "Максималното количество на това поле е 30.",
  "Number of rows": "Брой редове",
  "Number of columns": "Брой колони",
  Scheme: "Схема",
  "Create scheme": "Създай схема",
  "Map products to buttons": "Свържи продукти към бутони",
  Mapping: "Връзка",
  "Machine blueprint signal mapping can't be blank":
    "Не са зададени сигналите за схемата на машината.",
  "Existing mappings might get deleted on field change":
    "Съществуващи връзки могат да бъдат изтрити при промяна на полето.",
  "Button signal": "Сигнал на бутон",
  "Copy from machine": "Копирай от машина",
  "Resend Email": "Препрати имейл",
  "An email has been sent to the email. Please follow the instructions in the email to change your password. If you don't see the email in your inbox within the next few minutes, please check your spam or junk folder. If you need further assistance, please don't hesitate to contact our support team at support@averato.com. Your security is our priority, and we're here to help you regain access to your account.":
    "Изпратен е имейл на посочения имейл адрес. Моля, следвайте инструкциите в имейла, за да промените паролата си. Ако не видите имейла в пощенската си кутия през следващите няколко минути, моля, проверете папката за спам или нежелана поща. Ако имате нужда от допълнителна помощ, моля, не се колебайте да се свържете с нашия екип за поддръжка на support@averato.com. Вашата сигурност е наш приоритет и ние сме тук, за да ви помогнем да възстановите достъпа до профила си.",
  period: "Период",
  "Module Flow": "Режим на работа",
  Flow: "Режим на работа",
  standard: "стандартен",
  carwash: "автомивка",
  "Upload logo": "Качи лого",
  "Upload image": "Качи снимка",
  Logo: "Лого",
  Image: "Снимка",
  "No image selected": "Не е избрана снимка",
  "File cannot exceed 5MB": "Файлът не може да бъде повече от 5МБ.",
  "Allowed file formats can only be png, jpeg or webp":
    "Позволените формати са png, jpeg, webp.",
  "Gift cards": "Карти подарък",
  "gift-cards": "Карти за подарък",
  Prize: "Награда",
  Public: "Публичнa",
  price: "цена",
  Value: "Парична стойност", // TODO: Find fix for english translations file not being detected.
  discount: "отстъпка",
  "gift-card": "Карта за подарък",
  "Valid for": "Валидна за",
  Discount: "Отстъпка",
  "Discount value": "Ценова разлика",
  conventional: "конвенционална",
  promotional: "промоционална",
  refund: "възстановяване на пари",
  day: "дни",
  week: "седмици",
  type: "Тип",
  "Add gift card": "Създаване на карта за подарък",
  "Gift Cards": "Карти подарък",
  month: "месеци",
  year: "години",
  "module flow version": "Версия на режима на работа",
  "Machine Type": "Тип на машината",
  vending: "вендинг",
  "Validity Period": "Период на валидност",
  "Purchase price": "Цена за покупка",
  "Total gift cards amount": "Общ брой закупени карти",
  "Purchased Amount": "Закупена стойност",
  "Purchased gift cards": "Закупени карти подарък",
  Rows: "Брой редове",
  row: "ред",
  "Open machine screen": "Отвори екрана на машината",
  Columns: "Брой колони",
  "Detach from company": "Откачи от компания",
  token: "токен",
  deactivated: "Деактивирани",
  PAID_AND_COMPLETED: "платена и приключена",
  ADD: "Добавяне",
  Token: "Токен",
  Active: "Активна",
  ProductType: "Тип на продуктите",
  name: "Име",
  quantity: "Наличност",
  measurement: "Мярка",
  allergens: "Алергени",
  action: "Действия",
  "Entity Categories": "Категории",
  "entity-categories": "Категория",
  "Add Entity Category": "Добави категория",
  "Entity Category": "Категория",
  successfully_generate_code: "Успешно създаден на код",
  "must be at least 4 characters long code":
    "Кодът трябва да бъде поне 4 символа дълъг",
  code: "Код",
  valid: "Валиден",
  generate_access_code: "Генериране на код за достъп",
  access_code: "Код за достъп",
  generate_code_for: "Генериране на код за",
  enabled: "Активен",
  "Select a product": "Изберете продукт",
  "No product": "Няма продукт",
  "Cannot delete a signal while it is connected to a button with a product":
    "Не може да изтриете сигнал, докато е свързан с бутон с продукт",
  "Something went wrong": "Нещо се обърка",
  alert_types: "Типове алерти",
  coin_acceptor_state_change: "Промяна на състоянието на монетоприемника",
  mdb_state_change: "Промяна на MDB състоянието",
  module_down: "Модулът е изключен",
  module_up: "Модулът е включен",
  "has already been taken code": "Кодът вече е използван.",
  "Access code must contain only numbers": "Кодът трябва да съдържа само цифри",
  "Maximum number of columns reached": "Достигнат е максималния брой колони",
  mdb_state_inactive: "Състоянието на MDB е неактивно",
  mdb_state_disabled: "Състоянието на MDB е деактивирано",
  mdb_state_enabled: "Състоянието на MDB е активирано",
  mdb_state_session_idle: "Сесията на MDB е неактивна",
  mdb_state_vend: "Състоянието на MDB е продажба",
  "Interface Mode": "Режим на интерфейса",
  "The new interface will be active after restarting the machine":
    "Новият интерфейс ще бъде активен след рестартиране на машината",
  list: "Списък",
  keyboard: "Клавиатура",
  Keyboard: "Клавиатура",
  button: "Бутон",
  "No button attached": "Няма свързан бутон",
  "Button signal is already used in this machine blueprint.":
    "Сигналът на бутона вече е използван в схемата на машината.",
};
