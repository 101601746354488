import { useTranslation } from "react-i18next";

import { DatePickerTypes } from "components/DatePicker/DatePicker";
import { emptyOptions } from "components/Forms/formUtils";
import { FieldsInfo } from "models/fields.model";
import { GiftCardType, GiftCardTypes } from "models/resources/gitfCard.model";
import { EmailRegexPattern, PhoneNumberRegexPattern } from "utils/constants";

export const fields = (mode: string) => {
  const { t } = useTranslation();

  return [
    {
      resource: "price adjustment",
      fields: [
        {
          id: "active",
          label: "Active",
          type: "Switch",
          defaultValue: true,
          required: true,
        },
        {
          id: "name",
          label: "Name",
          type: "TextField",
          required: true,
        },
        {
          id: "display_name",
          label: "Display name",
          type: "TextField",
          required: true,
        },
        {
          id: "description",
          label: "Description",
          type: "TextField",
          required: false,
        },
        {
          id: "company_id",
          renderedBy: "display_name",
          label: "Company",
          type: "Autocomplete",
          required: true,
          useIdInPayload: true,
          isFieldValueConditional: true,
          autoCompleteRequestType: "companies",
          autoCompleteResponseType: "companies",
        },
        {
          id: "range",
          type: "DatePicker",
          label: "Promotion period",
          inlineStyle: { marginBottom: "30px" },
          required: true,
          range: [
            new Date(new Date().setDate(new Date().getDate())),
            new Date(new Date().setDate(new Date().getDate() + 6)),
          ],

          onEditArgs: () => ({
            fixedStart: true,
            futureDatesFromStart: true,
          }),
          onCreateArgs: () => ({
            futureDatesOnly: true,
          }),
          datePickerType: DatePickerTypes.DATE_RANGED,
        },
        {
          id: "adjusted_sign",
          required: true,
          label: "Price change",
          type: "Select",
          useHardcodedOptions: false,
          hardcodedOptions: ["price increase", "price decrease"],
        },
        {
          id: "type_adjustment",
          required: true,
          label: "Adjustment type",
          type: "Select",
          hardcodedOptions: ["percentage", "fixed amount"],
          disabled: (mode: any) => mode === "edit",
        },
        {
          id: "price_adjustment",
          required: true,
          label: "Price adjustment",
          resetValue: (value: number, getField: Function) => {
            if (
              getField("adjusted_sign") === "price decrease" &&
              getField("type_adjustment") === "percentage"
            )
              return Math.min(Number(value), 100).toString();
            return value;
          },
          type: "TextField",
          textFieldType: "number",
          isPrice: true,
          maxValue: "123",
          dependantOn: ["adjusted_sign", "type_adjustment"],
        },
        {
          id: "from",
          type: "DatePicker",
          label: "Valid from",
          required: true,
          inlineStyle: { marginBottom: "30px" },
          range: new Date(new Date().setHours(0, 0, 0, 0)),

          onEditArgs: () => ({
            fixedStart: true,
            futureDatesFromStart: true,
          }),
          onCreateArgs: () => ({
            futureDatesOnly: true,
          }),
          datePickerType: DatePickerTypes.TIME_RANGED,
        },
        {
          id: "to",
          type: "DatePicker",
          label: "Valid to",
          required: true,
          inlineStyle: { marginBottom: "30px" },
          range: new Date(new Date().setHours(23, 59, 59, 59)),
          onEditArgs: () => ({
            fixedStart: true,
            futureDatesFromStart: true,
          }),
          onCreateArgs: () => ({
            futureDatesOnly: true,
          }),
          datePickerType: DatePickerTypes.TIME_RANGED,
        },
        {
          id: "product_ids",
          label: "Products",
          type: "MultiAutocomplete",
          sendParamAsFilter: true,
          useIdInPayload: true,
          isFieldParamVariableArray: true,
          fieldAwaitsQueryParam: true,
          filterParamVariable: "companyIds",
          autoCompleteRequestType: "products",
          dependantOn: ["company_id"],
        },
      ],
    },
    {
      resource: "machine",
      dependencies: ["company_id"],
      fields: [
        {
          id: "name",
          label: "Machine name",
          type: "TextField",
          required: true,
        },
        {
          id: "currency",
          label: "Currency",
          type: "Select",
          requiredValues: ["company_id"],
          messages: ["You must select a company first"],
          emptyOptions: emptyOptions,
          required: true,
          useIdInPayload: false,
          fieldAwaitsQueryParam: true,
          autoCompleteRequestType: "companies",
          autoCompleteRequestEndpoint: "payment-credentials",
          autoCompleteResponseType: "credentials",
          dependantOn: ["company_id"],
          hardcodedOptions: ["BGN"],
        },
        {
          id: "minimum_payment_amount",
          label: "Minimal sum for purchase",
          type: "TextField",
          textFieldType: "number",
          isPrice: true,
          onlyPositiveIntegers: true,
        },
        {
          id: "company_id",
          renderedBy: "display_name",
          label: "Company",
          type: "Autocomplete",
          required: true,
          useIdInPayload: true,
          isFieldValueConditional: true,
          autoCompleteRequestType: "companies",
          autoCompleteResponseType: "companies",
        },
        {
          id: "addCompanyButton",
          dialogResource: "company",
          label: "Add company",
          type: "AddResourceDialog",
          autoCompleteRequestType: "companies",
        },
        {
          id: "location_id",
          renderedBy: "address",
          label: "Location",
          type: "Autocomplete",
          requiredValues: ["company_id"],
          useIdInPayload: true,
          required: true,
          fieldAwaitsQueryParam: true,
          sendParamAsFilter: true,
          filterParamVariable: "companyIds",
          isFieldParamVariableArray: true,
          autoCompleteRequestType: "locations",
          autoCompleteResponseType: "locations",
          dependantOn: ["company_id"],
        },
        {
          id: "addLocationButton",
          label: "Add location",
          dialogResource: "location",
          autoCompleteRequestType: "locations",
          type: "AddResourceDialog",
          dependantOn: ["company_id"],
        },
        {
          id: "module_id",
          renderedBy: "machine",
          label: "Module",
          type: "Select",
          requiredValues: ["company_id", "options.0.module_id"],
          emptyOptions: (
            values: Array<any>,
            messages = [
              "You must select a company first",
              "There are no modules available in the specified company",
            ],
          ) => {
            const [company, modules] = values;
            if (company == undefined) return messages[0];
            if (!modules || modules.length == 0) return messages[1];

            return "";
          },
          useIdInPayload: true,
          fieldAwaitsQueryParam: true,
          sendParamAsFilter: true,
          filterParamVariable: "companyIds",
          isFieldParamVariableArray: true,
          autoCompleteRequestType: "modules",
          autoCompleteResponseType: "modules",
          dependantOn: ["company_id"],
        },
        {
          id: "machine_blueprint_id",
          renderedBy: "name",
          label: "Blueprint",
          type: "Autocomplete",
          requiredValues: ["company_id"],
          useIdInPayload: true,
          required: true,
          fieldAwaitsQueryParam: true,
          sendParamAsFilter: true,
          filterParamVariable: "companyIds",
          isFieldParamVariableArray: true,
          autoCompleteRequestType: "machine-blueprints",
          autoCompleteResponseType: "blueprints",
          dependantOn: ["company_id"],
        },
        {
          id: "entity_category_id",
          renderedBy: "name",
          label: "Entity Category",
          type: "Autocomplete",
          useIdInPayload: true,
          required: true,
          autoCompleteRequestType: "entity-categories",
          autoCompleteResponseType: "entities",
        },
        {
          id: "product_type",
          label: "Product type",
          type: "Select",
          useHardcodedOptions: true,
          required: false,
        },
        {
          id: "interface_mode",
          label: "Interface Mode",
          type: "Select",
          useHardcodedOptions: true,
          required: true,
          helperText:
            "The new interface will be active after restarting the machine",
        },
        {
          id: "detachModuleButton",
          label: "Detach module from machine",
          type: "Button",
          editOnly: true,
          onClick: ({ setter }) => {
            setter("module_id", null);
          },
        },
        {
          id: "comments",
          label: "Optional comments",
          type: "TextField",
        },
        {
          id: "Image",
          type: "Image",
          url: `${process.env.REACT_APP_BASE_URL}/api/machines/{id}/screen-saver`,
          isCropSquared: true,
        },
      ],
    },
    {
      resource: "qr",
      fields: [
        {
          id: "name",
          label: "QR code name",
          type: "TextField",
          required: true,
        },
        {
          id: "serial",
          label: "Unique QR serial",
          type: "TextField",
          required: true,
        },
        {
          id: "generateSerial",
          label: "Auto generate new serial number",
          type: "Button",
          onClick: ({ setter }) => {
            // algorithm from old project
            let val = Math.floor(Math.random() * 990);
            val += Math.floor((val + 110) / 110);
            let randonNumber = ("000" + val).substr(-3);
            let randomSerial = "Q" + randonNumber + Date.now();

            // form value setter
            setter("serial", randomSerial);
          },
        },
        {
          id: "company_id",
          renderedBy: "display_name",
          label: "Company",
          type: "Autocomplete",
          required: true,
          useIdInPayload: true,
          isFieldValueConditional: true,
          autoCompleteRequestType: "companies",
          autoCompleteResponseType: "companies",
        },
        {
          id: "addCompanyButton",
          label: "Add company",
          dialogResource: "company",
          type: "AddResourceDialog",
          autoCompleteRequestType: "companies",
        },
        {
          id: "currency",
          label: "Currency",
          type: "Select",
          required: true,
          useIdInPayload: false,
          fieldAwaitsQueryParam: true,
          autoCompleteRequestType: "companies",
          autoCompleteRequestEndpoint: "payment-credentials",
          autoCompleteResponseType: "credentials",
          dependantOn: ["company_id"],
        },
        {
          id: "location_id",
          renderedBy: "address",
          label: "Location",
          type: "Autocomplete",
          useIdInPayload: true,
          fieldAwaitsQueryParam: true,
          sendParamAsFilter: true,
          filterParamVariable: "companyIds",
          isFieldParamVariableArray: true,
          autoCompleteRequestType: "locations",
          autoCompleteResponseType: "locations",
          dependantOn: ["company_id"],
        },
        {
          id: "addLocationButton",
          label: "Add location",
          dialogResource: "location",
          type: "AddResourceDialog",
          autoCompleteRequestType: "locations",
          dependantOn: ["company_id"],
        },
        {
          id: "entity_category_id",
          renderedBy: "name",
          label: "Entity Category",
          type: "Autocomplete",
          useIdInPayload: true,
          required: true,
          autoCompleteRequestType: "entity-categories",
          autoCompleteResponseType: "entities",
        },
        {
          id: "comments",
          label: "Optional comments",
          type: "TextField",
        },
      ],
    },
    {
      resource: "pos",
      fields: [
        {
          id: "name",
          label: "POS name",
          type: "TextField",
          required: true,
        },
        {
          id: "company_id",
          renderedBy: "display_name",
          label: "Company",
          type: "Autocomplete",
          required: true,
          useIdInPayload: true,
          isFieldValueConditional: true,
          autoCompleteRequestType: "companies",
          autoCompleteResponseType: "companies",
        },
        {
          id: "addCompanyButton",
          label: "Add company",
          dialogResource: "company",
          type: "AddResourceDialog",
          autoCompleteRequestType: "companies",
        },
        {
          id: "currency",
          label: "Currency",
          type: "Select",
          required: true,
          useIdInPayload: false,
          fieldAwaitsQueryParam: true,
          autoCompleteRequestType: "companies",
          autoCompleteRequestEndpoint: "payment-credentials",
          autoCompleteResponseType: "credentials",
          dependantOn: ["company_id"],
        },
        {
          id: "location_id",
          renderedBy: "address",
          label: "Location",
          type: "Autocomplete",
          useIdInPayload: true,
          fieldAwaitsQueryParam: true,
          sendParamAsFilter: true,
          filterParamVariable: "companyIds",
          isFieldParamVariableArray: true,
          autoCompleteRequestType: "locations",
          autoCompleteResponseType: "locations",
          dependantOn: ["company_id"],
        },
        {
          id: "addLocationButton",
          label: "Add location",
          dialogResource: "location",
          type: "AddResourceDialog",
          autoCompleteRequestType: "locations",
          dependantOn: ["company_id"],
        },
        {
          id: "user_ids",
          label: "Associated users",
          type: "MultiAutocomplete",
          sendParamAsFilter: true,
          useIdInPayload: true,
          fieldAwaitsQueryParam: true,
          filterParamVariable: "companyIds",
          isFieldParamVariableArray: true,
          autoCompleteRequestType: "users",
          autoCompleteResponseType: "users",
          dependantOn: ["company_id"],
        },
        {
          id: "addUserButton",
          label: "Add associated user",
          dialogResource: "user",
          type: "AddResourceDialog",
          autoCompleteRequestType: "users",
          dependantOn: ["company_id"],
        },
        {
          id: "entity_category_id",
          renderedBy: "name",
          label: "Entity Category",
          type: "Autocomplete",
          useIdInPayload: true,
          required: true,
          autoCompleteRequestType: "entity-categories",
          autoCompleteResponseType: "entities",
        },
        {
          id: "comments",
          label: "Comments",
          type: "TextField",
        },
      ],
    },
    {
      resource: "company",
      fields: [
        {
          id: "name",
          label: "Company name",
          type: "TextField",
          required: true,
        },
        {
          id: "display_name",
          label: "Display name",
          type: "TextField",
          required: true,
        },
        {
          id: "email",
          label: "Email",
          type: "TextField",
          required: true,
          pattern: EmailRegexPattern,
        },
        {
          id: "phone",
          label: "Phone number",
          type: "PhoneInput",
          required: true,
          pattern: PhoneNumberRegexPattern,
        },
        {
          id: "receive_sms",
          label: "Do you wish to receive SMS?",
          type: "RadioButton",
          editOnly: true,
          radioOptions: [
            { label: "Yes", value: true },
            { label: "No", value: false },
          ],
        },
        {
          id: "info",
          label: "Company info",
          type: "TextField",
        },
        {
          id: "country",
          label: "Country",
          type: "Autocomplete",
          isFieldValueConditional: true,
          useHardcodedOptions: true,
          hardcodedOptionsType: "countries",
          required: true,
        },
        {
          id: "state",
          label: "Country State",
          requiredValues: ["options.0.state"],
          messages: ["There are no states for the correspoding country"],
          emptyOptions: emptyOptions,
          type: "Autocomplete",
          useHardcodedOptions: true,
          fieldAwaitsQueryParam: true,
          hardcodedOptionsType: "states",
          required: true,
          dependantOn: ["country"],
        },
        {
          id: "city",
          label: "City",
          requiredValues: ["options.0.city"],
          messages: ["There are no cities for the correspoding state"],
          emptyOptions: emptyOptions,
          type: "Autocomplete",
          useHardcodedOptions: true,
          hardcodedOptionsType: "cities",
          fieldAwaitsQueryParam: true,
          required: true,
          dependantOn: ["country", "state", "options.0.state"],
        },
        {
          id: "address",
          label: "Address",
          editOnly: true,
          type: "TextField",
        },
        {
          id: "tax_number",
          label: "UIC",
          type: "TextField",
        },
        {
          id: "next_invoice_number",
          label: "Next invoice number",
          type: "TextField",
          required: true,
          textFieldType: "number",
        },
        {
          id: "accountable_person",
          label: "Accountable person",
          type: "TextField",
        },
        {
          id: "vat_load_gift_cards",
          label: "VAT% (load prepaid cards)",
          type: "TextField",
          editOnly: true,
          textFieldType: "number",
        },
      ],
    },
    {
      resource: "promotion",
      fields: [
        {
          id: "active",
          label: "Public",
          type: "Switch",
          defaultValue: true,
          required: true,
        },
        {
          id: "name",
          label: "Name",
          type: "TextField",
          required: true,
        },
        {
          id: "display_name",
          label: "Display name",
          type: "TextField",
          required: true,
        },
        {
          id: "description",
          label: "Description",
          type: "TextField",
          required: false,
        },
        {
          id: "company_id",
          renderedBy: "display_name",
          label: "Company",
          type: "Autocomplete",
          required: true,
          useIdInPayload: true,
          isFieldValueConditional: true,
          autoCompleteRequestType: "companies",
          autoCompleteResponseType: "companies",
        },
        {
          id: "range",
          type: "DatePicker",
          label: "Promotion period",
          inlineStyle: { marginBottom: "30px" },
          required: true,
          onEditArgs: () => ({
            fixedStart: true,
            futureDatesFromStart: true,
          }),
          onCreateArgs: () => ({
            futureDatesOnly: true,
          }),
          range: [
            new Date(new Date().setDate(new Date().getDate())),
            new Date(new Date().setDate(new Date().getDate() + 6)),
          ],
          datePickerType: DatePickerTypes.DATE_RANGED,
        },
        {
          id: "gift_card_id",
          renderedBy: "display_name",
          label: "Prize",
          type: "Autocomplete",
          required: true,
          sendParamAsFilter: true,
          fieldAwaitsQueryParam: true,
          useIdInPayload: true,
          isFieldParamVariableArray: true,
          filterParamVariable: "companyIds",
          autoCompleteRequestType: "gift-cards",
          autoCompleteResponseType: "gift-cards",
        },
        {
          id: "seperator",
          type: "Seperator",
          inlineStyle: { marginBottom: "0" },
          hideOnEdit: true,
        },
        {
          id: "typography",
          type: "Typography",
          label: "Win conditions",
          inlineStyle: { fontSize: "25px" },
          fullWidth: true,
          centered: true,
          hideOnEdit: true,
        },
        {
          id: "required_sum",
          label: "Required sum",
          type: "TextField",
          required: true,
          textFieldType: "number",
          isPrice: true,
          hideOnEdit: true,
        },
        {
          id: "total_rewards",
          label: "Total rewards",
          type: "TextField",
          hideOnEdit: true,
        },
        {
          id: "rewards_per_user",
          label: "Rewards per user",
          type: "TextField",
          hideOnEdit: true,
        },
        {
          id: "rewards_per_payment",
          label: "Rewards per payment",
          type: "TextField",
          hideOnEdit: true,
        },
      ],
    },
    {
      resource: "user",
      fields: [
        {
          id: "first_name",
          label: "First name",
          type: "TextField",
          required: true,
        },
        {
          id: "last_name",
          label: "Last name",
          type: "TextField",
          required: true,
        },
        {
          id: "email",
          label: "Email",
          type: "TextField",
          required: true,
          pattern: EmailRegexPattern,
          hide: mode === "edit",
        },
        {
          id: "phone",
          label: "Phone number",
          type: "PhoneInput",
          pattern: PhoneNumberRegexPattern,
        },
        {
          id: "receive_sms",
          label: "Do you wish to receive SMS?",
          type: "RadioButton",
          editOnly: true,
          radioOptions: [
            { label: "Yes", value: true, checked: false },
            { label: "No", value: false, checked: false },
          ],
        },
        {
          id: "role",
          label: "Role",
          type: "Select",
          required: true,
          useHardcodedOptions: true,
          hardcodedOptionsType: "role",
        },
        {
          id: "company_ids",
          renderedBy: "display_name",
          label: "Companies",
          type: "MultiAutocomplete",
          required: true,
          useIdInPayload: true,
          filterParamVariable: "companyIds",
          isFieldParamVariableArray: true,
          isFieldValueConditional: true,
          autoCompleteRequestType: "companies",
          autoCompleteResponseType: "companies",
        },
        {
          id: "alert_types",
          renderedBy: "name",
          label: "alert_types",
          type: "MultiAutocomplete",
          autoCompleteRequestType: "constants/alert-types",
          autoCompleteResponseType: "entities",
        },
      ],
    },
    {
      resource: "location",
      fields: [
        {
          id: "name",
          label: "Name",
          type: "TextField",
          required: true,
        },
        {
          id: "address",
          label: "Address",
          type: "TextField",
          required: true,
        },
        {
          id: "company_id",
          renderedBy: "display_name",
          label: "Companies",
          type: "Autocomplete",
          required: true,
          useIdInPayload: true,
          autoCompleteRequestType: "companies",
          autoCompleteResponseType: "companies",
        },
        {
          id: "addCompanyButton",
          dialogResource: "company",
          label: "Add company",
          type: "AddResourceDialog",
          autoCompleteRequestType: "companies",
        },
        {
          id: "map",
          type: "Map",
        },
      ],
    },
    {
      resource: "PrepaidCardChildForm",
      fields: [
        {
          id: "custom",
          label: "Custom amount for every user",
          type: "Switch",
        },
      ],
    },
    {
      resource: "module",
      fields: [
        {
          id: "serial",
          label: "Serial",
          type: "TextField",
          disabled: true,
        },
        {
          id: "company_id",
          renderedBy: "display_name",
          label: "Company",
          type: "Autocomplete",
          isFieldValueConditional: true,
          useIdInPayload: true,
          autoCompleteRequestType: "companies",
          autoCompleteResponseType: "companies",
        },
        {
          id: "machine_id",
          renderedBy: "name",
          label: "Machine",
          type: "Autocomplete",
          useIdInPayload: true,
          fieldAwaitsQueryParam: true,
          sendParamAsFilter: true,
          filterParamVariable: "companyIds",
          isFieldParamVariableArray: true,
          autoCompleteRequestType: "machines",
          autoCompleteResponseType: "machines",
          dependantOn: ["company_id"],
        },
        {
          id: "comments",
          label: "Optional comments",
          type: "TextField",
        },
        {
          id: "mdbSettings",
          dialogResource: "mdb-settings",
          label: "MDB settings",
          type: "MdbSettingsForm",
          autoCompleteRequestType: "modules",
          autoCompleteRequestEndpoint: "mdb",
        },
      ],
    },
    {
      resource: "mdb-settings",
      fields: [
        {
          id: "mdb-notice",
          type: "Typography",
          label: t(
            "Important notice: MDB settings are low level technical ones. Please, only change values if you are 100% sure about what you are doing!",
          ),
        },
        {
          id: "dev_addr",
          label: "Peripheral address",
          type: "RadioButton",
          radioOptions: [
            { label: t("Default (0x10)"), value: 16 },
            { label: t("Default (0x60)"), value: 96 },
          ],
        },
        {
          id: "feature_level",
          label: "Feature level",
          type: "RadioButton",
          radioOptions: [
            { label: "1", value: 1 },
            { label: "2", value: 2 },
          ],
        },
        {
          id: "scale_factor",
          label: "Scale factor",
          type: "TextField",
          textFieldType: "number",
        },
        {
          id: "app_max_resp_time",
          label: "App max response time",
          type: "TextField",
          textFieldType: "number",
        },
        {
          id: "country_code",
          label: "Country / currency code",
          type: "TextField",
        },
        {
          id: "decimal_place",
          label: "Decimal places",
          type: "TextField",
          textFieldType: "number",
          isPrice: true,
        },
        {
          id: "max_price",
          label: "Max price",
          type: "TextField",
          textFieldType: "number",
          isPrice: true,
        },
        {
          id: "min_price",
          label: "Min price",
          type: "TextField",
          textFieldType: "number",
        },
        {
          id: "misc_opt",
          label: "Miscelaneous options",
          type: "TextField",
        },
      ],
    },
    {
      resource: "module-firmware",
      fields: [
        {
          id: "current",
          label: "Version",
          type: "Select",
          autoCompleteRequestType: "modules",
          fieldAwaitsQueryParam: true,
          // autoCompleteRequestEndpoint: "payment-credentials",
          autoCompleteResponseType: "modules",
        },
      ],
    },
    {
      resource: "module-flow",
      fields: [
        {
          id: "flow",
          label: "Module Flow Version",
          type: "Select",
          autoCompleteRequestType: "modules",
          fieldAwaitsQueryParam: true,
          useHardcodedOptions: true,
          autoCompleteResponseType: "modules",
        },
      ],
    },
    {
      resource: "change-password",
      fields: [
        {
          id: "old_password",
          label: "Old password",
          type: "TextField",
          textFieldType: "password",
        },
        {
          id: "new_password",
          label: "New password",
          type: "TextField",
          textFieldType: "password",
        },
      ],
    },
    {
      resource: "packet-product",
      fields: [
        {
          id: "name",
          label: "Name",
          type: "TextField",
          required: true,
        },
        {
          id: "display_name",
          label: "Product name",
          type: "TextField",
          required: true,
        },
        {
          id: "description",
          label: "Description",
          type: "TextField",
          required: false,
        },
        {
          id: "company_id",
          renderedBy: "display_name",
          label: "Company",
          type: "Autocomplete",
          required: true,
          useIdInPayload: true,
          isFieldValueConditional: true,
          autoCompleteRequestType: "companies",
          hideOnEdit: true,
          autoCompleteResponseType: "companies",
        },
        {
          id: "currency",
          label: "Currency",
          type: "Select",
          required: true,
          dependantOn: ["company_id"],
          useIdInPayload: false,
          fieldAwaitsQueryParam: true,
          autoCompleteRequestType: "companies",
          autoCompleteRequestEndpoint: "payment-credentials",
          autoCompleteResponseType: "credentials",
          hardcodedOptions: ["BGN"],
        },
        {
          id: "price",
          label: "Price",
          type: "TextField",
          textFieldType: "number",
          isPrice: true,
          required: true,
          useIdInPayload: true,
        },
        {
          id: "allergen_ids",
          label: "Allergens",
          type: "MultiAutocomplete",
          sendParamAsFilter: true,
          dependantOn: ["company_id"],
          useIdInPayload: true,
          isFieldParamVariableArray: true,
          fieldAwaitsQueryParam: true,
          filterParamVariable: "companyIds",
          autoCompleteRequestType: "allergens",
          autoCompleteResponseType: "allergens",
        },
        {
          id: "machine_ids",
          renderedBy: "name",
          label: "Machines",
          type: "MultiAutocomplete",
          dependantOn: ["company_id"],
          required: false,
          useIdInPayload: true,
          sendParamAsFilter: true,
          fieldAwaitsQueryParam: true,
          filterParamVariable: "companyIds",
          filterHardcodeKey: "productType",
          filterHardcodeValue: "packaged",
          isFieldParamVariableArray: true,
          autoCompleteRequestType: "machines",
          autoCompleteResponseType: "machines",
        },
        {
          id: "table",
          url: `${process.env.REACT_APP_BASE_URL}/api/products/{id}/image`,
          type: "Image",
        },
        {
          id: "promotion_ids",
          label: "Price adjustment",
          type: "MultiAutocomplete",
          sendParamAsFilter: true,
          useIdInPayload: true,
          isFieldParamVariableArray: true,
          fieldAwaitsQueryParam: true,
          filterParamVariable: "companyIds",
          autoCompleteRequestType: "promotions",
          filterHardcodeKey: "promotion_type",
          filterHardcodeValue: "price_adjustment",
        },
      ],
    },

    {
      resource: "recipe-product",
      fields: [
        {
          id: "name",
          label: "Name",
          type: "TextField",
          required: true,
        },
        {
          id: "display_name",
          label: "Product name",
          type: "TextField",
          required: true,
        },
        {
          id: "description",
          label: "Description",
          type: "TextField",
          required: false,
        },
        {
          id: "company_id",
          renderedBy: "display_name",
          label: "Company",
          type: "Autocomplete",
          required: true,
          useIdInPayload: true,
          isFieldValueConditional: true,
          hideOnEdit: true,
          autoCompleteRequestType: "companies",
          autoCompleteResponseType: "companies",
        },
        {
          id: "currency",
          label: "Currency",
          type: "Select",
          required: true,
          dependantOn: ["company_id"],
          useIdInPayload: false,
          fieldAwaitsQueryParam: true,
          autoCompleteRequestType: "companies",
          autoCompleteRequestEndpoint: "payment-credentials",
          autoCompleteResponseType: "credentials",
          hardcodedOptions: ["BGN"],
        },
        {
          id: "price",
          label: "Price",
          type: "TextField",
          textFieldType: "number",
          isPrice: true,

          required: true,
          useIdInPayload: true,
        },
        {
          id: "machine_ids",
          renderedBy: "name",
          label: "Machines",
          type: "MultiAutocomplete",
          required: false,
          dependantOn: ["company_id"],
          useIdInPayload: true,
          sendParamAsFilter: true,
          fieldAwaitsQueryParam: true,
          filterParamVariable: "companyIds",
          filterHardcodeKey: "productType",
          filterHardcodeValue: "recipe",
          isFieldParamVariableArray: true,
          autoCompleteRequestType: "machines",
          autoCompleteResponseType: "machines",
        },
        {
          id: "promotion_ids",
          label: "Price adjustment",
          type: "MultiAutocomplete",
          sendParamAsFilter: true,
          useIdInPayload: true,
          isFieldParamVariableArray: true,
          fieldAwaitsQueryParam: true,
          filterParamVariable: "companyIds",
          autoCompleteRequestType: "promotions",
          filterHardcodeKey: "promotion_type",
          filterHardcodeValue: "price_adjustment",
          dependantOn: ["company_id"],
        },
        {
          id: "table",
          type: "Image",
        },
        {
          id: "table",
          type: "IngredientsTable",
          dependantOn: ["company_id"],
        },
      ],
    },
    {
      id: "table",
      type: "IngredientsTable",
    },
    {
      resource: "ingredient",
      fields: [
        {
          id: "name",
          label: "Name",
          type: "TextField",
          required: true,
        },
        {
          id: "company_id",
          renderedBy: "display_name",
          label: "Company",
          type: "Autocomplete",
          required: true,
          useIdInPayload: true,
          isFieldValueConditional: true,
          hideOnEdit: true,
          autoCompleteRequestType: "companies",
          autoCompleteResponseType: "companies",
        },
        {
          id: "unit_name",
          label: "Unit name",
          type: "Select",
          useHardcodedOptions: true,
          required: true,
        },
        {
          id: "allergen_ids",
          label: "Allergens",
          type: "MultiAutocomplete",
          dependantOn: ["company_id"],
          sendParamAsFilter: true,
          useIdInPayload: true,
          isFieldParamVariableArray: true,
          fieldAwaitsQueryParam: true,
          filterParamVariable: "companyIds",
          autoCompleteRequestType: "allergens",
          autoCompleteResponseType: "allergens",
        },
      ],
    },
    {
      resource: "allergen",
      fields: [
        {
          id: "name",
          label: "Name",
          type: "TextField",
          required: true,
        },
        {
          id: "company_id",
          renderedBy: "display_name",
          label: "Company",
          type: "Autocomplete",
          required: true,
          useIdInPayload: true,
          isFieldValueConditional: true,
          hideOnEdit: true,
          autoCompleteRequestType: "companies",
          autoCompleteResponseType: "companies",
        },
      ],
    },
    {
      resource: "blueprint",
      fields: [
        {
          id: "name",
          label: "Name",
          type: "TextField",
          required: true,
        },
        {
          id: "company_id",
          renderedBy: "display_name",
          label: "Company",
          type: "Autocomplete",
          required: true,
          useIdInPayload: true,
          isFieldValueConditional: true,
          hideOnEdit: true,
          autoCompleteRequestType: "companies",
          autoCompleteResponseType: "companies",
        },
        {
          id: "number_of_rows",
          label: "Rows",
          type: "TextField",
          textFieldType: "number",
          onlyPositiveIntegers: true,
          required: true,
          onChangeMessage:
            "Existing mappings might get deleted on field change",
          maxValue: 30,
        },
        {
          id: "number_of_columns",
          label: "Columns",
          type: "TextField",
          textFieldType: "number",
          onlyPositiveIntegers: true,
          required: true,
          onChangeMessage:
            "Existing mappings might get deleted on field change",
          maxValue: 30,
        },
      ],
    },
    {
      resource: "gift-cards",
      fields: [
        {
          id: "name",
          label: "Name",
          type: "TextField",
          required: true,
        },
        {
          id: "description",
          label: "Description",
          type: "TextField",
          required: false,
        },
        {
          id: "company_id",
          renderedBy: "display_name",
          label: "Company",
          type: "Autocomplete",
          required: true,
          useIdInPayload: true,
          isFieldValueConditional: true,
          hideOnEdit: true,
          autoCompleteRequestType: "companies",
          autoCompleteResponseType: "companies",
        },
        {
          id: "currency",
          label: "Currency",
          type: "Select",
          required: true,
          useIdInPayload: false,
          fieldAwaitsQueryParam: true,
          hideOnEdit: true,
          autoCompleteRequestEndpoint: "payment-credentials",
          autoCompleteRequestType: "companies",
        },
        {
          id: "type",
          label: "Type",
          type: "Select",
          useHardcodedOptions: true,
          hideOnEdit: true,
          hardcodedOptionsType: "giftCardsTypes",
          required: true,
        },
        {
          id: "total_purchases",
          label: "Total gift cards amount",
          textFieldType: "number",
          isPrice: false,
          type: "TextField",
          isVisible: ({ giftCardType }: { giftCardType: GiftCardType }) => {
            return giftCardType === GiftCardTypes.Conventional;
          },
        },
        {
          id: "price",
          label: "Purchase price",
          type: "TextField",
          textFieldType: "number",
          useHardcodedOptions: true,

          required: "true",
          isPrice: true,
        },
        {
          id: "discount_value",
          label: "Value",
          type: "TextField",
          required: "true",
          textFieldType: "number",
          isPrice: true,
        },
        {
          id: "validity_amount",
          label: "Valid for",
          type: "TextField",
          textFieldType: "number",
          isPrice: true,
          required: true,
          hideOnEdit: true,
        },
        {
          id: "validity_type",
          label: "Validity Period",
          type: "Select",
          useHardcodedOptions: true,
          required: true,
          hideOnEdit: true,
        },
        {
          id: "active",
          label: "Active",
          type: "Switch",
          defaultValue: true,
          required: true,
        },
      ],
    },
    {
      resource: "entity-categories",
      fields: [
        {
          id: "name",
          label: "Name",
          type: "TextField",
          required: true,
        },
      ],
    },
  ] as FieldsInfo[];
};
