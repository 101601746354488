import { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  TableRow,
  TableCell,
  Box,
  Button,
  Dialog,
  Typography,
  TextField,
  FormControlLabel,
  Switch,
} from "@mui/material";

import { CustomDetailsPage } from "components/DetailsPage/DetailsPage";
import ContentLayout from "components/layouts/ContentLayout";
import useFilterContext from "context/FilterContext";
import { FieldDescriptor } from "models/fieldsEntities.model";
import constants from "styles/scss/constants.module.scss";
import { roleName } from "utils/UserRoles";

import useUserDetails from "./useUserDetails";

interface Machine {
  id: number;
  name: string;
  module: any;
  type: string;
  description: string | null;
}

interface HandleEditCode {
  code: string;
  is_valid: boolean;
  object_entity_ids: number[];
  id: number;
}
const UserDetails = () => {
  const {
    userData,
    setUserData,
    filteredMachines,
    handleDelete,
    updateAccesCode,
  } = useUserDetails();
  const [modalOpen, setModalOpen] = useState(false);

  const [handleEditCode, setHandleEditCode] = useState<HandleEditCode | null>(
    null,
  );
  const { t } = useTranslation();
  const { options } = useFilterContext();
  const { companies } = options;
  const noEndUser = userData?.role?.type !== "end_user";
  const [isValid, setIsValid] = useState<boolean>(false);

  const handleEdit = (machine: any) => {
    setIsValid(machine.is_valid);
    setHandleEditCode({
      code: machine.code,
      is_valid: isValid,
      object_entity_ids: machine.object_entity_ids,
      id: machine.id,
    });

    setModalOpen(true);
  };
  const handleUpdateCode = async () => {
    if (handleEditCode && handleEditCode.code.length > 3) {
      const bodyData = {
        code: handleEditCode.code,
        is_valid: isValid,
        user_id: userData?.id,
        id: handleEditCode.id,
      };
      setModalOpen(false);
      await updateAccesCode(bodyData);
    }
  };

  const fields: FieldDescriptor[] = [
    {
      value: userData?.first_name,
      label: "First name",
      type: "InfoText",
      show: true,
    },
    {
      value: userData?.last_name,
      label: "Last name",
      type: "InfoText",
      show: true,
    },
    {
      value: userData?.email, // TODO: Add link to email.
      label: "Email",
      type: "InfoText",
      show: true,
    },
    {
      value: userData?.phone,
      label: "Phone number",
      type: "InfoText",
      show: true,
    },
    {
      value: userData?.receive_sms === true ? t("Yes") : t("No"),
      label: "Do you wish to receive SMS?",
      type: "InfoText",
      show: true,
    },
    {
      value: `${
        userData?.role?.id ? t(roleName(userData?.role?.type) ?? "") : ""
      }`,
      label: "Role",
      type: "InfoText",
      show: true,
    },
    {
      value: userData?.alert_types?.map((alert) => <div>{t(alert)}</div>),
      label: "Alert types",
      type: "InfoText",
      show: userData?.alert_types?.length !== 0,
    },
    {
      value: userData?.users_companies,
      label: "Companies",
      type: "Table",
      show: userData?.users_companies?.length !== 0,
      headers: [t("Name")],
      columns: userData?.users_companies
        ?.filter((uc) => companies.map((c) => c.id).includes(uc.id))
        .sort((a, b) => a.display_name?.localeCompare(b.display_name as string))
        .map((e, i) => {
          return (
            <TableRow key={`${i}-${e.id}`}>
              <TableCell sx={{ padding: "5px 0 0 5px" }}>
                {e.display_name /* TODO: Add links to companies. */}
              </TableCell>
            </TableRow>
          );
        }),
    },
    {
      value: `${t("generate_code_for")} ${userData?.first_name ?? ""} ${
        userData?.last_name ?? ""
      }`,
      label: "Generate code",
      type: "AccessCodes",
      show: noEndUser,
    },
    {
      value: noEndUser && userData?.access_codes,
      label: "",
      type: "Table",
      show: userData?.access_codes?.length !== 0 && noEndUser,
      headers: [t("code"), t("enabled"), t("Machines"), t("Actions")],
      columns:
        noEndUser &&
        userData?.access_codes.map((e, i) => {
          return (
            <TableRow key={`${i}-${e.code}`}>
              <TableCell sx={{ padding: "5px 0 0 5px" }}>{e.code}</TableCell>
              <TableCell
                sx={{ padding: "5px 0 0 5px" }}
                style={{
                  textAlign: "right",
                }}
              >
                {e.is_valid ? t("Yes") : t("No")}
              </TableCell>
              <TableCell
                style={{
                  textAlign: "right",
                }}
                sx={{ padding: "5px 0 0 5px", gap: "10px" }}
              >
                {(filteredMachines || []).map(
                  (machine: Machine, index: number) => {
                    return e.object_entity_ids.includes(machine.id) ? (
                      <Box key={machine.id}>{machine.name}</Box>
                    ) : null;
                  },
                )}
              </TableCell>
              <TableCell
                sx={{ padding: "5px 0 0 5px" }}
                style={{
                  textAlign: "right",
                }}
              >
                <Button onClick={() => handleEdit(e)}>{t("Edit")}</Button>
                <Button
                  style={{ padding: 0 }}
                  onClick={() => handleDelete(e.id)}
                >
                  {t("Delete")}
                </Button>
              </TableCell>
            </TableRow>
          );
        }),
    },
  ];
  return (
    <>
      <ContentLayout
        title={`${t("Users")} > ${userData?.first_name ?? ""} ${
          userData?.last_name ?? ""
        }`}
        filters={false}
      >
        <CustomDetailsPage
          data={userData}
          setData={setUserData}
          resource={"user"}
          call={"users"}
          fields={fields}
        />
      </ContentLayout>
      <Dialog title={""} open={modalOpen} onClose={() => setModalOpen(false)}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "3em",
              backgroundColor: constants.bgTextShadow,
              color: "transparent",
              textTransform: "uppercase",
              textShadow: `0px 3px 3px ${constants.textShadow}`,
              fontFamily: constants.bold,
              backgroundClip: "text",
              textAlign: "center",
              paddingBottom: "10px!important",
            }}
          >
            {t("Mapping")}
          </Typography>
          <Box
            component="form"
            sx={{ "& > :not(style)": { m: 1, width: "25ch" } }}
            noValidate
            autoComplete="off"
          >
            <TextField
              id="outlined-controlled"
              label=""
              value={handleEditCode?.code}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setHandleEditCode((old) => {
                  if (old) {
                    return { ...old, code: event.target.value };
                  }
                  return old;
                });
              }}
            />
          </Box>
          <Box>
            <FormControlLabel
              sx={{
                display: "inline",
              }}
              label={t("Active")}
              control={
                <Switch
                  name={t("active")}
                  checked={isValid}
                  onChange={() => {
                    setIsValid((old) => !old);
                  }}
                />
              }
            />
          </Box>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
              gap: "10px",
            }}
          >
            <Button
              onClick={() => handleUpdateCode()}
              style={{ background: "#6a6afe", color: "#fff" }}
            >
              {t("Save")}
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default UserDetails;
