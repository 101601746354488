import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HelpIcon from "@mui/icons-material/Help";
import { Button, IconButton, Link, Typography } from "@mui/material";

import CompanyPaymentCredentials from "components/CompanyPaymentCredentials";
import {
  CustomTable,
  Column,
  TableSettings,
  CellValue,
  DynamicDialogProps,
} from "components/Table/Table";
import ContentLayout from "components/layouts/ContentLayout";
import DynamicDialog from "components/layouts/Dialog";
import QueryParams from "models/queryParams.model";
import CompanyModel from "models/resources/company.model";
import Company from "models/resources/company.model";
import { ResponseDataTable } from "models/responseData.model";
import { getData } from "services/requests/listData";

const CompanyTable = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [companiesData, setCompaniesData] =
    useState<ResponseDataTable<CompanyModel[]>>();

  const setData = (queryParams: QueryParams, setDataLoading: any) => {
    getData(setCompaniesData, "companies", queryParams, setDataLoading);
  };

  const columns: Column<Company>[] = [
    {
      label: t("Company Name"),
      name: "companyName",
      getData: (data): CellValue => data.name,
      show: () => {
        return true;
      },
      sort: { isSortable: true, field: "name" },
      width: "20%",
    },
    {
      label: t("Display name"),
      name: "displayName",
      getData: (data): CellValue => data.display_name,
      show: () => {
        return true;
      },
      width: "20%",
    },
    {
      label: t("Email"),
      name: "email",
      getData: (data): CellValue => (
        <Link key="Email" component="a" href={`mailto: ${data.email}`}>
          {data.email}
        </Link>
      ),
      show: () => {
        return true;
      },
      sort: { isSortable: true, field: "email" },
      width: "10%",
    },
    {
      label: t("Phone number"),
      name: "phoneNumber",
      getData: (data): CellValue => data.phone,
      show: () => {
        return true;
      },
      width: "10%",
      minWidth: "200px",
    },
    {
      label: t("Status"),
      name: "status",
      getData: (data): CellValue => {
        switch (data?.status) {
          case "approved":
            return <CheckCircleIcon color="success" fontSize="large" />;
          case "declined":
            return <CancelIcon color="error" fontSize="large" />;
          case "waiting":
            return <HelpIcon color="warning" fontSize="large" />;
          default:
            return <Typography component="p">---</Typography>;
        }
      },
      show: () => {
        return true;
      },
      align: "center",
      width: "5%",
    },
    {
      label: t("Payment credentials"),
      name: "paymentCredentials",
      getData: (data): CellValue => (
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="label"
        >
          <DynamicDialog
            title={t("Payment credentials")}
            openMessage={t("Payment credentials")}
            isOpen={false}
            hideActions
            component={<CompanyPaymentCredentials companyId={data.id} />}
          />
        </IconButton>
      ),
      align: "center",
      width: "15%",
      show: () => {
        return true;
      },
    },
    {
      label: t("API Credentials"),
      name: "apiCredentials",
      getData: (data): CellValue => (
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="label"
        >
          <Button
            variant="outlined"
            onClick={() =>
              navigate(`/account/companies/${data.id}/api-credentials`)
            }
          >
            {t("API Credentials")}
          </Button>
        </IconButton>
      ),
      align: "center",
      width: "15%",
      show: (user) => {
        return ["admin", "owner"].includes(user?.role.type || "");
      },
    },
    {
      label: t("Edit"),
      name: "edit",
      getData: (data): CellValue => data.id,
      show: () => {
        return true;
      },
      align: "center",
      width: "5%",
    },
  ];

  const tableOptions: TableSettings = {
    pagination: true,
    page: 0,
    rowsPerPage: 25,
    field: "id",
    order: "asc",
  };

  const dynamicDialogProps: DynamicDialogProps = {
    title: "Add company",
    resource: "company",
    call: "companies",
    styles: () => "flexHalfForm",
  };

  return (
    <ContentLayout title={t("Companies")}>
      <CustomTable
        title={t("Companies")}
        tableData={companiesData}
        fetchPaginatedData={setData}
        setTableData={setCompaniesData}
        columns={columns}
        resource="company"
        call="companies"
        tableOptions={tableOptions}
        exportProp={"companies"}
        dynamicDialogProps={dynamicDialogProps}
        isDynamic={true}
      />
    </ContentLayout>
  );
};

export default CompanyTable;
