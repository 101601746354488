import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { isEmpty } from "lodash";

import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import apiClient from "services/api";
import constants from "styles/scss/constants.module.scss";

import useUserDetails from "./useUserDetails";

interface AccessCodesProps {
  data: {
    id: number;
    users_companies: [
      {
        id: number;
        name: string;
      },
    ];
  };
  openModal: (value: boolean) => void;
  isOpen: boolean;
}
const AccessCodes = ({ data, openModal, isOpen }: AccessCodesProps) => {
  const { createUserAccessCode, refetch } = useUserDetails();
  const { t } = useTranslation();
  const [codes, setCodes] = useState<string>();
  const [codeError, setCodeError] = useState<string>("");
  const [machines, setMachines] = useState<{}>([]);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [options, setOptions] = useState<{ label: string; id: number }[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<{ id: number }>();

  const userId = data.id;
  const userCompanies = data.users_companies;
  const userCompaniesOptions = userCompanies.map((company) => {
    return {
      label: company.name,
      id: company.id,
    };
  });

  const handleCompanyChange = async (value: { id: number }) => {
    setSelectedCompany(value);
    setMachines([]);
    setOptions([]);

    if (!value) return;

    const queryParams = {
      filter: {
        companyIds: [value.id],
      },
    };

    try {
      const response = await apiClient({
        url: `machines`,
        queryParams: queryParams,
        method: "get",
      });

      const option: { label: string; id: number }[] = response.data.map(
        (machine: { name: string; id: number }) => {
          return {
            label: machine.name,
            id: machine.id,
          };
        },
      );
      setOptions(option);
    } catch (error) {
      toast.error("Error fetching company machines:");
      setOptions([]);
    }
  };

  const handleMachineChange = (value: { id: number }[]) => {
    const machineIds = value.map((machine) => machine.id);
    setMachines(machineIds);
  };

  const handleIsActiveOnChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setIsActive(event.target.checked);
  };

  const generateCodes = async () => {
    refetch((old) => !old);
    if (!selectedCompany) return;
    console.log(isEmpty(selectedCompany), isEmpty(machines), isEmpty(codes));
    if (isEmpty(machines) || isEmpty(codes) || isEmpty(selectedCompany)) return;
    if (codeError) {
      toast.error(t(codeError));
      return;
    }
    const bodyData = {
      code: codes,
      object_entity_ids: machines,
      is_valid: isActive,
      company_id: selectedCompany.id,
    };
    createUserAccessCode(userId, bodyData);

    openModal(false);
  };

  const handleCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value && !/^\d+$/.test(value)) {
      setCodeError(t("Access code must contain only numbers"));
    } else {
      setCodeError("");
    }
    setCodes(value);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <FormControl error={true} style={{ width: "100%" }}>
        <Typography
          sx={{
            fontSize: "3em",
            backgroundColor: constants.bgTextShadow,
            color: "transparent",
            textTransform: "uppercase",
            textShadow: `0px 3px 3px ${constants.textShadow}`,
            fontFamily: constants.bold,
            backgroundClip: "text",
            textAlign: "center",
            paddingBottom: "10px!important",
          }}
        >
          {t("generate_access_code")}
        </Typography>

        <Box component="form" noValidate autoComplete="off" width={1}>
          <Autocomplete
            fullWidth
            id="company-select"
            options={userCompaniesOptions}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label={t("Company")} />
            )}
            onChange={(event, value: { id: number } | null) => {
              if (value) {
                handleCompanyChange(value);
              }
            }}
          />
        </Box>
        <Box component="form" noValidate autoComplete="off" width={1}>
          <Autocomplete
            fullWidth
            multiple
            id="machines-select"
            options={options}
            filterSelectedOptions
            value={machines // @ts-ignore
              .map((id) => options.find((opt) => opt.id === id))
              .filter(Boolean)}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label={t("Machines")} />
            )}
            onChange={(event, value) => {
              handleMachineChange(value);
            }}
          />
        </Box>
        <Box component="form" noValidate autoComplete="off" width={1}>
          <TextField
            fullWidth
            required
            id="required"
            label={t("access_code")}
            onChange={handleCodeChange}
            error={!!codeError}
            helperText={codeError ? t(codeError) : ""}
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
            }}
          />
        </Box>

        <Box>
          <FormControlLabel
            sx={{
              display: "inline",
            }}
            label={t("Active")}
            control={
              <Switch
                name={t("active")}
                checked={isActive}
                onChange={handleIsActiveOnChange}
              />
            }
          />
        </Box>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
            gap: "10px",
          }}
        >
          <Button
            onClick={generateCodes}
            style={{ background: "#6a6afe", color: "#fff" }}
          >
            {t("Save")}
          </Button>
        </div>
      </FormControl>
    </div>
  );
};

export default AccessCodes;
