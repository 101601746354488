import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import {
  CellValue,
  Column,
  CustomTable,
  DynamicDialogProps,
  TableSettings,
} from "components/Table/Table";
import ContentLayout from "components/layouts/ContentLayout";
import Blueprint from "models/resources/blueprint.model";

import useBlueprintTable from "./useBlueprintTable";

const BlueprintTable = () => {
  const { t } = useTranslation();
  const { blueprintData, setBlueprintData, setData } = useBlueprintTable();

  const columns: Column<Blueprint>[] = [
    {
      label: t("Name"),
      name: "name",
      getData: (data): CellValue => data.name,
      show: () => {
        return true;
      },
      sort: { isSortable: false, field: "name" },
    },
    {
      label: t("Company"),
      name: "company",
      getData: (data): CellValue => (
        <Link
          to={{
            pathname: `/account/companies/${data.company_id}`,
          }}
        >
          {data.company?.display_name || data.company?.name}
        </Link>
      ),
      show: () => {
        return true;
      },
      sort: { isSortable: false, field: "company_id" },
    },
    // {
    //   label: t("Edit"),
    //   name: "edit",
    //   getData: (data): CellValue => data.id,
    //   show: () => {
    //     return true;
    //   },
    //   width: "5%",
    //   align: "center",
    // },
  ];

  const tableOptions: TableSettings = {
    pagination: true,
    page: 0,
    rowsPerPage: 25,
    field: "name",
    order: "asc",
  };

  const dynamicDialogProps: DynamicDialogProps = {
    title: "Add blueprint",
    resource: "blueprint",
    call: "machine-blueprints",
    styles: () => "flexHalfForm",
  };

  return (
    <ContentLayout title={`${t("Machines")} > ${t("Blueprints")}`}>
      <CustomTable
        title={t("Blueprints")}
        tableData={blueprintData}
        fetchPaginatedData={setData}
        setTableData={setBlueprintData}
        columns={columns}
        resource="blueprint"
        call="machine-blueprints"
        tableOptions={tableOptions}
        exportProp={"blueprints"}
        dynamicDialogProps={dynamicDialogProps}
        isDynamic={true}
        notCSV={true}
        clickableRow
      />
    </ContentLayout>
  );
};

export default BlueprintTable;
