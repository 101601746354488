import React, {
  Dispatch,
  Fragment,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import "react-phone-input-2/lib/style.css";
import { toast, ToastContainer, TypeOptions } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import * as _ from "lodash";

import { Box, Button, DialogActions } from "@mui/material";

import {
  FormDataKey,
  generateSubmitUrl,
  getLabelForOption,
  parsePayload,
  populateEditForm,
  setConditionalFieldOptions,
  setFieldsOptions,
} from "components/Forms/formUtils";
import useDataContext from "context/DataContext";
import {
  FieldDescriptor,
  FieldsInfo,
  FormData as IFormData,
} from "models/fields.model";
import RequestParams from "models/requestParams.model";
// import DynamicDialog from "layouts/Dialog";
import { GiftCardPaymentResponse } from "models/resources/payment.model";
import Product from "models/resources/product.model";
import {
  DatabaseEntity,
  ResponseDataError,
  ResponseDataSuccess,
} from "models/responseData.model";
import apiClient from "services/api";

import { fields } from "./descriptors/fields";
import useFieldGenerator from "./useFieldGenerator";

interface ToastNotification {
  message: string;
  outcomeMessage?: string;
  type: "success" | "error";
}

interface DynamicFormProps {
  // both props used for API calls
  // resource is single form, mode is plural.
  // Backend format is non-consistent and a query to `companies/:id` will return `company` key in the response
  // some have additional format: `companies/:id/payment-credentials`
  resource: string;
  isResourceListedInTable?: boolean;
  call: string; // corresponds to: autoCompleteRequestType from field interface (refer to it for more info)
  callEndPoint?: string; // corresponds

  mode: string; // create/edit
  nestedOnSubmit?: Function;
  editId?: number;
  preventQuery?: boolean; // set edit form values from elsewhere, prevent api call
  dynamicClass?: (...args: string[]) => string; //set class for styling purpose
  editData?: any; // if no API calls will be made then provide the edit data externally
  setDialogOpen?: Function; // a nested DOM like so is possible: dialog -> form -> dialog -> form. The innermost form should be able to close its dialog and return to the parent dialog.
  getResponse?: (responseType: GiftCardPaymentResponse) => void; // Returns response data to the parent component calling the form.
  disableNotifications?: boolean;
  submitButton?: JSX.Element; // Use custom submit form button passed from parent component.
  setFormOpened?: Function;
  exitAction?: Dispatch<SetStateAction<any>>;
  updateData?: any;
  tableData?: any;
  onCustomSubmit?(): void;
}

const getDefaultSubmitButton = (
  disableNotifications: boolean,
  disabled?: boolean,
): JSX.Element => {
  return (
    <>
      {!disableNotifications && (
        <ToastContainer limit={1} newestOnTop={true} position="top-right" />
      )}
      <Box display="flex" justifyContent="space-between" mt={2}>
        <Button
          type="submit"
          variant="contained"
          style={{ width: "100%", boxShadow: "none" }}
          disabled={disabled}
        >
          {useTranslation().t("Save")}
        </Button>
      </Box>
    </>
  );
};

const DynamicForm = ({
  resource,
  isResourceListedInTable = false,
  mode,
  call,
  callEndPoint = "",
  nestedOnSubmit = undefined,
  editId = -1,
  preventQuery = false,
  editData = undefined,
  dynamicClass = (): string => "",
  setDialogOpen = undefined,
  getResponse,
  disableNotifications = false,
  submitButton = getDefaultSubmitButton(disableNotifications),
  setFormOpened = undefined,
  exitAction,
  updateData,
  tableData,
  onCustomSubmit = () => {},
}: DynamicFormProps) => {
  const [disableState, setDisableState] = useState(true);
  const methods = useForm<IFormData>();
  const [resourceState, setResourceState] = useState<string>(resource);
  const {
    selectedCompanyEditId,
    setSelectedCompanyEditId,
    setSelectedModuleEditId,
    setMarkerPosition,
    ...dataContext
  } = useDataContext();

  let formFields: FieldDescriptor[] = _.map(
    fields(mode),
    (fieldInfo: FieldsInfo) => {
      if (fieldInfo.resource === resourceState) return fieldInfo.fields;
    },
  ) as unknown as FieldDescriptor[];

  let dependencies: any = fields(mode).find((fieldInfo: FieldsInfo) => {
    return fieldInfo.resource === resourceState;
  })?.dependencies;

  formFields = _.filter(
    formFields,
    (fieldAttr: FieldDescriptor) => fieldAttr != null,
  );
  formFields = _.flatten(formFields);
  const {
    formState: { errors },
    setValue,
    handleSubmit,
    getValues,
    watch,
    control,
    resetField,
    clearErrors,
  } = methods;

  const { t } = useTranslation();

  // hook updates autocomplete/select options and triggers rerender
  //@ts-ignore
  const { update } = useFieldArray({ control, name: "options" });
  const { generateFieldsForResource, fieldToWatch } = useFieldGenerator();
  const [initialEditFormValues, setInitialEditFormValues] =
    useState<DatabaseEntity>();

  const key = fieldToWatch.current as FormDataKey;

  if (editId !== -1) {
    if (resource === "module") setSelectedModuleEditId(editId);
    if (resource === "company") setSelectedCompanyEditId(editId);
  }

  // subscribe to changes of this field and manipulate in useEffect
  //@ts-ignore
  const conditionalId = watch(key) as number;
  const childCreated = watch("childCreated") as string;
  const conditionalCountryState = watch("state") as number | undefined; // Used for showing only cities from the selected country-state in company create/edit page.
  const [loadedEditData, setLoadedEditData] = useState(false);
  const toastId = useRef("");

  useEffect(() => {
    if (setFormOpened) setFormOpened(true);

    return () => {
      if (setFormOpened) setFormOpened(false);
    };
  }, []);

  useEffect(() => {
    if (mode === "edit" && preventQuery && editData !== undefined) {
      clearErrors();

      _.forEach(editData, (innerValue, innerKey) => {
        //@ts-ignore
        setValue(innerKey, innerValue);
      });
    }

    if (mode === "edit" && editId !== -1 && !preventQuery) {
      if (
        ![
          "machine",
          "pos",
          "qr",
          "recipe-product",
          "packet-product",
          "gift-cards",
        ].includes(resource) ||
        conditionalId
      )
        (async () =>
          await setFieldsOptions({
            formFields,
            conditionalId,
            getValues,
            update,
            editId,
            conditionalCountryState,
            translate: t,
          }))();

      if (!loadedEditData) {
        setLoadedEditData(true);
        const populate = async () => {
          const result = await populateEditForm(
            resource,
            call,
            editId,
            setMarkerPosition,
            setValue,
            formFields,
          );
          const values: {} | any = {};
          if (result?.entity) {
            Object.entries(result?.entity).map(([key, value]) => {
              if (key === "properties" && typeof result === "object") {
                //@ts-ignore
                for (const key in result?.entity.properties) {
                  values[key] = value[key];
                }
              }
              if (result.responseLogic.rootValues?.includes(key)) {
                if (typeof value === "object") {
                  switch (key) {
                    case "users_pos":
                      values["user_ids"] = value.map((el: any) => el.id);
                      break;
                    case "module":
                      values["module_id"] = value?.id;
                      break;
                    case "role":
                      values["role"] = value.type;
                      break;
                    case "users_companies":
                      values["company_ids"] = value.map((el: any) => el.id);
                      break;
                    case "products":
                      values["product_ids"] = value.map((el: any) => el.id);
                      break;
                    case "promotions":
                      values["promotion_ids"] = value.map((el: any) => el.id);
                      break;
                    default:
                      break;
                  }
                } else {
                  switch (key) {
                    case "from":
                      values[key] = value.split("T")[0];
                      break;
                    case "to":
                      values[key] = value.split("T")[0];
                      break;
                    default:
                      values[key] = value;
                      break;
                  }
                }
              }
            });
          }
          setInitialEditFormValues(values);
        };
        populate();
      }
    } else {
      // Subsequent renders: condition has changed, rerender all options for fields that depend on it
      if (conditionalId !== -1 && conditionalId !== undefined) {
        (async () =>
          setFieldsOptions({
            formFields,
            conditionalId,
            getValues,
            update,
            resetField:
              typeof conditionalCountryState === "undefined"
                ? resetField
                : undefined,
            conditionalCountryState,
            translate: t,
          }))();
      } else {
        // Else clause represents the initial rendering scenario
        // Initially fetch autocomplete/select options for the field that is marked as conditional
        // (its' value is used to determine subsequent fields' values)
        let formHasConditionalField = false;
        (async () =>
          (formHasConditionalField = await setConditionalFieldOptions(
            formFields,
            conditionalId,
            setValue,
          )))();

        // Form has no conditional field so the form can load all autocompletes on first render
        // Otherwise render the rest of the options on every condition change
        if (!formHasConditionalField)
          (async () =>
            await setFieldsOptions({
              formFields,
              conditionalId,
              getValues,
              update,
              conditionalCountryState,
              translate: t,
            }))();
      }
    }

    if (mode === "create" || resource === "mdb-settings") {
      setDisableState(false);
      return;
    }
    if (mode === "edit") {
      const subscription = watch((value, { name, type }) => {
        if (initialEditFormValues === undefined) return;

        //@ts-ignore
        const initialEditFormKeys = Object.keys(initialEditFormValues) as [];
        const initialEditFormValuesAsArr = Object.values(
          initialEditFormValues,
        ).map((el) => el?.toString().replace(/ /g, ""));

        const currFormValues = initialEditFormKeys.map((key) => {
          //@ts-ignore
          if (key === "phone" && !getValues(key).includes("+")) {
            //@ts-ignore
            return `+${getValues(key)?.toString().replace(/ /g, "")}`;
          } else if (key === "from") {
            // TODO: Fix commented code with range.
            //@ts-ignore
            // return getValues("range").start.format("YYYY-MM-DD");
          } else if (key === "to") {
            //@ts-ignore
            // return getValues("range").end.format("YYYY-MM-DD");
          }
          //@ts-ignore
          return getValues(key)?.toString().replace(/ /g, "");
        });
        if (_.isEqual(currFormValues, initialEditFormValuesAsArr)) {
          return setDisableState(true);
        } else {
          return setDisableState(false);
        }
      }) as any;
      //@ts-ignore
      return () => subscription.unsubscribe();
    }
  }, [
    conditionalId,
    editData,
    childCreated,
    conditionalCountryState,
    watch,
    initialEditFormValues,
  ]);
  const formValues = getValues();

  const onSubmit = async (
    event: React.FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    /* This part is for stopping parent forms to trigger their submit
     HTML5 and W3C conventions do not allow nested forms for native "form" element.
     RHF uses html native form element.
     In this application a nested form is allowed. 

     This part of the code ensures that this event is not dispatched to the parent form and handles
     the submit of the current form only. 
     For example: We have a machine create form and we would like to add a new location. That would prompt
     a new dialog with a new nested form. If we don't prevent it then on location submit both forms will submit. */
    if (event) {
      // sometimes not true, e.g. React Native
      if (typeof event.preventDefault === "function") {
        event.preventDefault();

        /*
        delete the marker position after the post/patch api call.
        if we dont use timeout it sets the position to null before
        sending the request and everything breaks;
        */
        setTimeout(() => {
          setMarkerPosition(null);
        }, 800);
      }
      if (typeof event.stopPropagation === "function") {
        // prevent any outer forms from receiving the event too
        event.stopPropagation();
      }
    }

    // callback to handle form data
    return handleSubmit(async (data: IFormData) => {
      let url = generateSubmitUrl(
        resource,
        mode,
        call,
        callEndPoint,
        editId,
        selectedCompanyEditId,
      );

      let requestBodyData = parsePayload(mode, resourceState, data);

      if (resource === "recipe-product") {
        requestBodyData = {
          ...requestBodyData,
          ingredients: dataContext.recipeProductIngredients,
        };
      }
      if (resource === "gift-cards") {
        requestBodyData = {
          ...requestBodyData,
          usage_conditions: [
            {
              type: "valid_period",
              validity_amount: data.validity_amount,
              validity_type: data.validity_type,
            },
          ],
        };
      }
      const apiParams: RequestParams = {
        url: url,
        method:
          mode === "create" && resource !== "change-password"
            ? "post"
            : resource === "module-firmware" ||
                resource === "change-password" ||
                resource === "module-flow"
              ? "put"
              : "patch",
        bodyData: requestBodyData,
      };

      if (!disableNotifications)
        toastId.current = toast.loading(
          mode === "create" ? "Creating resource..." : "Editing resource...",
          { autoClose: 1500, closeOnClick: true },
        ) as string;

      let notification: ToastNotification = { message: "", type: "success" };

      const removeMachinesFromProduct = async (resourceData: any) => {
        const allMachines = resourceData.machines.map((i: any) => i.id);

        const removedMachines = allMachines.filter(
          (m: any) => !data.machine_ids?.includes(m),
        );

        for (const machineIndex in removedMachines) {
          await apiClient({
            url: `machines/${removedMachines[machineIndex]}/products/${editId}`,
            method: "delete",
          });
        }
      };

      const handleEditResourceRemove = async (resourceData: any) => {
        //TODO: Refactor logic around removing resources on edit.
        switch (resource) {
          // Additional code for removing allergens from ingredient on edit because of BE api architecture.
          case "ingredient":
            const allAllergens = resourceData?.allergens.map((a: any) => a.id);

            const removedAllergens = allAllergens.filter(
              (a: any) => !data.allergen_ids?.includes(a),
            );

            for (const allergenIndex in removedAllergens) {
              apiClient({
                url: `ingredients/${editId}/allergens/${removedAllergens[allergenIndex]}`,
                method: "delete",
              });
            }
            break;
          // Additional code for removing ingredients from products on edit because of BE api architecture.
          case "recipe-product":
            const allIngredients = resourceData?.ingredients.map(
              (i: any) => i.id,
            );

            const removedIngredients = allIngredients.filter(
              (a: any) =>
                !dataContext.recipeProductIngredients
                  .map((i) => i.ingredient_id)
                  .includes(a),
            );

            for (const ingredientIndex in removedIngredients) {
              apiClient({
                url: `products/${editId}/ingredients/${removedIngredients[ingredientIndex]}`,
                method: "delete",
              });
            }

            await removeMachinesFromProduct(resourceData);
            break;
          case "packet-product":
            // Additional code for removing ingredients from products on edit because of BE api architecture.
            const ingredientId = resourceData?.ingredients[0].id; // TODO: BE need to add product.ingredient_id to product response to make delete request in details page.
            const allProductAllergens =
              resourceData?.ingredients[0].allergens.map((i: any) => i.id);

            const removedProductAllergens = allProductAllergens.filter(
              (a: any) => !data.allergen_ids?.includes(a),
            );

            for (const allergenIndex in removedProductAllergens) {
              apiClient({
                url: `ingredients/${ingredientId}/allergens/${removedProductAllergens[allergenIndex]}`,
                method: "delete",
              });
            }

            await removeMachinesFromProduct(resourceData);
            break;
          case "price adjustment":
            resourceData?.products.map(async ({ id }: Product) => {
              await apiClient({
                url: `/price_adjustments/${resourceData?.id}/product/${id}`,
                method: "delete",
              });
            });

            break;
        }
      };

      if (mode === "edit") {
        await handleEditResourceRemove(
          isResourceListedInTable
            ? tableData.find((d: any) => d.id === editId)
            : tableData,
        );
      }

      const response: ResponseDataSuccess<DatabaseEntity> | ResponseDataError =
        await apiClient<DatabaseEntity>(apiParams)
          .then(async (response) => {
            notification =
              mode === "create"
                ? {
                    message: "Resource created successfully",
                    type: "success",
                  }
                : {
                    message: "Resource edited successfully",
                    type: "success",
                  };

            if (typeof getValues("product_image") !== "undefined") {
              const formData = new FormData();
              formData.append("image", getValues("product_image"), "logo.png");

              const url = formFields.find((el) => el.type == "Image")?.url;
              if (!url)
                // log an error for unproper custom usage
                return;

              await fetch(url.replace("{id}", response.data.id), {
                method: "PUT",
                credentials: "include",
                body: formData,
              });
            }

            if (isResourceListedInTable && updateData) {
              const manipulatedEntity = response.data;
              tableData?.data?.unshift(manipulatedEntity);
              if (mode === "create") {
                updateData({ ...tableData });
              } else if (mode === "edit") {
                const filteredData = tableData.filter(
                  // @ts-ignore
                  (e: DatabaseEntity) => e.id !== manipulatedEntity.id,
                );
                const index = tableData.findIndex(
                  // @ts-ignore
                  (e: DatabaseEntity) => e.id === manipulatedEntity.id,
                );
                filteredData.splice(index, 0, manipulatedEntity);
                updateData({ ...tableData, data: filteredData });
              }
            } else if (updateData) {
              updateData(response.data);
            }

            return response;
          })
          .catch((error) => {
            notification = {
              message: `Resource ${
                mode === "create" ? "creation" : "editing"
              } failed`,
              type: "error",
              outcomeMessage: error.message,
            };

            return error;
          });
      if (!disableNotifications)
        toast.update(toastId.current, {
          render: () => {
            let message = notification.message;

            if (notification.type === "error")
              message += `\n Reason: ${notification.outcomeMessage}`;
            else if (notification.type === "success" && mode === "edit") {
              message = "Resource edited successfully";
              if (exitAction) exitAction({ display: false, editId: -1 });
            }
            return t(message);
          },
          type: notification.type as TypeOptions,
          isLoading: false,
          autoClose: 8000,
        });

      let responseData: DatabaseEntity | undefined;

      if (notification.type === "success") {
        responseData = (response as ResponseDataSuccess<DatabaseEntity>).data;
      }

      // If this form is a nested form inside a dialog, the value from the submit
      // needs to be passed to the parent form and available as an option.
      if (typeof getResponse !== "undefined")
        getResponse(responseData as GiftCardPaymentResponse);
      if (
        typeof setDialogOpen !== "undefined" &&
        notification.type === "success"
      )
        setDialogOpen(false);
      if (typeof nestedOnSubmit !== "undefined") {
        nestedOnSubmit({
          //@ts-ignore
          id: responseData!.id,

          label: getLabelForOption({
            //@ts-ignore
            entity: responseData!.data,
            translate: t,
          }),
        });

        if (exitAction) exitAction({ display: false, editId: -1 });
      }
    })(event);
  };

  const dynamicClassParams = dependencies
    ? (dependencies.map((dependency: string) =>
        watch(dependency as any),
      ) as string[])
    : [];

  return (
    <form
      onSubmit={onSubmit}
      className={(dynamicClass as (...args: string[]) => string)(
        ...dynamicClassParams,
      )}
    >
      {generateFieldsForResource({
        mode,
        getValues,
        setValue,
        formFields,
        //@ts-ignore
        control,
        errors,
        watch,
        tableData,
        resource,
      })}
      <DialogActions>
        <div
          style={{ flex: "1 0 0", padding: 0 }}
          onClick={() => {
            if (Object.keys(errors).length !== 0) onCustomSubmit();
          }}
        >
          {submitButton}
        </div>
      </DialogActions>
    </form>
  );
};

export default DynamicForm;
